import React, { useEffect, useState } from 'react'
import { getSingleOrgContractsAction } from '../../../redux/action/contracts'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useParams } from 'react-router-dom'
import { getCurrentDateTime } from '../../../helpers/helper'
import { Modal } from 'react-responsive-modal'
import EditContract from './EditContract'
import Loading from '../../../layout/Loading'

export default function ContractsList({ contracts, loading }) {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const dispacth = useDispatch()
  const navigate = useNavigate()

  const { id } = useParams()
  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  useEffect(() => {
    dispacth(getSingleOrgContractsAction(id))
  }, [dispacth, id])

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <div className="full-width white p-1">
      <div
        className={
          isTablet
            ? 'mb-1 flex align-center space-between'
            : 'flex align-center space-between'
        }
      >
        <b className="normal-size">Contracts List</b>
        {contracts.length > 0 && (
          <button
            className={' hover view-all-btn'}
            onClick={() => navigate(`/all-contractList/${id}`)}
          >
            View All
          </button>
        )}
      </div>
      {contracts && contracts.length === 0 ? (
        <div className="flex column align-center p-1">
          <img src="../images/no-data.svg" alt="no-data" width={100} />
          <p className="grey-text">Sorry, currently you don’t have any data</p>
        </div>
      ) : (
        <div
          className={isTablet ? 'flex column gap-2' : 'flex gap-2 mt-1 mb-1'}
        >
          {contracts?.map((item, index) => {
            if (index < 4) {
              return (
                <div
                  key={index}
                  className=""
                  style={{ width: isTablet ? '100%' : '25%' }}
                >
                  <div
                    className="flex align-center space-between p-1"
                    style={{
                      background: '#F3ECF9',
                      borderRadius: ' 7px 7px 0px 0px',
                    }}
                  >
                    <img
                      src="../images/contract-new.png"
                      alt="no-data"
                      style={{ width: '60px', height: '60px' }}
                    />
                    <span
                      className="flex column white p-1"
                      style={{ borderRadius: '7px' }}
                    >
                      <p>EXPIRES IN</p>
                      <p className="purple-text">365 Days</p>
                    </span>
                  </div>
                  <div
                    style={{
                      boxShadow: ' 0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                    }}
                  >
                    <div className="ml-1">
                      <p
                        className="purple-text"
                        style={{ fontSize: '16px', paddingTop: '5px' }}
                      >
                        Scrap
                      </p>
                      <p>
                        {item.scrap.name} - {item.scrap.variant}
                      </p>
                    </div>
                    <div className="ml-1">
                      <p
                        className="purple-text"
                        style={{ fontSize: '16px', paddingTop: '5px' }}
                      >
                        Waste Processor
                      </p>
                      <p>{item.processor.name}</p>
                    </div>
                    <div className="ml-1">
                      <p
                        className="purple-text"
                        style={{ fontSize: '16px', paddingTop: '5px' }}
                      >
                        Start Date{' '}
                      </p>
                      <p>{getCurrentDateTime(item.start)}</p>
                    </div>
                    <div className="ml-1">
                      <p
                        className="purple-text"
                        style={{ fontSize: '16px', paddingTop: '5px' }}
                      >
                        End Date{' '}
                      </p>
                      <p>{getCurrentDateTime(item.end)}</p>
                    </div>
                  </div>
                  <div className="full-width btn" onClick={onOpenModal}>
                    EDIT
                  </div>

                  <Modal
                    center
                    styles={{
                      modal: {
                        borderRadius: '10px',
                        padding: 0,
                        width: isTablet ? '370px' : '800px',
                      },
                    }}
                    open={open}
                    onClose={onCloseModal}
                    closeIcon={<></>}
                  >
                    <>
                      <div className="cercle-purple p-1 white-text flex align-center space-between">
                        <h4>Raise A Pickup Request</h4>
                        <div
                          onClick={onCloseModal}
                          className="flex align-center justify-center"
                          style={{
                            width: 40,
                            height: 40,
                            background: 'rgba(255, 255, 255, 0.50)',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                        >
                          <span className="material-symbols-outlined">
                            close
                          </span>
                        </div>
                      </div>
                      <EditContract />
                    </>
                  </Modal>
                </div>
              )
            }
            return null
          })}
        </div>
      )}
    </div>
  )
}
