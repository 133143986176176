import React, { useState } from 'react'
import AddExpenses from './AddExpenses'
import Modal from 'react-responsive-modal'
import { Pagination, Icon } from 'react-materialize'
import { useMediaQuery } from 'react-responsive'
const Data = [
  {
    brand: 'Sri Krishna sweets',
    time: 'Today at 4:30 PM',
    raiseDate: 'Mon OCT 30 2023',
    pickedDate: 'Mon DEC 3 2023',
    wasteType: 'Dry',
    weight: '32023 Kg',
    vehicleType: 'TATA Ace',
    vehicleNumber: 'TN 38 SS 1225',
    pickupLocation: 'CCMC-Peelamedu Garbage Transfer Station ',
  },
  {
    brand: 'Sri Krishna sweets',
    time: 'Today at 4:30 PM',
    raiseDate: 'Mon OCT 30 2023',
    pickedDate: 'Mon DEC 3 2023',
    wasteType: 'Dry',
    weight: '32023 Kg',
    vehicleType: 'TATA Ace',
    vehicleNumber: 'TN 38 SS 1225',
    pickupLocation: 'SKS - Neivedhyam',
  },
  {
    brand: 'Sri Krishna sweets',
    time: 'Today at 4:30 PM',
    raiseDate: 'Mon OCT 30 2023',
    pickedDate: 'Mon DEC 3 2023',
    wasteType: 'Dry',
    weight: '32023 Kg',
    vehicleType: 'TATA Ace',
    vehicleNumber: 'TN 38 SS 1225',
    pickupLocation: 'SKS - Neivedhyam',
  },
]

export default function TransactionHistory() {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <div className="historytable white mt-1" style={{ borderRadius: '7px' }}>
      <div className="p-1">
        <div
          className={
            isMobile
              ? 'flex column gap-1'
              : 'flex space-between align-center full-width '
          }
        >
          <div className="flex full-width">
            <div
              className="input-field-style flex align-center small-text"
              style={{
                margin: 0,
                color: '#BFC3CE',
                background: '#FAFAFA',
                width: isMobile ? '100%' : '30%',
                marginRight: '10px',
              }}
            >
              <span className="material-symbols-outlined mr-1">search</span>
              <input
                style={{ margin: 0, caretColor: '#000000' }}
                placeholder="Search"
                className="hide-focus-underline"
              />
            </div>
            <button className="btn">Search</button>
          </div>
          <div className="flex gap-1">
            <span className="input-field-style">
              <span className="material-symbols-outlined">filter_list</span>
            </span>
            <button className="btn">Export</button>
          </div>
        </div>
        <div
          className="transactiontable"
          style={{ overflowX: isMobile ? 'auto' : '' }}
        >
          <table className="main-table">
            <thead>
              <tr>
                <th className="fixed-column">BRAND</th>
                <th>PICKUP RAISED DATE</th>
                <th>PICKED DATE</th>
                <th>WASTE TYPE </th>
                <th>LOADED WEIGHT</th>
                <th>VEHICLE TYPE</th>
                <th>VEHICLE NUMBER</th>
                <th>PICKUP LOCATION</th>
              </tr>
            </thead>

            <tbody>
              {Data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="fixed-column flex">
                      <span
                        style={{
                          width: '20px',
                          height: '20px',
                          background: 'rgba(111, 45, 168, 1)',
                          borderRadius: '50%',
                          color: '#fff',
                          padding: '2px',
                        }}
                        onClick={onOpenModal}
                      >
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: '16px' }}
                        >
                          add
                        </span>
                      </span>

                      <Modal
                        center
                        styles={{
                          modal: {
                            borderRadius: '10px',
                            padding: 0,
                            width: isMobile ? '380px' : '800px',
                            margin: 0,
                          },
                        }}
                        open={open}
                        onClose={onCloseModal}
                        closeIcon={<></>}
                      >
                        <>
                          <div className="cercle-purple p-1 white-text flex align-center space-between">
                            <h4>Add Expenses</h4>
                            <div
                              onClick={onCloseModal}
                              className="flex align-center justify-center"
                              style={{
                                width: 40,
                                height: 40,
                                background: 'rgba(255, 255, 255, 0.50)',
                                borderRadius: '6px',
                                cursor: 'pointer',
                              }}
                            >
                              <span className="material-symbols-outlined">
                                close
                              </span>
                            </div>
                          </div>
                          <AddExpenses />
                        </>
                      </Modal>

                      <span className="ml-1" style={{ textAlign: 'start' }}>
                        <p>{item.brand}</p>
                        <p className="small-text grey-text">{item.time}</p>
                      </span>
                    </td>

                    <td>{item.raiseDate}</td>
                    <td>{item.pickedDate}/KG</td>
                    <td>{item.wasteType}</td>
                    <td>{item.weight}</td>
                    <td>{item.vehicleType}</td>
                    <td>{item.vehicleNumber}</td>
                    <td>{item.pickupLocation}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="table-footer mb-1">
        <Pagination
          activePage={3}
          items={5}
          leftBtn={<Icon>chevron_left</Icon>}
          rightBtn={<Icon>chevron_right</Icon>}
        />
      </div>
    </div>
  )
}
