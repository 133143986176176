import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PickupsHistory from './components/PickupHistory'
import ContactDetails from './components/ContactDetails'
import { useParams } from 'react-router'
import {
  getSingleOrgSummaryAction,
  getSingleOrganizationAction,
} from '../../redux/action/organization'
import SingleTraderAnalitics from './components/SingleTraderAnalitics/SingleTraderAnalitics'

export default function TraderView() {
  const dispacth = useDispatch()
  const { id } = useParams()
  const { organization } = useSelector((state) => state.singleOrganization)

  const { pickupsHistory, loading } = useSelector(
    (state) => state.singleOrganizationPickupsHistory,
  )
  const { summary } = useSelector((state) => state.summary)
 
  useEffect(() => {
    dispacth(getSingleOrganizationAction(id))
    dispacth(getSingleOrgSummaryAction(id))
  }, [dispacth, id])

  return (
    <div>
      <b>Single Organization</b>
      <ContactDetails organizationDetails={organization} />
      <SingleTraderAnalitics
        organizationDetails={organization}
        summary={summary}
      />
      <PickupsHistory
        pickupsHistory={pickupsHistory}
        loading={loading}
        organization={organization}
      />
    </div>
  )
}
