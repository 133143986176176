import React from 'react'
import Analytics from './components/Analytics'
import PaymentReceivedChart from './components/PaymentReceivedChart'
import RevenueSplitupChart from './components/RevenueSplitupChart'
import LocationChart from './components/LocationChart'
import SalesGoalChart from './components/SalesGoalChart'
import TransactionHistory from './components/TransactionHistory'
import SalesGoalAreaChart from './components/SalesGoalAreaChart'
import ExpenseSpentChart from './components/ExpenseSpentChart'
import RevenueGenearteBrand from './components/RevenueGenearteBrand'
import { useMediaQuery } from 'react-responsive'

export default function Payments() {
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div>
      <b className="normal-size">Payments & Transaction </b>
      <div
        className={
          isLaptop ? 'flex column gap-1' : 'flex align-center gap-1 mt-1'
        }
      >
        <Analytics />
        <PaymentReceivedChart />
      </div>
      <div className={isTablet ? 'flex column mt-1 gap-1' : 'flex mt-1  gap-2'}>
        <RevenueSplitupChart />
        <LocationChart />
        <SalesGoalChart />
      </div>
      <div className={isMobile ? 'flex column mt-1 gap-1' : 'flex mt-1 gap-2'}>
        <SalesGoalAreaChart />
        <ExpenseSpentChart />
        <RevenueGenearteBrand />
      </div>
      <div>
        <TransactionHistory />
      </div>
    </div>
  )
}
