import React, { useState } from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import { useMediaQuery } from 'react-responsive'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import axios from 'axios'
import { toast } from 'react-toastify'

function AddLocations({
  onCloseAddLocationModal,
  addLocation,
  loading,
  values,
  setValues,
  google,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [address, setAddress] = useState('')
  const [mapCenter, setMapCenter] = useState({
    longitude: 0,
    latitude: 0,
  })

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const lat = position.coords.latitude
      const lng = position.coords.longitude

      setMapCenter({
        longitude: lng,
        latitude: lat,
      })

      try {
        const { data } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo`,
        )

        const address = data.results[0].formatted_address
        setAddress(data.results[0].formatted_address)
        setValues({
          ...values,
          location: {
            nickname: values.location.nickname,
            longitude: lng,
            latitude: lat,
            address: address,
          },
        })
      } catch (error) {}
    })
  }

  const handleChange = (address) => {
    setAddress(address)
    setValues({
      ...values,
      location: {
        nickname: values.location.nickname,
        longitude: mapCenter.longitude,
        latitude: mapCenter.latitude,
        address: address,
      },
    })
  }

  const handleSelect = (address) => {
    setAddress(address)
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setMapCenter({
          longitude: latLng.lng,
          latitude: latLng.lat,
        })

        setValues({
          ...values,
          location: {
            nickname: values.location.nickname,
            longitude: latLng.lng,
            latitude: latLng.lat,
            address: address,
          },
        })
      })
      .catch((error) => console.error('Error', error))
  }

  async function moveMarker(e, m) {
    try {
      setMapCenter({
        latitude: m.position.lat(),
        longitude: m.position.lng(),
      })

      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${m.position.lat()},${m.position.lng()}&key=YOUR_GOOGLE_MAPS_API_KEY`,
      )
      setAddress(data.results[0].formatted_address)
      setValues({
        ...values,
        location: {
          longitude: m.position.lng(),
          latitude: m.position.lat(),
          address: data.results[0].formatted_address,
          nickname: values.location.nickname,
        },
      })
    } catch (err) {
      toast.error('Error getting address', {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      })
    }
  }

  return (
    <div>
      <h6>Add New Location</h6>
      <div
        className="map-container"
        style={{ position: 'relative', width: isMobile ? '340px' : '550px' }}
      >
        <Map
          google={google}
          zoom={mapCenter.latitude ? 15 : 1}
          center={{
            lat: mapCenter.latitude,
            lng: mapCenter.longitude,
          }}
          containerStyle={{
            position: 'relative',
            width: isMobile ? '' : '100%',
            height: '300px',
            boxShadow:
              '4px 4px 8px rgba(0,0,0,0.2),-4px -4px 8px rgba(255,255,255,1)',
          }}
        >
          {mapCenter.latitude && mapCenter.longitude && (
            <Marker
              title={'Your location'}
              position={{
                lat: mapCenter.latitude,
                lng: mapCenter.longitude,
              }}
              draggable={true}
              onDragend={moveMarker}
            />
          )}
        </Map>
      </div>
      <div>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <label style={{ fontWeight: 'bold' }} className="black-text">
                Search Location
              </label>
              <input
                {...getInputProps({
                  placeholder: '  Search Places ...',
                  className: 'browser-default input-style select-item',
                })}
                style={{ padding: '5px' }}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        padding: 5,
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: 5,
                      }
                  return (
                    <div
                      key={i}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div className="mt-1">
        <label className="black-text" htmlFor="">
          Location Name
        </label>
        <div className="input-field-style">
          <input
            type="text"
            id="id"
            onChange={(e) => {
              setValues({
                ...values,
                location: {
                  ...values.location,
                  nickname: e.target.value,
                },
              })
            }}
            className="browser-default input-date full-width"
            style={{ border: 'none', outline: 'none' }}
            placeholder="Enter a location for the address"
            value={values.location.nickname}
          />
        </div>
      </div>
      <div
        className="flex align-center mt-1"
        onClick={() => {
          getLocation()
        }}
        style={{ cursor: 'pointer' }}
      >
        <span className="material-symbols-outlined cercle-purple-text">
          my_location
        </span>
        <div style={{ marginLeft: '0.5rem' }}>
          <b className="cercle-purple-text">Current Location</b>
          <p className="small-text grey-text">
            Enable your current location for better services
          </p>
        </div>
      </div>
      <div className="flex justify-end gap-1">
        <button
          className="btn-small mt-2 flex justify-end "
          onClick={() => {
            addLocation()
            onCloseAddLocationModal()
            
          }}
          disabled={
            loading || !values.location.nickname || !values.location.address
          }
        >
          Save And Add Location
        </button>
      </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo',
})(AddLocations)
