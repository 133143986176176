export const getPickupsHistorySingleOrgReducer = (
  state = {
    loading: false,
    pickupsHistory: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_PICKUPS_HISTORY':
      return { ...state, loading: true }
    case 'GOT_PICKUPS_HISTORY_SINGLEORG':
      return { ...state, loading: false, pickupsHistory: action.payload }
    case 'CANNOT_GET_PICKUPS_HISTORY':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getBrandPickupsReducer = (
  state = {
    loading: false,
    pickups: [],
  },
  action,
) => {
  switch (action.type) {
    case 'RAISE_PICKUP':
      return { ...state, loading: true }
    case 'RAISED_PICKUP':
      return { ...state, loading: false, pickups: action.payload }
    case 'CANNOT_RAISE_PICKUP':
      return { ...state, loading: false }
    default:
      return state
  }
}
