import React from "react";

const CardAnalytics = ({ type, pickupCount, pickupType }) => {
  return (
    <>
      {type === 1 ? (
        <div className="plan-container full-width row" style={{position: 'relative'}}>
          <div className="p-1" >
            <h4 className="cercle-purple-text">Current Plan</h4>
            <p>0</p>
          </div>
          <div
            className="green-text flex align-center gap-2"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              borderRadius: '0 12px',
              padding: '4px',
              border: '1px solid #27AE60',
              background: '#F5FFF9',
            }}
          >
            <span className="active-dot"></span>
            <p>Active</p>
          </div>
          <div
            className="flex align-center gap-1 cercle-purple-text"
            style={{ background: "#FFFDBE", paddingBlock: "0.2rem" }}
          >
            <span className="material-symbols-outlined ml-1">acute</span>
            <p>0 Days remaining in this plan</p>
          </div>
          <div
            style={{
              background: "linear-gradient(91deg, #6F2DA8 0%, #BA68C8 100%)",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
            className="p-1 flex justify-end"
          >
            <div
              style={{
                paddingBlock: "0.5rem",
                paddingInline: "0.7rem",
                borderRadius: "6px",
              }}
              className="flex align-center justify-center gap-1 cercle-purple-text white small-text"
            >
              <b>RENEW YOUR PLAN</b>
              <span className="material-symbols-outlined">arrow_outward</span>
            </div>
          </div>
        </div>
      ) : type === 2 ? (
        <div className="plan-container full-width">
          <div className="p-1">
            <h2 className="cercle-purple-text">{pickupCount}</h2>
            <b>{pickupType} Pickups</b>
          </div>
          <div
            style={{
              background: "linear-gradient(91deg, #6F2DA8 0%, #BA68C8 100%)",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              padding: '0.5rem'
            }}
            className="flex justify-center gap-1 small-text white-text align-center"
          >
            <b>BUY MORE PICKUPS</b>
            <span className="material-symbols-outlined">arrow_outward</span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CardAnalytics;
