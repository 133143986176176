import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import Loading from '../../../layout/Loading'

export default function AllNetworkList() {
  const isMobile = useMediaQuery({ query: '(max-width:768px)' })

  const { networkOfAnOrganization, loading } = useSelector(
    (state) => state.networkOfAnOrganization,
  )
  if (loading) {
    return <Loading />
  }

  return (
    <div className={isMobile ? '' : ' full-width pb-1  p-1 '}>
      <span className="flex align-center space-between ">
        <b className="normal-size">Network List</b>
      </span>
      <div className="full-width card ">
        {networkOfAnOrganization && networkOfAnOrganization.length === 0 ? (
          <div className="flex column align-center p-1">
            <img src="../images/no-data.svg" alt="no-data" width={100} />
            <p className="grey-text">
              Sorry, currently you don’t have any data
            </p>
          </div>
        ) : (
          <table className="striped p-1">
            <thead>
              <tr>
              <th className="purple-text">Name</th>
              <th className="purple-text">Phone Number</th>
              <th className="purple-text">Locations</th>
              <th className="purple-text">Email</th>
              <th className="purple-text">Role</th>
              <th className="purple-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {networkOfAnOrganization?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.organization?.name}
                    </td>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.organization.owner?.phoneNumber}
                    </td>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.locations.length}
                    </td>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.organization.owner?.email}
                    </td>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.role}
                    </td>
                    <td
                      className={
                        item.wasInvited ? 'active-status' : 'cancel-status'
                      }
                    >
                      {item.wasInvited ? 'Active' : 'cancel'}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
