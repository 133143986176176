import React from 'react'
import TraderAnalytics from '../TraderAnalytics'
import RevenueGeneratorChart from './RevenueGeneratorChart'
import TotalPickupChart from './TodayPickupChart'
import { useMediaQuery } from 'react-responsive'
import AnalyticsCard from '../../../../modules/AnalyticsCard'


const cardData = [
  {
    icon: 'local_shipping',
    bgColor: 'rgba(52, 152, 219, 0.20)',
    iconColor: 'rgba(52, 122, 226, 1)',
  },

  {
    icon: 'local_shipping',
    bgColor: 'rgba(255, 176, 58, 0.20)',
    iconColor: 'rgba(255, 136, 27, 1)',
  },
  {
    icon: 'group',
    bgColor: 'rgba(178, 164, 255, 0.20)',
    iconColor: 'rgba(111, 45, 168, 1)',
  },
  {
    icon: 'navigation',
    bgColor: '#FFF9DB',
    iconColor: 'rgba(255, 212, 0, 1)',
  },

]

export default function SingleTraderAnalitics({
  summary,
}) {
  

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  return (
    <div className={isTablet ? 'flex column gap-1' : 'flex gap-1 mt-1'}>
      {/* <TraderAnalytics organizationDetails={organizationDetails} summary={summary}/> */}
      <div className={isMobile ? 'flex column gap-1' : 'flex full-width gap-2'}>
        <div className="full-width flex column gap-1">
        <AnalyticsCard
              count={`${summary.totalPickups ? summary.totalPickups : 0}`}
              title="Total Pickups"
              trend="20%"
              icon={cardData[0].icon}
              bgColor={cardData[0].bgColor}
              iconColor={cardData[0].iconColor}
            />
             <AnalyticsCard
              count={`${summary.totalPendingPickups ? summary.totalPendingPickups : 0}`}
              title="Pending Pickups "
              trend="20%"
              icon={cardData[1].icon}
              bgColor={cardData[1].bgColor}
              iconColor={cardData[1].iconColor}
            />
        </div>
        <div className="full-width flex column gap-1">
        <AnalyticsCard
              count={`${summary.totalNumberOfNetworkMembers ? summary.totalNumberOfNetworkMembers : 0}`}
              title="Network Members"
              trend="20%"
              icon={cardData[2].icon}
              bgColor={cardData[2].bgColor}
              iconColor={cardData[2].iconColor}
            />
             <AnalyticsCard
              count={`${summary.totalNumberOfLocations
                ? summary.totalNumberOfLocations
                : 0}`}
              title="Locations"
              trend="20%"
              icon={cardData[3].icon}
              bgColor={cardData[3].bgColor}
              iconColor={cardData[3].iconColor}
            />
        </div>
      </div>
      <RevenueGeneratorChart />
      <TotalPickupChart />
    </div>
  )
}
