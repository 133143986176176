import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getSingleProcessorAction,
  getSingleProcessorVehiclesAction,
} from "../../../../redux/action/processor";
function BrandDetails({
  step,
  setStep,
  handleChange,
  pickupData,
  setPickupData,
}) {
  const { organization, loading } = useSelector(
    (state) => state.singleOrganization
  );
  const dispatch = useDispatch();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { loading: networkLoading, networkOfAnOrganization } = useSelector(
    (state) => state.networkOfAnOrganization
  );
  const { id } = useParams();
  const fetchProcessor = (id) => {
    dispatch(getSingleProcessorAction(id));
    dispatch(getSingleProcessorVehiclesAction(id));
  };

  const handleDateChange = (value) => {
    setPickupData((prevData) => {
      return {
        ...prevData,
        // [name]: value,
        date: value.target.value,
      };
    });
  };

  useEffect(() => {
    const initialPickupData = pickupData;

    const handleChange = () => {
      setHasUnsavedChanges(
        JSON.stringify(initialPickupData) !== JSON.stringify(pickupData)
      );
    };
    window.addEventListener("beforeunload", (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    });

    return () => {
      window.removeEventListener("beforeunload", handleChange);
    };
  }, [pickupData]);

  const handleNextStep = () => {
    if (hasUnsavedChanges) {
      confirmAlert({
        title: "Unsaved Changes",
        message: "Do you want to save your changes before proceeding?",
        buttons: [
          {
            label: "Save",
            onClick: () => {
              // Implement saving logic here
              // ...
              setStep(step + 1);
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setStep(step + 1);
            },
          },
          {
            label: "Cancel",
          },
        ],
      });
    } else {
      setStep(step + 1);
    }
  };

  if (loading || networkLoading) return <div>Loading...</div>;

  return (
    <div className="p-1 white">
      <label>Select Pickup Location*</label>
      <select
        className="browser-default input-style mb-1"
        name="select-pickup-location"
        onChange={(e) => {
          setPickupData((oldData) => ({
            ...oldData,
            pickupLocation: JSON.parse(e.target.value),
          }));
        }}
      >
        <option value="">Select an option</option>
        {organization.locations?.map((item, index) => {
          const isSelected =
            pickupData.pickupLocation &&
            pickupData.pickupLocation.latitude === item.latitude &&
            pickupData.pickupLocation.longitude === item.longitude;
          return (
            <option
              key={index}
              value={JSON.stringify({
                latitude: item.latitude,
                longitude: item.longitude,
                address: item.address,
                nickname: item.nickname,
              })}
              selected={isSelected}
            >
              {item.nickname}-{item.address}
            </option>
          );
        })}
      </select>

      <label>Select Date*</label>
      <div className="flex  input-style align-center  custom-datepicker">
        <input
          type="date"
          name="date"
          value={pickupData.date}
          onChange={(e) => {
            handleDateChange(e);
          }}
        />
      </div>

      <div className="mt-1 mb-1 ">
        <label>Select Scrap Type*</label>
        <select
          className="browser-default input-style "
          name="select-pickup-location"
          onChange={(e) => {
            setPickupData((oldData) => ({
              ...oldData,
              scrap: e.target.value,
            }));
          }}
        >
          <option value="">Select an option</option>
          {organization.scraps?.map((item, index) => {
            const isSelected = item._id === pickupData.scrap;
            return (
              <option key={index} value={item._id} selected={isSelected}>
                {item.type}-{item.variant}
              </option>
            );
          })}
        </select>
      </div>

      <label>Select Processor*</label>
      <select
        className="browser-default input-style mb-1"
        name="select-pickup-location"
        onChange={(e) => {
          setPickupData((oldData) => ({
            ...oldData,
            processor: e.target.value,
          }));
          fetchProcessor(e.target.value);
        }}
      >
        <option value="">Select an option</option>
        {pickupData.processor && (
          <option
            key={pickupData.processor}
            value={pickupData.processor}
            selected
          >
            {/* Assuming networkOfAnOrganization contains processor details */}
            {
              networkOfAnOrganization.find(
                (item) => item.organization._id === pickupData.processor
              )?.organization.name
            }
          </option>
        )}
        {networkOfAnOrganization?.map((item, index) => {
          if (item.role === "trader/aggregator")
            return (
              <option key={index} value={item.organization._id}>
                {item.organization.name}
              </option>
            );
        })}
      </select>

      <div>
        <label>Price*</label>
        <input
          type="number"
          name="price"
          onChange={(e) => {
            handleChange(e);
          }}
          className="browser-default input-style mb-1"
          placeholder="Enter price"
          value={pickupData.price}
        />
      </div>

      <div className="flex align-center justify-end mt-1 ">
        <button
          className="cercle-btn btn "
          onClick={handleNextStep}
          disabled={
            !pickupData.date || !pickupData.scrap || !pickupData.pickupLocation
          }
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default BrandDetails;
