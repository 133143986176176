import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addNewPickupAction } from '../../../../redux/action/pickups'
import { toast } from 'react-toastify'
function LogisticsDetails({
  step,
  setStep,
  handleChange,
  setPickupData,
  pickupData,
}) {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(pickupData)
  const [tempFileData, setTempFileData] = useState({})

  const handleFileUpload = (e, fileType) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }

    if (
      (fileType === 'eWaybill' || fileType === 'invoiceManifest') &&
      !file.type.includes('pdf')
    ) {
      toast.error(`Please upload only PDF files for ${fileType}`)
      return
    }

    if (fileType === 'image_0' && !file.type.includes('image')) {
      toast.error('Please upload only image files for Scrap Image')
      return
    }

    setPickupData((oldData) => ({ ...oldData, [fileType]: file }))
    setTempFileData((oldData) => ({ ...oldData, [fileType]: file }))
    setFormData((prevFormData) => ({ ...prevFormData, [fileType]: file }))
  }

  useEffect(() => {
    setPickupData((prevData) => ({
      ...prevData,
      ...tempFileData,
    }))
  }, [tempFileData])

  useEffect(() => {
    setPickupData((prevData) => ({
      ...prevData,
      ...tempFileData,
    }))
  }, [tempFileData])

  const handleSubmit = () => {
    const formData = new FormData()

    formData.append('generator', pickupData.generator)
    formData.append('processor', pickupData.processor)
    formData.append('scrap', pickupData.scrap)
    formData.append('quantity', pickupData.quantity)
    formData.append('price', pickupData.price)
    formData.append('date', pickupData.date)
    formData.append('image_0', pickupData.image_0)

    const pickupLocationObj = {
      latitude: pickupData.pickupLocation.latitude,
      longitude: pickupData.pickupLocation.longitude,
      address: pickupData.pickupLocation.address,
      nickname: pickupData.pickupLocation.nickname,
    }

    formData.append('pickupLocation', JSON.stringify(pickupLocationObj))

    formData.append('dropLocation', JSON.stringify(pickupData.dropLocation))

    formData.append('numberOfVehicles', pickupData.numberOfVehicles)

    formData.append('drivers', JSON.stringify(pickupData.drivers))
    formData.append('vehicles', `["${pickupData.vehicles}"]`)

    formData.append('eWaybill', pickupData.eWaybill)
    formData.append('invoiceManifest', pickupData.invoiceManifest)

    dispatch(addNewPickupAction(formData,setStep))
  }

  return (
    <div className="white p-1">
      {pickupData.numberOfVehicles && (
        <div>
          <label>Enter Quantity*</label>
          <input
            className="browser-default input-style"
            placeholder="Enter Quantity"
            name="quantity"
            type="number"
            value={pickupData.quantity}
            onChange={(e) => handleChange(e)}
          />
        </div>
      )}

      <span className="full-width">
        <label>eWaybill*</label>
        <input
          onChange={(e) => {
            handleFileUpload(e, 'eWaybill')
          }}
          className="browser-default custom-file-input input-style "
          type="file"
          placeholder="Enter eWaybill"
          accept="application/pdf"
        />
      </span>

      <span className="full-width  mt-1">
        <label>Invoice Manifest*</label>
        <input
          onChange={(e) => {
            handleFileUpload(e, 'invoiceManifest')
          }}
          className="browser-default custom-file-input input-style "
          type="file"
          accept="application/pdf/*"
        />
      </span>

      <span className="full-width mt-1">
        <label>Scrap Image*</label>
        <input
          onChange={(e) => {
            handleFileUpload(e, 'image_0')
          }}
          className="browser-default custom-file-input input-style "
          type="file"
          accept="image/*"
        />
      </span>

      <div className="flex align-center  space-between mt-2">
        <button
          className=" btn-outline cercle-btn "
          onClick={() => setStep(step - 1)}
        >
          Back
        </button>
        <button
          className="btn cercle-btn "
          disabled={
            !pickupData.quantity ||
            !pickupData.image_0
          }
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default LogisticsDetails
