import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import Loading from '../../../layout/Loading'
import { AiOutlineEye } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function AllNetworks() {
  const isMobile = useMediaQuery({ query: '(max-width:768px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const { allNetwork, loading } = useSelector((state) => state.allNetworkStore)
  console.log(allNetwork, 'allNetwork')
  if (loading) {
    return <Loading />
  }

  return (
    <div className={isMobile ? '' : ' full-width pb-1  p-1 '}>
      <span className="flex align-center space-between ">
        <b className="normal-size">Network List</b>
      </span>
      <div className="full-width card ">
        {allNetwork && allNetwork.length === 0 ? (
          <div className="flex column align-center p-1">
            <img src="../images/no-data.svg" alt="no-data" width={100} />
            <p className="grey-text">
              Sorry, currently you don’t have any data
            </p>
          </div>
        ) : (
          <table className="striped p-1">
            <thead>
              <tr>
                <th className="purple-text">Organization</th>
                <th className="purple-text">Role</th>
                <th className="purple-text">Locations</th>
                <th className="purple-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {allNetwork?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={isTablet && 'small-text'}>{item.name}</td>
                    <td className={isTablet && 'small-text'}>{item.type}</td>
                    <td className={isTablet && 'green-text small-text'}>
                      {item.locations.length}
                    </td>
                    <td>
                      <Link to={`/single-organization/${item._id}`}>
                        <AiOutlineEye size={18} />
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
