import React, { useState } from 'react'
import { Pagination, Icon } from 'react-materialize'
import Modal from 'react-responsive-modal'
import AddNewSubscriptionBrand from './AddNewSubscriptionBrand'
import { useMediaQuery } from 'react-responsive'

const Data = [
  {
    brand: 'GLOBEX CORPORATION ( THE SIMPSON)',
    startDate: 'MON OCT 31 2023',
    billingDate: 'JUN 12 2024',
    adminName: 'Ananda krishnan',
    payment: 430000,
    availablePickups: 20,
    emergencyPickup: 2,
  },
  {
    brand: 'GLOBEX CORPORATION ( THE SIMPSON)',
    startDate: 'MON OCT 31 2023',
    billingDate: 'JUN 12 2024',
    adminName: 'Ananda krishnan',
    payment: 430000,
    availablePickups: 20,
    emergencyPickup: 2,
  },
  {
    brand: 'GLOBEX CORPORATION ( THE SIMPSON)',
    startDate: 'MON OCT 31 2023',
    billingDate: 'JUN 12 2024',
    adminName: 'Ananda krishnan',
    payment: 430000,
    availablePickups: 20,
    emergencyPickup: 2,
  },
  {
    brand: 'GLOBEX CORPORATION ( THE SIMPSON)',
    startDate: 'MON OCT 31 2023',
    billingDate: 'JUN 12 2024',
    adminName: 'Ananda krishnan',
    payment: 430000,
    availablePickups: 20,
    emergencyPickup: 2,
  },
]

export default function SubscriptionList() {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  return (
    <div className="historytable white mt-1" style={{ borderRadius: '7px' }}>
      <div className="p-1">
        <div className={isMobile ? 'flex column' : 'flex'}>
          <div className="flex full-width">
            <h5>Subscription list</h5>
          </div>
          <div className="flex full-width justify-end gap-1">
            <div
              className="input-field-style flex align-center small-text "
              style={{
                margin: 0,
                color: '#BFC3CE',
                background: '#FAFAFA',
                width: isMobile ? '100%' : '50%',
                marginRight: '10px',
              }}
            >
              <span className="material-symbols-outlined mr-1">search</span>
              <input
                style={{ margin: 0, caretColor: '#000000' }}
                placeholder="Search"
                className="hide-focus-underline"
              />
            </div>
            <button className="btn-outline">EXPORT</button>
            <button className="btn" onClick={onOpenModal}>
              ADD NEW
            </button>
          </div>

          <Modal
            center
            styles={{
              modal: {
                borderRadius: '10px',
                padding: 0,
                width: isTablet ? '380px' : '800px',
                margin: 0,
              },
            }}
            open={open}
            onClose={onCloseModal}
            closeIcon={<></>}
          >
            <>
              <div className="cercle-purple p-1 white-text flex align-center space-between">
                <h4>Add New Brand</h4>
                <div
                  onClick={onCloseModal}
                  className="flex align-center justify-center"
                  style={{
                    width: 40,
                    height: 40,
                    background: 'rgba(255, 255, 255, 0.50)',
                    borderRadius: '6px',
                    cursor: 'pointer',
                  }}
                >
                  <span className="material-symbols-outlined">close</span>
                </div>
              </div>
              <AddNewSubscriptionBrand />
            </>
          </Modal>
        </div>
        <div
          className="subscriptiontable"
          style={{ overflowX: isMobile ? 'auto' : '' }}
        >
          <table>
            <thead>
              <tr>
                <th>
                  <span className="flex ml-1">
                    {/* <Checkbox
                     checked 
                    filledIn 
                    value="Red" 
                    /> */}
                    BRAND
                  </span>
                </th>
                <th>STATUS</th>
                <th>START DATE</th>
                <th>NEXT BILLING DATE </th>
                <th>ADMIN</th>
                <th>PAYMENT</th>
                <th>AVAILABLE PICKUPS</th>
                <th>EMERGENCY</th>
                <th>ACTION</th>
              </tr>
            </thead>

            <tbody>
              {Data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span className="flex  ml-1">
                        {/* <Checkbox
                          checked
                          filledIn
                          value="Red"
                          label={item.brand || 'Default Label'}
                        /> */}
                        {item.brand}
                      </span>
                    </td>
                    <td className="capitalize">
                      <div
                        className="flex align-center gap-1 justify-center"
                        style={{
                          width: '80px',
                          border: '1px solid #27AE60',
                          background: '#F5FFF9',
                          borderRadius: '20px',
                          padding: 0,
                        }}
                      >
                        <span className="active-dot"></span>
                        <p className="green-text">Active</p>
                      </div>
                    </td>
                    <td className="lowercase">{item.startDate}</td>
                    <td>{item?.billingDate}</td>
                    <td className="capitalize">{item?.adminName}</td>
                    <td className="">{item.payment}</td>
                    <td>{item.availablePickups}</td>
                    <td>{item.emergencyPickup}</td>
                    <td>
                      <span
                        className="material-symbols-outlined"
                        style={{
                          color: 'rgba(111, 45, 168, 1)',
                        }}
                      >
                        visibility
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{
                          color: 'rgba(111, 45, 168, 1)',
                        }}
                      >
                        edit
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="table-footer mb-1">
        <Pagination
          activePage={3}
          items={5}
          leftBtn={<Icon>chevron_left</Icon>}
          rightBtn={<Icon>chevron_right</Icon>}
        />
      </div>
    </div>
  )
}
