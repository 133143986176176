import React, { useEffect } from 'react'
import PickupTimeLine from './components/PickupTimeLine'
import { getSingleOrganizationAction } from '../../redux/action/organization'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'

export default function SingleBrandPickups() {
  const dispacth = useDispatch()
  const { id } = useParams()
  const location = useLocation()
  const pickupsHistory = location.state.organizationId

  useEffect(() => {
    dispacth(getSingleOrganizationAction(id))
  }, [dispacth, id])

  return (
    <div>
      <PickupTimeLine orgId={pickupsHistory} />
    </div>
  )
}
