export const accountReducer = (
  state = { loading: false, account: {} },
  action,
) => {
  switch (action.type) {
    case 'GETTING_ACCOUNT':
      return { ...state, loading: true }
    case 'GOT_ACCOUNT':
      return { ...state, loading: false, account: action.payload }
    case 'CANNOT_GET_ACCOUNT':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getOrganizationReducer = (
  state = {
    organization: {},
    orgLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_ORGANIZATION':
      return { ...state, loading: true }
    case 'GOT_ORGANIZATION':
      return { ...state, loading: false, organization: action.payload }
    case 'CANNOT_GET_ORGANIZATION':
      return { ...state, loading: false }
    default:
      return state
  }
}
