import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getCurrentDateTime } from '../../../helpers/helper'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getSingleOrganizationAction } from '../../../redux/action/organization'

function AllContractList() {
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
  const dispacth = useDispatch()
  const { id } = useParams()
  const { contracts } = useSelector((state) => state.contracts)
  const { organization } = useSelector((state) => state.singleOrganization)

  useEffect(() => {
    dispacth(getSingleOrganizationAction(id))
  }, [dispacth, id])

  return (
    <div>
      <b>
        Contract List - <span className="purple-text">{organization.name}</span>
      </b>
      <div className="white p-1" style={{ overflowX: isTablet ? 'auto' : '' }}>
        <table
          className={
            !isTablet ? 'striped full-width' : 'striped full-width isTablet'
          }
        >
          <thead>
            <tr>
              <th className="purple-text normal-text">#</th>
              <th className="purple-text">Processor</th>
              <th className="purple-text">Scrap</th>
              <th className="purple-text">Start Date</th>
              <th className="purple-text">End Date</th>
            </tr>
          </thead>
          <tbody>
            {contracts?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.processor.name}</td>
                <td>
                  {item.scrap.name} - {item.scrap.variant}
                </td>
                <td>{getCurrentDateTime(item.start)}</td>
                <td>{getCurrentDateTime(item.end)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AllContractList
