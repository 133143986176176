import React,{useState} from 'react'
import Modal from 'react-responsive-modal'
import RenewalReminder from './RenewalReminder'
import { useMediaQuery } from 'react-responsive';

export default function SubscriptionNotification() {
  const isTablet = useMediaQuery({ query: "(max-width: 1330px)" });
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  return (
    <div style={{position:'relative'}}>
    <img src='../images/subscriptionnotification.svg'  alt='subscriptionnotification' width={isMobile ? 380:315} />
    <span style={{position:'absolute',left:isMobile ? '100px':'70px',bottom:'15px'}}>
      <button className='btn-outline' onClick={onOpenModal}>Send Notification</button>

      <Modal
            center
            styles={{
              modal: {
                borderRadius: '10px',
                padding: 0,
                width: isTablet ? '380px':'800px',
                margin: 0,
              },
            }}
            open={open}
            onClose={onCloseModal}
            closeIcon={<></>}
          >
            <>
              <div className="cercle-purple p-1 white-text flex align-center space-between">
                <h4>Renewal Reminders </h4>
                <div
                  onClick={onCloseModal}
                  className="flex align-center justify-center"
                  style={{
                    width: 40,
                    height: 40,
                    background: 'rgba(255, 255, 255, 0.50)',
                    borderRadius: '6px',
                    cursor: 'pointer',
                  }}
                >
                  <span className="material-symbols-outlined">close</span>
                </div>
              </div>
              <RenewalReminder />
            </>
          </Modal>
    </span>
    </div>
  )
}
