import React, { useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']


const RevenueGeneratorChart = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [clickedCell, setClickedCell] = useState(null);

  const { locationchart } = useSelector((state) => state.locationchart)

  const handleCellClick = (data) => {
    setClickedCell(data)
  }
  return (
    <div
      className="white p-1"
      style={{
        width: isMobile ? '100%' : '33%',
        height: '360px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <h5 style={{ textAlign: 'center' }}>% Revenue generated by Scrap</h5>
      <hr color="#E5E5EF" />
      {locationchart.length === 0 ? ( // Check if quantity array is empty
      <div className='flex align-center justify-center' style={{height:'300px'}}>
        <p>No data available</p>
        </div>
      ) : (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={locationchart}
              cx="50%"
              cy="35%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="quantity"
              labelLine={false}
              onClick={handleCellClick}
            >
              {locationchart.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ outline: 'none' }}
                />
              ))}
            </Pie>
            <Tooltip
              cursor={{ fill: 'transparent' }}
              contentStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                border: 'none',
              }}
              formatter={(value, name, props) => {
                if (
                  props.payload &&
                  props.payload.location &&
                  props.payload.quantity
                ) {
                  const formattedQuantity = props.payload.quantity.toFixed(2);
                  return [
                    `Location: ${props.payload.location}`,
                    `Quantity: ${formattedQuantity}`,
                  ]
                }
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>)}
      <div
        className="flex space-between align-center full-width p-1"
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '00px',
          textAlign: 'right',
        }}
      >
        <div className="scrap-name">
          <p
            style={{
              color: 'grey',
              fontSize: isMobile ? '16px' : '18px',
              position: 'absolute',
              top: '5px',
            }}
          >
             {clickedCell ? 'Location' : ''} 
          </p>
          <p
            style={{ marginTop: '15px' }}
            className={isMobile ? 'small-text' : ''}
          >
            {clickedCell ? clickedCell.location : ''}
          </p>
        </div>
        <div className="mt-1 scrap-value">
          <p
            style={{
              color: 'grey',
              fontSize: isMobile ? '16px' : '18px',
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
             {clickedCell ? 'Quantity' : ''} 
          </p>
          <p className={isMobile ? 'small-text' : ''}>
            {clickedCell ? clickedCell.quantity : ''} 
          </p>
        </div>
      </div>
    </div>
  )
}

export default RevenueGeneratorChart
