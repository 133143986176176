import React, { useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
]
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const QuantityChart = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [clickedCell, setClickedCell] = useState(null)

  const handleCellClick = (data) => {
    setClickedCell(data)
  }

  return (
    <div
      className="white p-1"
      style={{
        width: isMobile ? '100%' : '33%',
        height: '350px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <h5 style={{ textAlign: 'center' }}>% Scrap generated by quantity</h5>
      <hr color="#E5E5EF" />
      <div
        className="flex column gap-2"
        style={{
          position: 'absolute',
          bottom: '80px',
          right: '40px',
          textAlign: 'right',
        }}
      >
        <div>
          <p style={{ color: 'grey', fontSize: isMobile ? '16px' : '18px' }}>
            Scrap
          </p>
          <h5 className={isMobile ? 'small-text' : ''}>Food</h5>
        </div>
        <div>
          <p style={{ color: 'grey', fontSize: isMobile ? '16px' : '18px' }}>
            Scrap Quantity
          </p>
          <h5 className={isMobile ? 'small-text' : ''}>30 Tons</h5>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              cx="30%"
              cy="50%"
              innerRadius={60}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              labelLine={false}
              onClick={handleCellClick}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ outline: 'none' }}
                />
              ))}

              {clickedCell && (
                <Label
                  key={`center-label`}
                  value={`${clickedCell.name}: ${clickedCell.value}`}
                  position="center"
                  fontSize={isMobile ? 12 : 18}
                />
              )}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default QuantityChart
