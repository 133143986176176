import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { getCurrentDateTime } from '../../../helpers/helper'
import { useNavigate } from 'react-router'

function AllPickupsHistory() {
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const { history } = useSelector((state) => state.history)
  return (
    <div
      className="historytable white p-1 mt-1"
      style={{ borderRadius: '7px' }}
    >
      <div className="flex space-between align-center">
        <h5>Overall Pickups History</h5>
      </div>
      <div
        className="accounttable "
        style={{ overflowX: isMobile ? 'auto' : '' }}
      >
        <table>
          <thead>
            <tr>
              <th>PICKUP DATE</th>
              <th>SCRAP</th>
              <th>QUANTITY</th>
              <th>PROCESSOR</th>
              <th>PRICE</th>
              <th>STATUS</th>
              <th>ACTION</th>
            </tr>
          </thead>

          <tbody>
            {history?.map((item, index) => {
              let progressWidth = '0%'
              let progressColor = 'rgba(234, 236, 240, 1)'

              if (item.status === 'requested') {
                progressWidth = '25%'
                progressColor = 'rgba(255, 212, 0, 1)'
              } else if (item.status === 'accepted') {
                progressWidth = '50%'
                progressColor = 'rgba(255, 136, 27, 1)'
              } else if (item.status === 'assigned') {
                progressWidth = '75%'
                progressColor = 'rgba(56, 113, 223, 1)'
              } else if (item.status === 'completed') {
                progressWidth = '100%'
                progressColor = 'rgba(39, 174, 96, 1)'
              }

              return (
                <tr key={index}>
                  <td>{getCurrentDateTime(item.date)}</td>
                  <td className={isTablet && 'small-text'}>
                    {item.scrap.name}
                    {item.scrap.variant}
                  </td>
                  <td className={isTablet && 'small-text'}>
                    {item.quantity ? item.quantity + 'MT' : '---'}
                  </td>
                  <td className="capitalize">{item?.processor?.name}</td>
                  <td className="lowercase">RS. {item.price}/KG</td>

                  <td className="flex justify-center">
                    <div
                      className="main-progress-bar"
                      title={` ${item.status}`}
                    >
                      <div
                        className="progress-bar"
                        style={{
                          width: progressWidth,
                          backgroundColor: progressColor,
                        }}
                      >
                        {progressWidth === '100%' && (
                          <div
                            className="flex align-center justify-center gap-1"
                            style={{ fontSize: '10px', padding: '3px 0' }}
                          >
                            <span className="status-hover white-text">
                              Completed
                            </span>
                            <span
                              className="material-symbols-outlined white-text"
                              style={{ fontSize: '14px' }}
                            >
                              new_releases
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      className="material-symbols-outlined pointer"
                      style={{
                        color: 'rgba(111, 45, 168, 1)',
                        fontSize: '16px',
                      }}
                      onClick={() =>
                        navigate(`/singlebrandpickupview/${item._id}`, {
                          state: { organizationId: item },
                        })
                      }
                    >
                      visibility
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AllPickupsHistory
