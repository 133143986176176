import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
  getAllContractsReducer,
  getOrganizationContractReducer,
} from './reducer/contracts'

import { getAllNetworkReducer } from './reducer/network'
import { accountReducer } from './reducer/auth'
import { loadingReducer } from './reducer/loading'
import traderIdReducer, {
  getSingleOrganizationReducer,
  getPickupsHistorySingleOrgReducer,
  getNetworkOfAnOrganizationReducer,
  organizationReducer,
  getAllPickupsProgressReducer,
  getSingleOrgSummaryReducer,
  getAllScrapsReducer,
  getHistorySingleOrgReducer,
  getQuantityPerScrapReducer,
  getLocationChartReducer,
  getPickupsHistorySingleOrgReducerV2,
  getPaginationReducer,
} from './reducer/organization'

import { getBrandPickupsReducer } from './reducer/pickups'
import { getVehicleReducer } from './reducer/driver'
import {
  getSingleProcessorReducer,
  getSingleProcessorVehiclesReducer,
} from './reducer/processor'
import { getReportAnalyticsReducer } from './reducer/report'
import { getOverviewReducer } from './reducer/overview'

const reducer = combineReducers({
  allContracts: getAllContractsReducer,
  allNetworkStore: getAllNetworkReducer,
  account: accountReducer,
  singleOrganization: getSingleOrganizationReducer,
  singleOrganizationPickupsHistory: getPickupsHistorySingleOrgReducer,
  networkOfAnOrganization: getNetworkOfAnOrganizationReducer,
  loading: loadingReducer,
  organization: organizationReducer,
  pickups: getBrandPickupsReducer,
  progress: getAllPickupsProgressReducer,
  vehicle: getVehicleReducer,
  processor: getSingleProcessorReducer,
  reportAnalytics: getReportAnalyticsReducer,
  contracts: getOrganizationContractReducer,
  overview: getOverviewReducer,
  summary: getSingleOrgSummaryReducer,
  scraps: getAllScrapsReducer,
  history: getHistorySingleOrgReducer,
  quantity: getQuantityPerScrapReducer,
  locationchart: getLocationChartReducer,
  pickupsHistoryV2: getPickupsHistorySingleOrgReducerV2,
  processorVehicles: getSingleProcessorVehiclesReducer,
  traderId: traderIdReducer,
  pagination: getPaginationReducer,
})

const initialstate = {}
const middleware = [thunk]
const store = createStore(
  reducer,
  initialstate,
  composeWithDevTools(applyMiddleware(...middleware)),
)

export default store
