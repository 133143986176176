import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { DatePicker } from 'react-materialize'
import { useDispatch } from 'react-redux'
import { AddNewPickupsAction } from '../../../redux/action/pickups'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import addSeconds from 'date-fns/addSeconds'
import {
  getSingleOrganizationAction,
} from '../../../redux/action/organization'
import { useParams } from 'react-router'
import { getSingleProcessorAction } from '../../../redux/action/processor'

export default function AddNewPickups() {
  const { allNetwork } = useSelector((state) => state.allNetworkStore)
  const filterTraderList = allNetwork?.filter(
    (item) => item.type === 'trader/aggregator',
  )

  const dispacth = useDispatch()
  const { id } = useParams()
  const { organization } = useSelector((state) => state.singleOrganization)

  useEffect(() => {
    dispacth(getSingleOrganizationAction(id))
  }, [dispacth])

  const dispatch = useDispatch()
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
  const [date, setDate] = useState('')

  const { pickupsHistory } = useSelector(
    (state) => state.singleOrganizationPickupsHistory,
  )

  const [availableDropLocations, setAvailableDropLocations] = useState([])
  const [availableDrivers, setAvailableDrivers] = useState([])
  const [availableVehicles, setAvailableVehicles] = useState([])

  const initialAddNewPickupsState = {
    generator: organization ? organization._id : '',
    processor: '',
    scrap: '',
    quantity: '',
    price: 0,
    date: '',
    pickupLocation: {
      latitude: '',
      longitude: '',
      address: '',
      nickname: '',
    },
    dropLocation: {
      latitude: '',
      longitude: '',
      address: '',
      nickname: '',
    },
    numberOfVehicles: 0,
    drivers: '',
    vehicles: '',
    eWaybill: '',
    invoiceManifest: '',
    image_0: '',
  }

  const [addNewPickups, setAddNewPickups] = useState(initialAddNewPickupsState)
  const [selectedProcessor, setSelectedProcessor] = useState('')
  const [dropLocations, setDropLocations] = useState([])
  const [driverNames, setDriverNames] = useState([])

  const handleChangeArray = (event) => {
    const { name, value } = event.target

    if (name === 'drivers' || name === 'vehicles') {
      const selectedIds = Array.from(
        event.target.selectedOptions,
        (option) => option.value,
      )
      setAddNewPickups({ ...addNewPickups, [name]: selectedIds })
      console.log(selectedIds, 'form data-----')
    } else {
      let updatedValue = value

      if (name !== 'type') {
        updatedValue = value.toUpperCase()
      }

      setAddNewPickups({ ...addNewPickups, [name]: [updatedValue] })
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'processor') {
      if (selectedProcessor) {
        const selectedProcessorLocations = selectedProcessor.locations || []
        const uniqueProcessorLocations = selectedProcessorLocations
          .filter((location, index, self) => {
            return (
              index ===
              self.findIndex(
                (l) =>
                  l.nickname === location.nickname &&
                  l.address === location.address,
              )
            )
          })
          .map((location) => `${location.nickname} - ${location.address}`)

        setAvailableDropLocations(uniqueProcessorLocations)

        const selectedProcessorMembers = selectedProcessor.members || []
        const driverNames = selectedProcessorMembers
          .filter((member) => member.role === 'driver')
          .map((driver) => driver.name)

        setAvailableDrivers(driverNames)
      } else {
        setAvailableDropLocations([])
        setAvailableDrivers([])
      }
    }

    if (name === 'drivers' || name === 'vehicles') {
      const selectedIds = Array.from(
        event.target.selectedOptions,
        (option) => option.value,
      )
      setAddNewPickups({ ...addNewPickups, [name]: selectedIds })
    } else if (name === 'pickupLocation' || name === 'dropLocation') {
      const [nickname, address] = value.split(' - ')

      setAddNewPickups({
        ...addNewPickups,
        [name]: {
          ...addNewPickups[name],
          address,
          nickname,
          latitude: parseInt(0, 10), // Set latitude based on the selected address
          longitude: parseInt(0, 10), // Set longitude based on the selected address
        },
      })
    } else {
      // Handle other fields as before
      if (name !== 'type') {
        const newValue = value.toUpperCase()
        setAddNewPickups({ ...addNewPickups, [name]: newValue })
      } else {
        setAddNewPickups({ ...addNewPickups, [name]: value })
      }
    }
  }

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split('T')[0]
    setAddNewPickups({ ...addNewPickups, date: formattedDate })
  }

  const handleFileUpload = (e, fileType) => {
    const file = e.target.files[0]
    setAddNewPickups({ ...addNewPickups, [fileType]: file })
  }

  const handleNewScrapSubmit = (e) => {
    e.preventDefault()

    if (!addNewPickups.date || !addNewPickups.dropLocation) {
      toast.error('Please fill out all required fields.')
    } else {
      const formData = new FormData()

      formData.append('generator', addNewPickups.generator)
      formData.append('processor', addNewPickups.processor)
      formData.append('scrap', addNewPickups.scrap)
      formData.append('quantity', addNewPickups.quantity)
      formData.append('price', addNewPickups.price)
      formData.append('date', addNewPickups.date)
      formData.append('image_0', addNewPickups.image_0)

      const pickupLocationObj = {
        latitude: addNewPickups.pickupLocation.latitude,
        longitude: addNewPickups.pickupLocation.longitude,
        address: addNewPickups.pickupLocation.address,
        nickname: addNewPickups.pickupLocation.nickname,
      }

      formData.append('pickupLocation', JSON.stringify(pickupLocationObj))

      formData.append(
        'dropLocation',
        JSON.stringify(addNewPickups.dropLocation),
      )

      formData.append('numberOfVehicles', addNewPickups.numberOfVehicles)

      formData.append('drivers', JSON.stringify(addNewPickups.drivers))
      formData.append('vehicles', JSON.stringify(addNewPickups.vehicles))

      formData.append('eWaybill', addNewPickups.eWaybill)
      formData.append('invoiceManifest', addNewPickups.invoiceManifest)

      for (const pair of formData.entries()) {
        console.log(pair[0], pair[1])
      }

      dispatch(AddNewPickupsAction(formData))
    }
  }
  const extractDropLocations = (selectedProcessorId) => {
    const foundProcessor = filterTraderList.find(
      (item) => item._id === selectedProcessorId,
    )
    if (foundProcessor) {
      const processorLocations = foundProcessor.locations || []
      const uniqueDropLocations = processorLocations.map(
        (location) => `${location.nickname} - ${location.address}`,
      )
      setDropLocations(uniqueDropLocations)
    }
  }

  const extractDriverNames = (selectedProcessorId) => {
    const foundProcessor = filterTraderList.find(
      (item) => item._id === selectedProcessorId,
    )
    if (foundProcessor) {
      const processorMembers = foundProcessor.members || []
      const uniqueDriverNames = processorMembers
        .filter((member) => member.role === 'driver')
        .map((driver) => driver.account.name)

      setDriverNames(uniqueDriverNames)
    }
  }

  const handleProcessorChange = (event) => {
    const selectedProcessorId = event.target.value
    setSelectedProcessor(selectedProcessorId)
    extractDropLocations(selectedProcessorId)
    extractDriverNames(selectedProcessorId)
  }

  useEffect(() => {
    // dispatch(getSingleOrgPickupsHistoryAction(id))
    dispatch(getSingleProcessorAction(id))
    if (selectedProcessor) {
      extractDriverNames(selectedProcessor)
    }
  }, [dispatch, id, pickupsHistory, selectedProcessor])

  return (
    <div>
      <form onSubmit={handleNewScrapSubmit}>
        <div>
          <div className="flex align-center gap-1">
            <p>Organization Name :</p>
            <p className="purple-text">{organization?.name}</p>
          </div>

          <div className="flex">
            <div style={{ width: isTablet ? '100%' : '30%' }} className="mr-1">
              <label>
                Pickup Location <span className="red-text">*</span>
              </label>
              <select
                name="pickupLocation"
                className="flex input-style align-center"
                onChange={handleChange}
              >
                <option value="">Select a Location</option>
                {organization?.locations
                  ?.filter((item) => item.nickname && item.address) // Filter out empty or undefined values
                  .reduce((uniqueLocations, item) => {
                    const combinedName = `${item.nickname} - ${item.address}`
                    if (!uniqueLocations.includes(combinedName)) {
                      uniqueLocations.push(combinedName)
                    }
                    return uniqueLocations
                  }, [])
                  .map((location, index) => (
                    <option key={index} value={location}>
                      {location}
                    </option>
                  ))}
              </select>
            </div>
            <div style={{ width: isTablet ? '100%' : '30%' }} className="mr-1">
              <label>
                Scarp Name<span className="red-text">*</span>
              </label>
              {
                <select
                  className="flex input-style align-center"
                  name="scrap"
                  onChange={handleChange}
                >
                  <option value="">Select a Scraps</option>
                  {organization?.scraps?.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item?.name} - {item?.variant}
                      </option>
                    )
                  })}
                </select>
              }
            </div>
            <div style={{ width: isTablet ? '100%' : '30%' }} className="mr-1">
              <label>
                Select Processor<span className="red-text">*</span>
              </label>
              <select
                name="processor"
                className="flex input-style align-center "
                onChange={handleProcessorChange}
              >
                <option value="">Select a Processor</option>
                {filterTraderList.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-1 flex align-center gap-1">
            <div style={{ width: isTablet ? '100%' : '30%' }}>
              <label>
                Select Date<span className="red-text">*</span>
              </label>
              <div className="flex  input-style align-center  custom-datepicker">
                <span className="material-symbols-outlined  grey-text">
                  calendar_month
                </span>
                <DatePicker
                  id="DatePicker-7"
                  placeholder="Choose A Date"
                  style={{ border: 'none', outline: 'none' }}
                  className="browser-default input-date full-width"
                  value={date}
                  onChange={(e) => {
                    handleDateChange(e)
                    setDate(addSeconds(e, 3600))
                    document.body.style.overflow = 'auto'
                  }}
                />
              </div>
            </div>

            <div style={{ width: isTablet ? '100%' : '30%' }} className="mr-1">
              <label>
                Select Drop Location<span className="red-text">*</span>
              </label>
              <select
                name="dropLocation"
                className="flex input-style align-center"
                onChange={handleChange}
              >
                <option value="">Select a Drop Location</option>

                {dropLocations.map((location, index) => (
                  <option key={index} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: isTablet ? '100%' : '30%' }}>
              <label>
                Enter Quantity<span className="red-text">*</span>
              </label>
              <div className="flex  input-style align-center  custom-datepicker">
                <input
                  type="number"
                  name="quantity"
                  value={addNewPickups?.quantity}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  id="quantity"
                  min={0}
                  placeholder="Enter the quantity"
                  className="browser-default input-date full-width"
                  style={{ border: 'none', outline: 'none' }}
                />
              </div>
            </div>
          </div>
          <div className="flex align-center gap-2 mt-1">
            <div style={{ width: isTablet ? '100%' : '30%' }}>
              <label>
                Enter Number of Vehicle<span className="red-text">*</span>
              </label>
              <div className="flex  input-style align-center  custom-datepicker">
                <input
                  type="number"
                  name="numberOfVehicles"
                  value={addNewPickups?.numberOfVehicles}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  id="numberOfVehicles"
                  min={0}
                  placeholder="Enter number of vehicle"
                  className="browser-default input-date full-width"
                  style={{ border: 'none', outline: 'none' }}
                />
              </div>
            </div>
            <div style={{ width: isTablet ? '100%' : '30%' }}>
              <p>eWaybill</p>
              <input
                type="file"
                name="eWaybill"
                onChange={(e) => handleFileUpload(e, 'eWaybill')}
              />
            </div>
            <div style={{ width: isTablet ? '100%' : '30%' }}>
              <p>invoiceManifest</p>
              <input
                type="file"
                name="invoiceManifest"
                onChange={(e) => handleFileUpload(e, 'invoiceManifest')}
              />
            </div>
          </div>
          <div></div>

          <div className="flex mt-1 gap-1 align-center">
            <div style={{ width: isTablet ? '100%' : '30%' }}>
              <label>
                Enter Price<span className="red-text">*</span>{' '}
              </label>
              <div className="flex  input-style align-center  custom-datepicker">
                <span
                  className="material-symbols-outlined  grey-text"
                  style={{ marginRight: '5px' }}
                >
                  money
                </span>
                <input
                  type="number"
                  name="price"
                  id="pricePerKg"
                  value={addNewPickups?.price}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  min={0}
                  placeholder="Enter Price per Kg"
                  className="browser-default input-date full-width"
                  style={{ border: 'none', outline: 'none' }}
                />
              </div>
            </div>

            <div style={{ width: isTablet ? '100%' : '30%' }} className="mr-1">
              <label>
                Select Driver<span className="red-text">*</span>
              </label>
              <select
                name="drivers"
                className="flex input-style align-center"
                value={addNewPickups?.drivers}
                onChange={handleChangeArray}
              >
                <option value="">Select a Driver</option>
                {driverNames.map((driver, index) => (
                  <option key={index} value={driver}>
                    {driver}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: isTablet ? '100%' : '30%' }} className="mr-1">
              <label>
                Select Vehicle<span className="red-text">*</span>
              </label>
              <select
                name="vehicles"
                className="flex input-style align-center"
                value={addNewPickups.vehicles}
                onChange={handleChangeArray}
              >
                <option>Select a vehicle</option>
                {availableVehicles.map((vehicle) => (
                  <option key={vehicle._id} value={vehicle._id}>
                    {vehicle.vehicleNumber}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="mt-1" style={{ width: isTablet ? '100%' : '30%' }}>
          <p>Scrap Image</p>
          <input
            type="file"
            name="image_0"
            onChange={(e) => handleFileUpload(e, 'image_0')}
          />
        </div>
        <div className="flex align-center justify-end full-width mt-1">
          <button className="btn">Complete Pickup</button>
        </div>
      </form>
    </div>
  )
}
