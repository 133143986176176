import React from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../redux/action/auth";

function Forbidden() {
  const dispatch = useDispatch();

  return (
    <div
      className=" flex align-center justify-center p-2"
      style={{ height: "100vh" }}
    >
      <div className="flex column align-center forbidden-card ">
        <div className="flex column align-center  p-1 ">
          <h1>ERROR 403</h1>
          <h4>Forbidden</h4>
        </div>
        <b className="mt-2">Access to this Dashboard</b>
        <b>on the server is denied!</b>
        <button
          className="btn red mt-5 "
          onClick={() => dispatch(logoutAction())}
        >
          CLOSE
        </button>
      </div>
    </div>
  );
}

export default Forbidden;
