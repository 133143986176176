import React from 'react'
import { Col, Row } from 'react-materialize'
import { AiOutlineMail } from 'react-icons/ai'
import Loading from '../../../layout/Loading'
import { useMediaQuery } from 'react-responsive'

export default function ContactDetails({ organizationDetails, loading }) {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
  const isLaptopV2 = useMediaQuery({ query: '(max-width: 1800px)' })

  if (loading) {
    return <Loading />
  }

  return (
    <div
      className={
        isLaptop ? 'flex column gap-1' : 'full-width flex column gap-1 '
      }
    >
      <div className='flex full-width'>
        <Row className={isLaptop ? 'flex column gap-1' : 'flex gap-1 '} style={{width:'50%'}}>
          <div
            className={isLaptop ? 'col s12 white' : 'white full-width'}
            style={{
              borderRadius: '50px',
              boxShadow: ' 0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
            }}
          >
            <div className="flex column white" style={{borderRadius:'12px'}}>
              <div className="flex align-cente space-between p-1">
                <div className="flec column">
                  <p className="grey-text">Organization Name</p>
                  <p className="bold">{organizationDetails.name}</p>
                </div>
                <div className="blue lighten-4 waves-circle flex align-center justify-center p-1">
                  <img src="../images/corporate.png" alt='corporate' />
                </div>
              </div>
            </div>
            {/* <b className="mt-1">{item.account.name}</b> */}
          </div>

          <Col
            className={isLaptop ? 'col s12 white' : ' white full-width'}
            style={{
              borderRadius: '12px',
              boxShadow: ' 0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
            }}
          >
            <div className="flex column white">
              <div className="flex align-cente space-between p-1">
                <div className="flec column">
                  <p className="grey-text">Organization Email</p>
                  <p className="bold">adminofficial@gmail.com</p>
                </div>
                <div className="green lighten-4 waves-circle flex align-center justify-center p-1">
                  <img src="../images/mail.png" alt='mail' />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={isLaptop ? 'flex column gap-1' : 'flex gap-1'} style={{width:'50%'}}>
          <Col
            className={isLaptop ? 'col s12 white' : 'white full-width'}
            style={{
              borderRadius: '12px',
              boxShadow: ' 0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
            }}
          >
            <div className="flex column">
              <div className="flex align-cente space-between p-1">
                <div className="flec column">
                  <p className="grey-text">Location</p>
                  <p className="bold">Coimbatore</p>
                </div>
                <div
                  className="orange lighten-4 waves-circle flex align-center justify-center p-1"
                  style={{ marginTop: '5px' }}
                >
                  <img src="../images/location.png" alt='location' />
                </div>
              </div>
            </div>
          </Col>

          <div
            className={isLaptop ? 'col s12 white' : 'white full-width'}
            style={{
              borderRadius: '12px',
              boxShadow: ' 0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
            }}
          >
            <div className="flex column ">
              <div className="flex align-cente space-between p-1">
                <div className="flec column">
                  <p className="grey-text">Role</p>
                  <p className="bold">{organizationDetails.type}</p>
                </div>
                <div
                  className="purple lighten-4 waves-circle flex align-center justify-center p-1"
                  style={{ marginTop: '5px' }}
                >
                  <img src="../images/article.png" alt='article'/>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <div className="flex flex-wrap gap-1 align-center">
        {' '}
        {organizationDetails.members?.map((item, index) => {
          return (
            <div
              className="white"
              key={index}
              style={{
                width: isLaptop ? '100%' : '35%',
                height: isLaptopV2 ? '22vh' : '16vh',
                borderRadius: '12px',
                boxShadow: ' 0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
              }}
            >
              <div
                className="flex align-center space-between"
                style={{ position: 'relative' }}
              >
                <div className="flex align-center gap-1 ml-1">
                  <div className="flex align-center center mt-1">
                    <img
                      src="../images/user.png"
                      alt="user"
                      style={{ width: '50px' }}
                    />
                  </div>
                  <b className="mt-1">{item.account.name}</b>
                </div>
                <div
                  className="blue lighten-4 blue-text"
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    borderRadius: '0 12px 0 12px',
                    padding: '4px 8px',
                  }}
                >
                  <p>{item.role}</p>
                </div>
              </div>
              <div
                className={
                  isTablet ? 'flex column gap-1 p-1' : 'flex gap-1 p-1'
                }
              >
                <div className="flex align-center  gap-1 input-style full-width browser-default">
                  <span className="material-symbols-outlined blue-text">
                    call
                  </span>
                  <p>{item.account.phoneNumber}</p>
                </div>
                <div
                  className="flex align-center gap-1 input-style full-width browser-default "
                  style={{ background: '#F3F6FD' }}
                >
                  <AiOutlineMail color="green" size={20} />
                  <p>{item.account.email}</p>
                </div>
              </div>
            </div>
          )
        })}
        
      </div>

      {/* <div className="col s4 "></div> */}
    </div>
  )
}
