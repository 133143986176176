import React, { useState } from 'react'
import 'react-responsive-modal/styles.css'
import { Modal, Button } from 'react-materialize'
import AddLocations from '../brandManagement/AddLocations'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import EditLocation from './EditLocation'
import { useDispatch } from 'react-redux'
import {
  addLocation,
  removeLocationAction,
} from '../../../../redux/action/organization'
import { toast } from 'react-toastify'
import { useMediaQuery } from 'react-responsive'

export default function ({ organizationDetails }) {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false)

  const [openEditLocationModal, setOpenEditLocationModal] = useState(false)

  const onOpenAddLocationModal = () => setOpenAddLocationModal(true)
  const onCloseAddLocationModal = () => setOpenAddLocationModal(false)

  const onOpenEditLocationModal = () => setOpenEditLocationModal(true)
  const onCloseEditLocationModal = () => {
    setOpenEditLocationModal(false)
  }

  const [searchQuery, setSearchQuery] = useState('')

  const filteredLocations = organizationDetails?.locations?.filter(
    (item) =>
      item.nickname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.address.toLowerCase().includes(searchQuery.toLowerCase()),
  )
  // Add Location API
  const dispatch = useDispatch()
  const organizationId = organizationDetails ? organizationDetails._id : ''

  const [values, setValues] = React.useState({
    location: {
      lng: '',
      lat: '',
      address: '',
      nickname: '',
    },
  })

  function addNewLocation() {
    dispatch(
      addLocation(organizationId, values.location, () => {
        const locationModal = document.getElementById('add-location-modal')
        const instance = window.M.Modal.getInstance(locationModal)
        instance.close()

        toast.success('Location Added successfully', {
          position: toast.POSITION.BOTTOM_LEFT,
          hideProgressBar: true,
          autoClose: 3000,
        })
        setValues({
          location: {
            nickname: '',
            address: '',
            lat: '',
            lng: '',
          },
        })
      }),
    )
  }

  function addEditLocation() {
    dispatch(
      addLocation(organizationId, values.location, () => {
        const locationModal = document.getElementById('add-location-modal')
        const instance = window.M.Modal.getInstance(locationModal)
        instance.close()
        toast.success('Location Added successfully', {
          position: toast.POSITION.BOTTOM_LEFT,
          hideProgressBar: true,
          autoClose: 3000,
        })
        setValues({
          location: {
            nickname: '',
            address: '',
            lat: '',
            lng: '',
          },
        })
      }),
    )
  }

  return (
    <div>
      <div>
        <div className={isMobile ? 'flex column' : 'flex align-center p-1'}>
          <div className={isTablet ? '' : 'full-width'}>
            <h6 className="bold-title mr-1">Locations</h6>
          </div>
          <div
            style={{ justifyContent: 'flex-end' }}
            className="flex align-center full-width"
          >
            <div
              className="input-field-style flex align-center small-text"
              style={{
                margin: 0,
                color: '#BFC3CE',
                width: isTablet ? '100%' : '40%',
                marginRight: '10px',
              }}
            >
              <span className="material-symbols-outlined mr-1">search</span>
              <input
                style={{ margin: 0, caretColor: 'black' }}
                placeholder="Search"
                className="hide-focus-underline"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="flex gap-1">
              <Modal
                actions={<></>}
                bottomSheet={false}
                fixedFooter={false}
                id="Modal-0"
                open={false}
                options={{
                  dismissible: true,
                  endingTop: '10%',
                  inDuration: 250,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  opacity: 0.5,
                  outDuration: 250,
                  preventScrolling: true,
                  startingTop: '4%',
                }}
                trigger={
                  <button className="btn" onClick={onOpenAddLocationModal}>
                    Add New Location
                  </button>
                }
              >
                <AddLocations
                  addLocation={addNewLocation}
                  values={values}
                  setValues={setValues}
                  onCloseAddLocationModal={onCloseAddLocationModal}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div className="locationtable  ">
        {organizationDetails &&
        organizationDetails.locations &&
        organizationDetails.locations.length > 0 ? (
          filteredLocations && filteredLocations.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>NICKNAME</th>
                  <th>ADDRESS</th>
                  <th>ACTION</th>
                </tr>
              </thead>

              <tbody>
                {filteredLocations.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="capitalize">{item.nickname}</td>
                      <td className="lowercase">{item.address}</td>

                      <td>
                        <div className="flex">
                          <Modal
                            actions={<></>}
                            bottomSheet={false}
                            fixedFooter={false}
                            id="Modal-0"
                            open={false}
                            options={{
                              dismissible: true,
                              endingTop: '10%',
                              inDuration: 250,
                              onCloseEnd: null,
                              onCloseStart: null,
                              onOpenEnd: null,
                              onOpenStart: null,
                              opacity: 0.5,
                              outDuration: 250,
                              preventScrolling: true,
                              startingTop: '4%',
                            }}
                            trigger={
                              <span
                                className="material-symbols-outlined purple-text pointer"
                                onClick={onOpenEditLocationModal}
                              >
                                edit
                              </span>
                            }
                          >
                            <EditLocation
                              onCloseEditLocationModal={
                                onCloseEditLocationModal
                              }
                            />
                          </Modal>

                          <Modal
                            actions={<></>}
                            bottomSheet={false}
                            fixedFooter={false}
                            id="Modal-0"
                            open={false}
                            trigger={
                              <span className="material-symbols-outlined red-text pointer delete-location">
                                delete
                              </span>
                            }
                          >
                            Do you want to delete the location?
                            <div className="flex justify-end align-center gap-1 full-width">
                              <button
                                className="btn-small"
                                onClick={() =>
                                  dispatch(
                                    removeLocationAction(
                                      organizationDetails._id,
                                      item._id,
                                    ),
                                  )
                                }
                              >
                                yes
                              </button>
                              <Button
                                className="btn-outline-small"
                                modal="close"
                              >
                                Close
                              </Button>
                            </div>
                          </Modal>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="flex column align-center p-1">
              <img src="../images/no-data.svg" width={100} alt="No data" />
              <p className="grey-text">Sorry, no matching data found</p>
            </div>
          )
        ) : (
          <div className="flex column align-center p-1">
            <img src="../images/no-data.svg" width={100} />
            <p className="grey-text">
              Sorry, currently you don’t have any data.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
