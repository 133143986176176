import React, { useState } from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

function EditLocation(props) {
  const [address, setAddress] = useState('')
  const [mapCenter, setMapCenter] = useState({
    longitude: 0,
    latitude: 0,
  })

  return (
    <div>
      <h6>Edit Location</h6>
      <div className="map-container" style={{ position: 'relative', width: '550px' }}>
        <Map
          google={props.google}
          zoom={mapCenter.latitude ? 15 : 1}
          center={{
            lat: mapCenter.latitude,
            lng: mapCenter.longitude,
          }}
          containerStyle={{
            position: 'relative',
            width: '100%',
            height: '300px',
            // boxShadow:
            //   '4px 4px 8px rgba(38, 50, 56, 0.60),-4px -4px 8px rgba(255,255,255,1)',
          }}
        >
          {mapCenter.latitude && mapCenter.longitude && (
            <Marker
              title={'Your location'}
              position={{
                lat: mapCenter.latitude,
                lng: mapCenter.longitude,
              }}
              draggable={true}
            />
          )}
        </Map>
        </div>
      <div>
        <label className="black-text mt-1" htmlFor="">
          Search Location
        </label>
        <div className="input-field-style">
          <input
            type="text"
            id="id"
            className="browser-default input-date full-width"
            style={{ border: 'none', outline: 'none' }}
          />
        </div>
      </div>
      <div className="mt-1">
        <label className="black-text" htmlFor="">
          Location Name
        </label>
        <div className="input-field-style">
          <input
            type="text"
            id="id"
            className="browser-default input-date full-width"
            style={{ border: 'none', outline: 'none' }}
            placeholder="Enter A Nickname of the location"
          />
        </div>
      </div>
      <div className="flex align-center mt-1">
        <span className="material-symbols-outlined cercle-purple-text">
          my_location
        </span>
        <div style={{ marginLeft: '0.5rem' }}>
          <b className="cercle-purple-text">Current Location</b>
          <p className="small-text grey-text">
            Enable your current location for better services
          </p>
        </div>
      </div>
      <div className="flex justify-end gap-1">
        <button className="btn-small mt-2 flex justify-end ">
          Save And Add Location
        </button>
      </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo',
})(EditLocation)




