export const getAllContractsReducer = (
  state = {
    loading: false,
    allContracts: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_ALL_CONTRACTS':
      return { ...state, loading: true }
    case 'GOT_ALL_CONTRACTS':
      return { ...state, loading: false, allContracts: action.payload }
    case 'CANNOT_GET_ALL_CONTRACTS':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getSingleOrgContractsReducer = (
  state = {
    loading: false,
    orgContracts: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_SINGLE_ORG_CONTRACTS':
      return { ...state, loading: true }
    case 'GOT_SINGLE_ORG_CONTRACTS':
      return { ...state, loading: false, orgContracts: action.payload }
    case 'CANNOT_GET_SINGLE_ORG_CONTRACTS':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getSingleContractReducer = (
  state = { loading: false, singleContract: {} },
  action,
) => {
  switch (action.type) {
    case 'GETTING_SINGLE_CONTRACT':
      return { ...state, loading: true }
    case 'GOT_SINGLE_CONTRACT':
      return { ...state, loading: false, singleContract: action.payload }
    case 'CANNOT_GET_SINGLE_CONTRACT':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getOrganizationContractReducer = (
  state = {
    loading: false,
    contracts: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_SINGLE_ORG_CONTRACTS':
      return { ...state, loading: true }
    case 'GOT_SINGLE_ORG_CONTRACTS':
      return { ...state, loading: false, contracts: action.payload }
    case 'CANNOT_GET_SINGLE_ORG_CONTRACTS':
      return { ...state, loading: false }
    default:
      return state
  }
}
