import { wasteknot } from '../config/urlConfig'

export const getReportAnalytics = () => async (dispatch) => {
  dispatch({ type: 'GETTING_ANALYTICS' })
  wasteknot
    .get('/analytics/read/admin/reports')
    .then((res) => {
      dispatch({ type: 'GOT_ANALYTICS', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_ANALYTICS' })
      console.log(error)
    })
}
