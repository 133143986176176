import React, { useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts'
import { useMediaQuery } from 'react-responsive'
const data = [
  { name: 'Subscription', value: 70 },
  { name: 'Pickup sales', value: 20 },
  { name: 'Service', value: 10 },
]

const COLORS = ['rgba(234, 67, 53, 1)', '#6F2DA8', '#1E1B39']

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props
  const { value, name } = payload

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x =
    cx + radius * Math.cos(-((startAngle + endAngle) / 2) * (Math.PI / 180))
  const y =
    cy + radius * Math.sin(-((startAngle + endAngle) / 2) * (Math.PI / 180))

  const total = data.reduce((acc, entry) => acc + entry.value, 0)
  const percentage = `${((value / total) * 100).toFixed(2)}%`

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <text x={x} y={y} dy={8} textAnchor="middle" fill="#fff" fontSize={14}>
        {`${value}%`}
      </text>
    </g>
  )
}

const RevenueSplitupChart = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const activeColor =
    activeIndex >= 0 ? COLORS[activeIndex % COLORS.length] : null
  const activeName = activeIndex >= 0 ? data[activeIndex].name : ''

  const btnStyle = {
    position: 'absolute',
    right: '10px',
    backgroundColor: activeColor,
    padding: '7px',
    borderRadius: '10px',
    color: '#fff',
    margin: '10px 0',
  }

  return (
    <div
      className="white p-1"
      style={{
        width: isMobile ? '100%' : '40%',
        height: '350px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <h5 style={{ textAlign: 'center' }}>% Revenue Split up</h5>
      <hr color="#E5E5EF" />
      <span className="p-1 on-change-btn" style={btnStyle}>
        {activeName}
      </span>
      <div
        style={{
          position: 'absolute',
          bottom: '80px',
          right: '40px',
          textAlign: 'right',
        }}
      >
        <div>
          <p style={{ color: 'grey', fontSize: '16px' }}>Revenue</p>
          <h5>₹21,098</h5>
        </div>
        <div>
          <p style={{ color: 'grey', fontSize: '16px' }}>No of pickup sold</p>
          <h5>20</h5>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ResponsiveContainer>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="30%"
              cy="50%"
              innerRadius={60}
              outerRadius={100}
              dataKey="value"
              onMouseEnter={onPieEnter}
              stroke="none"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ outline: 'none' }}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default RevenueSplitupChart
