import React from 'react'
import Analytics from './components/Analytics'
import SubscriptionChart from './components/SubscriptionChart'
import SubscriptionNotification from './components/SubscriptionNotification'
import SubscriptionList from './components/SubscriptionList'
import { useMediaQuery } from 'react-responsive'

export default function Subscriptions() {
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  return (
    <div>
      <b className="normal-size">Subscription</b>
      <div
        className={
          isLaptop ? 'flex column gap-1' : 'flex align-center gap-2 mt-1' 
        }
      >
        <Analytics />
        <SubscriptionChart />
        <SubscriptionNotification />
      </div>
      <div>
        <SubscriptionList />
      </div>
    </div>
  )
}
