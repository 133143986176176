import React, { useEffect } from "react";
import { useParams } from "react-router";
import ContactDetails from "./components/ContactDetails";
import NetworkList from "./components/NetworkList";
import { useDispatch, useSelector } from "react-redux";
import ContractsList from "./components/ContractsList";
import {
  getLocationChartAction,
  getNetworkOfAnOrganizationAction,
  getQuantityPerScrapAction,
  getSingleOrgHistoryActionV2,
  getSingleOrgSummaryAction,
  getSingleOrganizationAction
} from "../../redux/action/organization";
import PickupsHistory from "./components/PickupsHistory";
import PickupRaise from "./components/PickupRaise";
import BrandManagement from "./components/brandManagement/BrandManagement";
import SingleOrgAnalitics from "./components/singleOrganizationAnalytics/SingleOrgAnalitics";

export default function SingleOrganization() {
  const dispacth = useDispatch();
  const { id } = useParams();

  const { organization, loading } = useSelector(

    (state) => state.singleOrganization
  );

  const { contracts } = useSelector((state) => state.contracts);

  const { summary } = useSelector((state) => state.summary);

  const { pickupsHistoryV2 } = useSelector((state) => state.pickupsHistoryV2);


  useEffect(() => {
    dispacth(getSingleOrganizationAction(id));
    dispacth(getSingleOrgSummaryAction(id));
    dispacth(getQuantityPerScrapAction(id));
    dispacth(getLocationChartAction(id));
    dispacth(getNetworkOfAnOrganizationAction(id));
    dispacth(getSingleOrgHistoryActionV2(id));
  }, [dispacth, id]);

  return (
    <div className="flex column gap-2 ">
      <PickupRaise />
      <ContactDetails organizationDetails={organization} loading={loading} />
      <SingleOrgAnalitics summary={summary} />
      <BrandManagement organizationDetails={organization} loading={loading} />
      <PickupsHistory pickupsHistory={pickupsHistoryV2} loading={loading} />
      <ContractsList contracts={contracts} loading={loading} />
      <NetworkList />
    </div>
  );
}
