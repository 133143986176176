import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import { getAllScrapsAction } from '../../../../redux/action/organization'
import { wasteKnotURL } from '../../../../redux/config/urlConfig'
import { addScraps } from '../../../../redux/action/organization'

export default function AddScrap({ onCloseModal, organizationDetails }) {
  const [selectedScraps, setSelectedScraps] = useState([])
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const dispatch = useDispatch()
  const [search, setSearch] = React.useState('')
  const [selected, setSelected] = React.useState([])
  const { scraps } = useSelector((state) => state.scraps)
  const handleScrapSelection = (index) => {
    const alreadySelected = selectedScraps.includes(index)
    if (alreadySelected) {
      const filteredScraps = selectedScraps.filter((item) => item !== index)
      setSelectedScraps(filteredScraps)
    } else {
      setSelectedScraps([...selectedScraps, index])
    }
  }

  const filtered = scraps.filter(
    (scrap) =>
      scrap.name.toLowerCase().includes(search.toLowerCase()) ||
      scrap.variant.toLowerCase().includes(search.toLowerCase()),
  )

  useEffect(() => {
    if (organizationDetails.scraps)
      setSelected([...organizationDetails.scraps.map((s) => s._id)])
  }, [organizationDetails.scraps])

  useEffect(() => {
    dispatch(getAllScrapsAction());
  }, [dispatch]);

  

  function submit(e) {
    e.preventDefault();
    const organizationId = organizationDetails ? organizationDetails._id : '';
    dispatch(addScraps(organizationId, { scraps: selected }, () => {
      dispatch(getAllScrapsAction());
      window.location.reload();
    }));
  }
  

  return (
    <>
      <form onSubmit={submit}>
        <div className="full-height full-width">
          <div className="flex align-center">
            <div className="full-width flex space-between align-center">
              <div
                className={
                  isMobile ? 'p-1 flex column' : 'flex align-center gap-2'
                }
              >
                <h4 className="full-width">Add Scrap</h4>
                <div
                  className="input-style flex align-center"
                  style={{
                    borderRadius: '8px',
                    width: '100%',
                  }}
                >
                  <span className="material-symbols-outlined grey-text mr-1">
                    search
                  </span>
                  <input
                    className="browser-default "
                    type="text"
                    placeholder="Search for scraps..."
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ outline: 'none', border: 'none' }}
                    value={search}
                  />
                </div>
              </div>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: '8px',
                  background: '#E5E5EF',
                  textAlign: 'center',
                  padding: '6px 0',
                }}
              >
                <span
                  className="material-symbols-outlined"
                  onClick={onCloseModal}
                >
                  close
                </span>
              </div>
            </div>
          </div>
          {isTablet ? (
            ''
          ) : (
            <div className="flex align-center ">
              <hr color="#E5E5EF" className="mt-1 " style={{ width: '35%' }} />
              <p
                className="purple-text mt-1"
                style={{ padding: '0 10px', fontSize: '12px' }}
              >
                SELECT THE SCRAP TO ADD
              </p>
              <hr color="#E5E5EF" className="mt-1 " style={{ width: '35%' }} />
            </div>
          )}

          <div
            className={
              isTablet
                ? 'flex flex-wrap'
                : 'flex align-center flex-wrap gap-2 ml-4'
            }
            style={{ height: '50vh', overflowY: 'auto' }}
          >
            {filtered.length === 0 ? (
              <p className="grey-text text-center full-width">
                No scraps found
              </p>
            ) : (
              filtered.map((scrap, i) => {
                return (
                  <div
                    key={i}
                    style={{ width: '28%' }}
                    className={
                      selected.includes(scrap._id)
                        ? 'add-scrap selected  mt-1'
                        : 'add-scrap mt-1'
                    }
                    role="button"
                    onClick={() => {
                      if (selected.includes(scrap._id))
                        setSelected(selected.filter((s) => s !== scrap._id))
                      else setSelected([...selected, scrap._id])
                      handleScrapSelection(i)
                    }}
                  >
                    <div
                      className="p-1 select-border"
                      style={{
                        border: selectedScraps.includes(i)
                          ? '1px solid #6F2DA8'
                          : '1px solid #D0D5DD',
                        boxShadow: selectedScraps.includes(i)
                          ? 'box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04)'
                          : '',
                        textAlign: 'center',
                        borderRadius: '10px 10px 0 0',
                        position: 'relative',
                      }}
                    >
                      {selectedScraps.includes(i) && (
                        <div
                          className="white-text done-select"
                          style={{
                            borderRadius: '50%',
                            width: 20,
                            height: 20,
                            textAlign: 'center',
                            position: 'absolute',
                            right: 5,
                            background: 'rgba(111, 45, 168, 1)',
                          }}
                        >
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: '20px' }}
                          >
                            done
                          </span>
                        </div>
                      )}
                      <img
                        src={`${wasteKnotURL}/scraps/read/image/${scrap._id}`}
                        alt="scrap"
                        style={{ width: '80px', height: '80px' }}
                      />
                    </div>
                    <div
                      className="p-1"
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        background: selectedScraps.includes(i)
                          ? '#F3E8FF'
                          : '#F9FAFB',
                        borderRadius: '0 0 10px 10px',
                      }}
                    >
                      <p>{scrap.name}</p>
                      <p
                        className="grey-text scrap-label"
                        style={{
                          fontSize: '12px',
                          textOverflow: 'ellipsis',
                          width: '80%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {scrap.variant}
                      </p>
                    </div>
                  </div>
                )
              })
            )}
          </div>
        </div>
        <div className="bottom-add-scrap" style={{ textAlign: 'center' }}>
          <button
            className="btn"
            type="submit"
            onClick={onCloseModal}
            disabled={selectedScraps.length === 0}
          >
            Add Scrap
          </button>
        </div>
      </form>
    </>
  )
}
