import { wasteknot } from "../config/urlConfig";

export const getSingleProcessorAction = (id) => async (dispacth) => {
  dispacth({ type: "GETTING_PROCESSOR" });
  wasteknot
    .get(`/organization/read/admin/single/${id}`)
    .then((res) => {
      dispacth({ type: "GOT_PROCESSOR", payload: res.data });
    })
    .catch((error) => {
      dispacth({ type: "CANNOT_GET_PROCESSOR" });
    });
};

export const getSingleProcessorVehiclesAction = (id) => async (dispacth) => {
  dispacth({ type: "GETTING_PROCESSOR_VEHICLES" });
  wasteknot
    .get(`/vehicles/read/admin/${id}`)
    .then((res) => {
      console.log(res);
      dispacth({ type: "GOT_PROCESSOR_VEHICLES", payload: res.data });
    })
    .catch((error) => {
      console.log(error);

      dispacth({ type: "CANNOT_GET_PROCESSOR_VEHICLES" });
    });
};
