import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { updateQuantityAction } from "../../../redux/action/organization";
import { useParams } from "react-router";
import { getCurrentDateTime } from "../../../helpers/helper";
import { useNavigate } from "react-router";
import { deletePickuplAction } from "../../../redux/action/pickups";
import ReassignDriver from "./ReassignDriver";
import ReassignTrader from "./ReassignTrader";
import { Carousel } from "react-materialize";
import { wasteKnotURL } from "../../../redux/config/urlConfig";
import { Modal, Button } from "react-materialize";
import Loading from "../../../layout/Loading";
import M from "materialize-css";

function PickupsHistory({ pickupsHistory, loading }) {
  const dispacth = useDispatch();
  const modalRef = useRef(null);
  const navigate = useNavigate();
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [selectedPickup, setSelectedPickup] = useState(null);
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isLaptop = useMediaQuery({ query: "(max-width: 1600px)" });
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openTraderModal, setOpenTraderModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedQuantityValue, setEditedQuantityValue] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCloseModal = (itemId) => {
    var elem = document.getElementById(`modal-id-${itemId}`);
    var instance = M.Modal.getInstance(elem);
    instance.close();
  };

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const onCloseDelete = () => setOpenDelete(false);

  const onOpenTraderModal = () => setOpenTraderModal(true);
  const onCloseTraderModal = () => setOpenTraderModal(false);

  const [clickedOutside, setClickedOutside] = useState(false);

  useEffect(() => {
    if (clickedOutside) {
      onCloseDelete();
      setClickedOutside(false);
    }
  }, [clickedOutside, onCloseDelete]);

  const { id } = useParams();
  // const [editedQuantity, setEditedQuantity] = useState("");

  const handleDropdownToggle = (index) => {
    if (activeDropdownIndex === index) {
      setActiveDropdownIndex(null);
    } else {
      setActiveDropdownIndex(index);
    }
  };

  const getIconForIndex = (index) => {
    return activeDropdownIndex === index
      ? "expand_circle_up"
      : "expand_circle_down";
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPickups = pickupsHistory?.filter((item) => {
    const { price, date } = item;
    const normalizedQuery = searchQuery.toLowerCase().trim();
    const formattedPricer = price ? String(price) : "";
    const formattedDate = date ? String(date) : "";

    return (
      item.scrap.name.toLowerCase().includes(normalizedQuery) ||
      item.processor.name.toLowerCase().includes(normalizedQuery) ||
      item.scrap.name.toLowerCase().includes(normalizedQuery) ||
      item.scrap.variant.toLowerCase().includes(normalizedQuery) ||
      item.status.toLowerCase().includes(normalizedQuery) ||
      formattedPricer.toLowerCase().includes(normalizedQuery) ||
      formattedDate.toLowerCase().includes(normalizedQuery) ||
      false
    );
  });

  const handleDeleteConfirmation = async (itemId) => {
    await dispacth(deletePickuplAction(itemId));
    onCloseDelete();
    setIsDeleted(true);
  };

  // const handleEditClick = (item) => {
  //   setEditedQuantity(item.quantity);
  //   setSelectedPickup(item);
  //   setShowEditButton(false);
  //   setShowSaveChangesButton(true);
  //   setIsEditing(true);
  // };

  const handleSaveChangesClick = (e) => {
    e.preventDefault();
    // if (!editedQuantity) {
    //   return;
    // }

    dispacth(
      updateQuantityAction(
        selectedPickup,
        editedQuantityValue,
        id,
        handleCloseModal,
        setUpdateLoading
      )
    );

    // setEditedQuantity("");
    // setSelectedPickup(null);
    // setShowEditButton(true);
    // setShowSaveChangesButton(false);
  };

  useEffect(() => {
    // dispacth(getSingleOrgHistoryAction());
    // const intervalId = setInterval(() => {
    //   if (isDeleted) {
    //     dispacth(getSingleOrgHistoryAction());
    //     setIsDeleted(false);
    //   }
    // }, 10000);
    // return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div className="box full-width white p-1">
      <div className={isMobile ? "flex column" : "flex align-center p-1"}>
        <div className={isTablet ? "" : "full-width"}>
          <h6 className="bold-title mr-1">Pickup History</h6>
        </div>
        <div
          style={{ justifyContent: "flex-end" }}
          className="flex align-center full-width"
        >
          <div
            className="input-field-style flex align-center small-text"
            style={{
              margin: 0,
              color: "#BFC3CE",
              width: isTablet ? "100%" : "40%",
              marginRight: "10px",
            }}
          >
            <span className="material-symbols-outlined mr-1">search</span>
            <input
              style={{ margin: 0, caretColor: "black" }}
              placeholder="Search"
              className="hide-focus-underline"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="flex gap-1">
            <button
              className="btn"
              onClick={() =>
                window.open(
                  "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
                  "_blank"
                )
              }
            >
              Export
            </button>
          </div>
          <button
            className={
              isMobile ? "bold purple-text white " : "view-all-btn hover ml-1"
            }
            onClick={() => navigate(`/single-organization/${id}/all-pickups`)}
            disabled={pickupsHistory?.length === 0}
          >
            View All
          </button>
        </div>
      </div>
      <div
        className="accounttable"
        style={{ overflowX: isMobile ? "auto" : "" }}
      >
        <table className="striped ">
          {filteredPickups?.length > 0 ? (
            <thead>
              <tr>
                <th className="purple-text">Date</th>
                <th className="purple-text">Scrap</th>
                <th className="purple-text">Quantity</th>
                <th className="purple-text">Processor</th>
                <th className="purple-text">Price (INR)</th>
                <th className="purple-text">Status</th>
                <th className="purple-text">Action</th>
              </tr>
            </thead>
          ) : null}
          <tbody>
            {filteredPickups?.length > 0 ? (
              filteredPickups.map((item, index) => {
                if (index < 6) {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td className={isTablet && "small-text"}>
                          <div className="flex align-center gap-1">
                            <span className="flex  ml-1">
                              <span
                                className="material-symbols-outlined purple-text pointer assign-dropdown"
                                style={{ fontSize: "20px" }}
                                onClick={() => handleDropdownToggle(index)}
                              >
                                {getIconForIndex(index)}
                              </span>
                            </span>

                            <span>{getCurrentDateTime(item.date)}</span>
                          </div>
                        </td>
                        <td className={isTablet && "small-text"}>
                          {item.scrap.name}
                          {item.scrap.variant}
                        </td>
                        <td className={isTablet && "small-text"}>
                          {item.quantity ? item.quantity + "MT" : "---"}
                        </td>
                        <td className={isTablet && "small-text"}>
                          {item.processor.name}
                        </td>
                        <td className={isTablet && "small-text"}>
                          {item.price} ₹
                        </td>
                        <td className={isTablet && "small-text"}>
                          <p
                            className={
                              item.status === "requested"
                                ? "pending"
                                : item.status === "accepted"
                                ? "accepted"
                                : item.status === "completed"
                                ? "completed"
                                : item.status === "assigned"
                                ? "assigned"
                                : "active"
                            }
                          >
                            {item.status}
                          </p>
                        </td>

                        <td className={isTablet && "small-text"}>
                          <span
                            className="material-symbols-outlined purple-text pointer"
                            onClick={() =>
                              navigate(`/singlebrandpickupview/${item._id}`, {
                                state: { organizationId: item },
                              })
                            }
                          >
                            visibility
                          </span>
                          {item.status === "completed" && (
                            <Modal
                              className="modal-container"
                              id={`modal-id-${item._id}`}
                              trigger={
                                <span>
                                  <span
                                    className="material-symbols-outlined blue-text pointer"
                                    onClick={() => {
                                      setSelectedPickup(item._id);
                                      setEditedQuantityValue(item.quantity);
                                    }}
                                  >
                                    edit
                                  </span>
                                </span>
                              }
                              actions={<></>}
                            >
                              <div className="modal-content Edit-quantity ">
                                <form onSubmit={handleSaveChangesClick}>
                                  <div className="flex align-center gap-1 space-between full-width">
                                    <p>Edit Quantity: </p>
                                  </div>
                                  <div className="flex gap-1 mt-1">
                                    <div
                                      className="full-width flex column gap-1 "
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        className="input-field-style full-width edit-quantity "
                                        style={{ width: "49%" }}
                                      >
                                        <input
                                          value={editedQuantityValue}
                                          type="number"
                                          placeholder="Enter Quantity"
                                          onChange={(e) =>
                                            setEditedQuantityValue(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <span
                                        class="material-symbols-outlined"
                                        style={{
                                          position: "absolute",
                                          left: isLaptop ? 320 : 420,
                                          top: 1,
                                          color: "#6F2DA8",
                                          background: "#ECE4F4",
                                          borderRadius: "0 6px 6px 0",
                                          padding: "15px",
                                        }}
                                      >
                                        <p style={{ fontSize: "14px" }}>MT</p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="flex justify-end gap-2 mt-1">
                                    <button
                                      className="btn edit-button"
                                      disabled={
                                        !editedQuantityValue || updateLoading
                                      }
                                      type="submit"
                                    >
                                      {updateLoading ? "loading..." : " Submit"}
                                    </button>
                                    <Button
                                      className="btn-outline-small"
                                      modal="close"
                                    >
                                      Close
                                    </Button>
                                  </div>
                                </form>
                              </div>
                            </Modal>
                          )}
                          {item.status === "requested" && (
                            <Modal
                              className="modal-container-new"
                              id="add_new"
                              trigger={
                                <span className="material-symbols-outlined red-text pointer">
                                  delete
                                </span>
                              }
                              actions={<></>}
                            >
                              <div className="modal-content Edit-quantity ">
                                <b>Confirm Delete</b>
                                <div className="flex space-between">
                                  <p className="mt-1">
                                    Are you sure you want to want delete Pickups
                                    ?
                                  </p>
                                  <div className="flex gap-1">
                                    <Button
                                      className=" btn-small green"
                                      modal="close"
                                      onClick={() => {
                                        handleDeleteConfirmation(item._id); // Perform delete logic
                                        closeModal(); // Close the modal after deletion
                                      }}
                                    >
                                      YES
                                    </Button>
                                    <Button
                                      flat
                                      className="btn-small red white-text"
                                      node="button"
                                      onClick={closeModal}
                                      modal="close"
                                    >
                                      NO
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </td>
                      </tr>

                      {activeDropdownIndex === index && (
                        <tr className="full-width">
                          <td colSpan="7" style={{ border: "none" }}>
                            <div
                              className={
                                isMobile
                                  ? "flex column"
                                  : "reassign-pickup flex space-between p-1 align-center"
                              }
                              style={{ background: "#FBF5FF" }}
                            >
                              <div className="flex gap-2">
                                <div className="p-1 pickup-location">
                                  <p className="grey-text">
                                    PICKUP LOCATION - OFFICE{" "}
                                  </p>
                                  <p>{item.pickupLocation.address}</p>
                                </div>
                                {item.status === "requested" && (
                                  <div className="p-1 processor-name">
                                    <p className="grey-text">PROCESSOR</p>
                                    <p>{item.processor.name}</p>
                                  </div>
                                )}
                                {item.status === "accepted" && (
                                  <div className="p-1 processor-name">
                                    <p className="grey-text">PROCESSOR</p>
                                    <p>{item.processor.name}</p>
                                  </div>
                                )}
                                {item.status === "assigned" && (
                                  <>
                                    <div className="p-1 processor-name">
                                      <p className="grey-text">PROCESSOR</p>
                                      <p>{item.processor.name}</p>
                                    </div>
                                    {item?.vehicles.map((vehicles, index) => (
                                      <div
                                        key={index}
                                        className="p-1 driver-name"
                                      >
                                        <p className="grey-text">
                                          VEHICLE NUMBER
                                        </p>
                                        <p>{vehicles.vehicleNumber}</p>
                                      </div>
                                    ))}
                                    {item?.drivers.map((driver, index) => (
                                      <div
                                        key={index}
                                        className="p-1 driver-name"
                                      >
                                        <p className="grey-text">DRIVER</p>
                                        <p>{driver.name}</p>
                                      </div>
                                    ))}
                                  </>
                                )}
                                {item.status === "completed" && (
                                  <>
                                    <div className="p-1 processor-name">
                                      <p className="grey-text">PROCESSOR</p>
                                      <p>{item.processor.name}</p>
                                    </div>
                                    <div className="p-1 pickup-location">
                                      <p className="grey-text">
                                        Drop LOCATION - OFFICE{" "}
                                      </p>
                                      <p>{item.dropLocation.address}</p>
                                    </div>

                                    {item?.vehicles.map((vehicles, index) => (
                                      <div
                                        key={index}
                                        className="p-1 driver-name"
                                      >
                                        <p className="grey-text">
                                          VEHICLE NUMBER
                                        </p>
                                        <p>{vehicles.vehicleNumber}</p>
                                      </div>
                                    ))}
                                    {item?.drivers.map((driver, index) => (
                                      <div
                                        key={index}
                                        className="p-1 driver-name"
                                      >
                                        <p className="grey-text">DRIVER</p>
                                        <p>{driver.name}</p>
                                      </div>
                                    ))}
                                    <div className="p-1 quantity">
                                      <p className="grey-text">QUANTITY</p>
                                      <p>
                                        {item.quantity
                                          ? item.quantity + "MT"
                                          : "---"}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div
                                className="flex gap-1"
                                style={{ overflowY: "none" }}
                              >
                                {item.status === "completed" && (
                                  <Modal
                                    className="modal-container"
                                    id="add_new"
                                    trigger={
                                      <button
                                        className="btn green"
                                        style={{
                                          fontSize: isMobile ? "12px" : "",
                                        }}
                                      >
                                        View
                                      </button>
                                    }
                                  >
                                    {item.images.length ? (
                                      <Carousel
                                        carouselId="Carousel-36"
                                        className="custom-carousel"
                                        images={item.images.map((img, i) => {
                                          return `${wasteKnotURL}/pickups/read/image/${
                                            item._id
                                          }?count=${i + 1}`;
                                        })}
                                        options={{
                                          fullWidth: true,
                                          indicators: true,
                                        }}
                                      />
                                    ) : (
                                      <div className="center">
                                        <p>No Images uploaded</p>
                                      </div>
                                    )}
                                  </Modal>
                                )}

                                {["requested", "assigned", "accepted"].includes(
                                  item.status
                                ) && (
                                  <>
                                    <button
                                      className="btn-outline"
                                      onClick={onOpenModal}
                                      style={{
                                        fontSize: isMobile ? "12px" : "",
                                      }}
                                    >
                                      REASSIGN DRIVER
                                    </button>
                                    <button
                                      className="btn"
                                      onClick={onOpenTraderModal}
                                      style={{
                                        fontSize: isMobile ? "12px" : "",
                                      }}
                                    >
                                      REASSIGN TRADER
                                    </button>
                                  </>
                                )}

                                <Modal
                                  center
                                  styles={{
                                    modal: {
                                      borderRadius: "10px",
                                      padding: 0,
                                      width: isTablet ? "380px" : "800px",
                                      margin: 0,
                                    },
                                  }}
                                  open={open}
                                  onClose={onCloseModal}
                                  closeIcon={<></>}
                                >
                                  <>
                                    <div className="cercle-purple p-1 white-text flex align-center space-between">
                                      <h4>Reassign Driver</h4>
                                      <div
                                        onClick={onCloseModal}
                                        className="flex align-center justify-center"
                                        style={{
                                          width: 40,
                                          height: 40,
                                          background:
                                            "rgba(255, 255, 255, 0.50)",
                                          borderRadius: "6px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <span className="material-symbols-outlined">
                                          close
                                        </span>
                                      </div>
                                    </div>
                                    <ReassignDriver />
                                  </>
                                </Modal>

                                <Modal
                                  center
                                  styles={{
                                    modal: {
                                      borderRadius: "10px",
                                      padding: 0,
                                      width: isTablet ? "380px" : "800px",
                                      margin: 0,
                                    },
                                  }}
                                  open={openTraderModal}
                                  onClose={onCloseTraderModal}
                                  closeIcon={<></>}
                                >
                                  <>
                                    <div className="cercle-purple p-1 white-text flex align-center space-between">
                                      <h4>Reassign trader</h4>
                                      <div
                                        onClick={onCloseTraderModal}
                                        className="flex align-center justify-center"
                                        style={{
                                          width: 40,
                                          height: 40,
                                          background:
                                            "rgba(255, 255, 255, 0.50)",
                                          borderRadius: "6px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <span className="material-symbols-outlined">
                                          close
                                        </span>
                                      </div>
                                    </div>
                                    <ReassignTrader />
                                  </>
                                </Modal>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              })
            ) : (
              <tr>
                <td colSpan="7" style={{ border: "none", background: "#fff" }}>
                  <div
                    className="flex column justift-center align-center p-1 full-width"
                    // style={{ width: '100%' }}
                  >
                    <img
                      src="../images/no-data.svg"
                      width={100}
                      alt="No data"
                    />
                    <p
                      className="grey-text"
                      style={{ textTransform: "lowercase", fontSize: "15px" }}
                    >
                      Sorry, currently you don’t have any data
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PickupsHistory;
