import React, { useEffect, useState } from 'react'
import { Dropdown, Modal, Button } from 'react-materialize'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { UserProfileImage, Notification } from '../modules'
import { useDispatch, useSelector } from 'react-redux'
import { readAccountAction, logoutAction } from '../redux/action/auth'

function Header({ setOpenAside, openAside, userInfo }) {
  const logoutUser = () => {
    dispatch(logoutAction())
  }
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)

  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [count, setCount] = useState(0);
  const [seen, setSeen] = useState(true);
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    dispatch(readAccountAction())
  }, [dispatch])

  return (
    <header className="flex align-center space-between white full-width  header ph-1">
      <div className="flex align-center  ">
        <div
          className={openAside ? 'open-menu-icon' : 'menu-icon p-1'}
          onClick={() => setOpenAside(!openAside)}
        >
          {openAside ? (
            <span className="material-symbols-outlined menu-icon icon hover">
              close
            </span>
          ) : (
            <span className="material-symbols-outlined menu-icon icon hover ">
              segment
            </span>
          )}
        </div>
        <div className="company-profile flex mh-1  align-center ">
          <div className="flex align-center gap-1 ml-1">
            <img
              src="https://i.ibb.co/PTd3s9m/cerclex.png"
              style={{
                width: '45px',
                height: '45px',
              }}
              alt="company logo"
            />
            <b className="capitalize  truncate">
              Infinite Cercle Private Limited
            </b>
          </div>
        </div>
      </div>

      <div className="flex align-center">
        <div className=" flex align-center justify-center gap-1 full-width ">
          <Dropdown
            trigger={
              <div role="button" className="notification-icon ml-1 mr-1">
                {!seen && count > 0 && (
                  <div className="new-notification-indicator">
                    {count > 99 ? '99+' : count}
                  </div>
                )}

                <div
                  className="grey lighten-4 waves-circle flex align-center justify-center p-1"
                  style={{ marginTop: '5px' }}
                >
                  <span className="material-symbols-outlined grey-text ">
                    notifications
                  </span>
                </div>
              </div>
            }
            className="header-notification"
          >
            <Notification />
          </Dropdown>
        </div>

        {!isMobile && (
          <Dropdown
            id="Dropdown_8"
            options={{
              alignment: 'right',
            }}
            trigger={
              <div className="user-profile flex align-center gap-2">
                <UserProfileImage userId={account._id} />
                {!isTablet ? (
                  <div>
                    <p className="semi-bold  capitalize">{account.name}</p>
                    <p style={{ fontSize: 13, color: '#666' }}>
                      {account.email}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            }
            className="user-profile-drop-down "
          >
            <Link to="/settings">
              <div className="flex ml-1 gap-1 ">
                <span className="material-symbols-outlined purple-text">
                  manage_accounts
                </span>
                <p className="semi-bold purple-text ">Account Setting</p>
              </div>
            </Link>
            <span>
              <div
                className="flex ml-1 gap-1"
                href="#modal1"
                node="button"
                onClick={() => setLogout(!logout)}
              >
                <span className="material-symbols-outlined purple-text">
                  logout
                </span>
                <p className="semi-bold purple-text ">Logout </p>
              </div>
              <Modal
                actions={[
                  <div className="flex justify-end gap-1">
                    <button className="green btn-small " onClick={logoutUser}>
                      Yes
                    </button>

                    <button
                      className="red btn-small"
                      onClick={() => setLogout(false)}
                    >
                      No
                    </button>
                  </div>,
                ]}
                id="modal1"
                open={logout}
                options={{
                  onCloseEnd: () => setLogout(false),
                }}
              >
                <b className="normal-size">Confirm Logout </b>
                <p className="semi-bold">Are you sure you want to logout?</p>
              </Modal>
            </span>
          </Dropdown>
        )}
      </div>
    </header>
  )
}

export default Header
