import React, { useEffect } from 'react'
import { Tabs, Tab } from 'react-materialize'
import Users from './User'
import Scrap from './Scarp'
import Locations from './Locations'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { getSingleOrganizationAction } from '../../../../redux/action/organization'

export default function BrandManagement({ organizationDetails, loading }) {
  const dispacth = useDispatch()
  const { id } = useParams()
  useEffect(() => {
    dispacth(getSingleOrganizationAction(id))
  }, [dispacth, id])
  return (
    <div className="white">
      <Tabs>
        <Tab
          options={{ swipeable: false }}
          title="User Accounts Management"
          idx="accounts"
        >
          <Users organizationDetails={organizationDetails} loading={loading} />
        </Tab>
        <Tab
          options={{ swipeable: false }}
          title="Locations Management"
          idx="locations"
         
        >
          <Locations organizationDetails={organizationDetails} />
        </Tab>
        <Tab
          options={{ swipeable: false }}
          title="Scraps Management"
          idx="scraps"
        >
          <Scrap organizationDetails={organizationDetails} />
        </Tab>
      </Tabs>
    </div>
  )
}
