import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useMediaQuery } from 'react-responsive'
import { loginUser, requestOtpAction } from '../../redux/action/auth'

function Login() {
  const isTablet = useMediaQuery({ query: '(max-width:790px)' })
  const { showLoading } = useSelector((state) => state.loading)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [userNameError, setUserNameError] = useState('')
  const [password, setPassword] = useState('')
  const [userName, setUserName] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!userName) {
      setUserNameError('Please enter your email address')
    } else if (!password) {
      setPasswordError('Please enter your password')
    } else {
      dispatch(loginUser(userName, password, navigate))
    }
  }

  const requestOtp = (e) => {
    e.preventDefault()
    if (userName.length < 12) {
      setUserNameError(
        'Please enter your phone number with country code to receive an OTP for login.',
      )
    } else {
      dispatch(requestOtpAction(userName, navigate))
    }
  }

  return (
    <div className="auth-page" style={{ position: 'relative' }}>
      <div>
        <image src="../images/logo.png" />
      </div>
      <div className="flex center full-width " style={{ height: '100vh' }}>
        <div className=" flex justify-center align-center  full-width  ">
          <form
            onSubmit={handleSubmit}
            className="flex column white p-2"
            style={{ width: !isTablet ? '30vw' : '96%' }}
          >
            <h2 className="large-size">Wasteknot Admin</h2>
            <b>
              <span className="purple-text large-size ">Welcome! </span>
              Let's Sign In
            </b>
            <b>Enter your account information below</b>

            <div className="flex column align-start mt-2 ">
              <div className="input-field-style full-width mb-1 ">
                <span className="material-symbols-outlined primary">mail</span>
                <input
                  placeholder="Enter your email or phone Number"
                  type="string"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value)
                    setUserNameError('')
                  }}
                />
              </div>
              {userNameError && (
                <p className="small-text red-text mb-1 ">{userNameError}</p>
              )}

              <div className="input-field-style full-width mt-1  ">
                <span className="material-symbols-outlined primary ">lock</span>
                <input
                  className="browser-default input-field  "
                  placeholder="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setPasswordError('')
                  }}
                />
                <div
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                  style={{ width: '25px' }}
                >
                  {showPassword ? (
                    <AiFillEye className="eye-icon" />
                  ) : (
                    <AiFillEyeInvisible className="eye-icon" />
                  )}
                </div>
              </div>
              {passwordError && (
                <p className="small-text red-text mb-1">{passwordError}</p>
              )}
              <div className="submit-btn column flex align-center full-width mt-1 ">
                <button
                  className="btn mb-1 full-width"
                  type="submit"
                  disabled={showLoading}
                >
                  Login
                </button>
                <b className="grey-text">OR</b>
                <button
                  className="btn-outline full-width mt-1"
                  onClick={requestOtp}
                >
                  {!showLoading ? ' Request OTP' : 'loading...'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
