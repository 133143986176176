import React from "react";
import loading from "../lotti/loading.json";
import ReactLoading from "react-loading";

function Loading() {
  const options = {
    animationData: loading,
    loop: true
  };

  return (
    <div
      className="box flex align-center justify-center p-2 column white"
      style={{ height: "300px" }}
    >
      <ReactLoading type="spinningBubbles" color="#6f2da8" />
      <p className="bold cercle-purple-text mt-1 ">loading...</p>
    </div>
  );
}
export default Loading;
