import React, { useEffect } from 'react'
import { Tabs, Tab } from 'react-materialize'
import Drivers from './components/Drivers'
import Vehicles from './components/Vehicles'
import { useDispatch } from 'react-redux'
import { getOrganizationAction } from '../../redux/action/auth'

function MemberVehicleListings() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrganizationAction())
  }, [dispatch])
  return (
    <div>
      <div className="mb-1">
        <b className="normal-size">Member & Vehicle Listings</b>
      </div>
      <Tabs className="mt-2 white ">
        <Tab title="Drivers">
          <Drivers />
        </Tab>
        <Tab title="Vehicles">
          <Vehicles />
        </Tab>
      </Tabs>
    </div>
  )
}

export default MemberVehicleListings
