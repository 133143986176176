import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "react-materialize";
import {
  getNetworkOfAnOrganizationAction,
  getSingleOrganizationAction
} from "../../../redux/action/organization";
import { getBrandPickupsAction } from "../../../redux/action/pickups";
import { toast } from "react-toastify";
import addSeconds from "date-fns/addSeconds";
import isToday from "date-fns/isToday";

function RaisePickups({
  scrapContract,
  onSelect,
  selectedProcessor,
  formData,
  setFormData
}) {
  const dispacth = useDispatch();

  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const [dateError, setDateError] = useState(false);
  const [numberOfVehicles, setNumberOfVehicles] = useState(1);
  const [date, setDate] = useState("");
  const { organization } = useSelector((state) => state.singleOrganization);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!formData.processor) {
      toast.error("Please select a processor.");
      return;
    }
    dispacth(getBrandPickupsAction(formData));
  };

  const handleDateChange = (value) => {
    isToday(value)
      ? setFormData((prevData) => {
          return {
            ...prevData,
            date: addSeconds(new Date(new Date().getTime() + 10 * 60000), 3600)
          };
        })
      : setFormData((prevData) => {
          return {
            ...prevData,
            date: addSeconds(value, 3600)
          };
        });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
        numberOfVehicles: numberOfVehicles
      };
    });
  };

  const handleSelect = (e) => {
    onSelect(e.target.value);
  };

  const { id } = useParams();

  useEffect(() => {
    dispacth(getSingleOrganizationAction(id));
    dispacth(getNetworkOfAnOrganizationAction(id));
  }, [dispacth, id]);

  return (
    <div>
      <p>
        <span className="purple-text">{organization?.name}</span>
      </p>
      <div className=" mt-1 raise-pickups ">
        <form onSubmit={handleFormSubmit} className="raise-pickups-section">
          <div className="flex space-between">
            <p className="bold">Raise A Pickup Request for Brands</p>
            <p>
              To:{" "}
              <span className="cercle-purple-text">{selectedProcessor}</span>
            </p>
          </div>

          <div className={!isTablet ? "flex s_b " : "flex  column "}>
            <div className="mt-1 flex full-width flex-wrap gap-1">
              <div
                style={{ width: isTablet ? "100%" : "30%" }}
                className="mr-1 "
              >
                <select
                  name="pickupLocation"
                  className="flex input-style align-center"
                  value={formData.location}
                  onChange={(e) => {
                    setFormData({ ...formData, location: e.target.value });
                    {
                      handleSelect(e);
                    }
                  }}
                >
                  <option value="">Select a Location</option>
                  {organization?.locations
                    ?.reduce((uniqueLocations, item) => {
                      const combinedName = `${item?.nickname} - ${item?.address}`;
                      if (!uniqueLocations.includes(combinedName)) {
                        uniqueLocations.push(combinedName);
                        return uniqueLocations;
                      }
                      return uniqueLocations;
                    }, [])
                    .map((location, index) => (
                      <option key={index} value={location}>
                        {location}
                      </option>
                    ))}
                </select>
              </div>

              <div
                style={{ width: isTablet ? "100%" : "30%" }}
                className=" mr-1"
              >
                {
                  <select
                    className="flex input-style align-center"
                    name="scrap"
                    value={formData.scrap}
                    onChange={handleChange}
                  >
                    <option value="">Select a Scraps</option>
                    {organization?.scraps?.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item?.name}-{item?.variant}
                        </option>
                      );
                    })}
                  </select>
                }
              </div>

              <div style={{ width: isTablet ? "100%" : "30%" }}>
                <div
                  className="flex  input-style align-center  custom-datepicker"
                  style={{ borderColor: "#6f2da8" }}
                >
                  <span className="material-symbols-outlined  grey-text">
                    calendar_month
                  </span>
                  <DatePicker
                    placeholder="Choose A Date"
                    className="browser-default input-date"
                    options={{
                      minDate: new Date(new Date().getTime() + 10 * 60000)
                    }}
                    value={formData.date ? formData.date?.toDateString() : ""}
                    onChange={(e) => {
                      handleDateChange(e);
                      // setDate(addSeconds(e, 3600));
                      document.body.style.overflow = "auto";
                    }}
                  />
                </div>
              </div>

              {!scrapContract && (
                <div style={{ width: isTablet ? "100%" : "30%" }}>
                  <div className="flex  input-style align-center  custom-datepicker">
                    <span
                      className="material-symbols-outlined  grey-text"
                      style={{ marginRight: "5px" }}
                    >
                      money
                    </span>
                    <input
                      type="number"
                      name="price"
                      id="pricePerKg"
                      value={formData.price}
                      onChange={handleChange}
                      min={0}
                      placeholder="Enter Price per Kg"
                      className="browser-default input-date full-width"
                      style={{ border: "none", outline: "none" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={
              !isTablet
                ? "flex mt-2 align-end space-between "
                : " flex gap-1 align-center column mt-2 "
            }
          >
            <div className={isTablet && "flex column align-center"}>
              <p className="semi-bold">Number Of Vehicles</p>
              <div className="flex align-center mt-1 gap-1  ">
                <button
                  className="countrol-btn"
                  type="button"
                  onClick={() =>
                    numberOfVehicles - 1 >= 1
                      ? setNumberOfVehicles(numberOfVehicles - 1)
                      : null
                  }
                  handleChange
                >
                  <span
                    className="material-symbols-outlined icon "
                    type="button"
                  >
                    remove
                  </span>
                </button>
                <p
                  className="bold input ml-1 mr-1"
                  style={{ justifyContent: "center" }}
                >
                  {numberOfVehicles}
                </p>
                <div
                  onClick={() => setNumberOfVehicles(numberOfVehicles + 1)}
                  className="countrol-btn"
                >
                  <span className="material-symbols-outlined icon">add</span>
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="btn"
                disabled={
                  !formData.location ||
                  !formData.scrap ||
                  !formData.date ||
                  !formData.price
                }
              >
                Raise Pickups
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RaisePickups;
