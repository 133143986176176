import React, { useState } from 'react'
import { Dropdown } from 'react-materialize'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  { name: 'Jan', uv: 4000, pv: 2400, amt: 400 },
  { name: 'Feb', uv: 3000, pv: 1398, amt: 2010 },
  { name: 'Mar', uv: 9000, pv: 9800, amt: 3090 },
  { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'May', uv: 1890, pv: 4800, amt: 6081 },
]

function SubscriptionChart() {
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const [number, setNumber] = useState(3)

  return (
    <div
      className="quantity-channelized-chart p-1 box white full-width"
      style={{ borderRadius: '12px' }}
    >
      <div className={isMobile ? ' mb-1':"flex space-between align-center mb-1"}>
        <h5>Subscription MRR </h5>
        <div className={isTablet ? '':"flex align-center gap-1"}>
          <div className="flex align-center gap-1">
            <span
              style={{
                width: '10px',
                height: '5px',
                borderRadius: '50%',
                background: '#6F2DA8',
                padding: '5px',
              }}
            ></span>
            <p>Subscription</p>
          </div>
          <div className="flex align-center gap-1">
            <span
              style={{
                width: '10px',
                height: '5px',
                borderRadius: '50%',
                background: '#FF9500',
                padding: '5px',
              }}
            ></span>
            <p>Subscription revenue</p>
          </div>
        </div>
        <div
          className={isMobile ? '':"flex align-center"}
          style={{
            background: '#fff',
            borderRadius: '8px',
            padding: '5px',
            boxShadow: '0px 2px 10px 0px rgba(124, 141, 181, 0.12)',
          }}
        >
          <Dropdown
            id="monthsDropdown"
            trigger={
              <div className="flex align-center">
                <p
                  className="cercle-purple-text"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Last {number} Months
                </p>
                <span className="material-symbols-outlined cercle-purple-text">
                  arrow_drop_down
                </span>
              </div>
            }
          >
            <span onClick={() => setNumber(3)}>3 Months</span>
            <span onClick={() => setNumber(6)}>6 Months</span>
            <span onClick={() => setNumber(12)}>1 Year</span>
            <span onClick={() => setNumber(24)}>2 Years</span>
          </Dropdown>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={280}>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar radius={10} dataKey="uv" fill="url(#colorUv)" barSize={32.5} />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#FF9500"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="amt"
            stroke="#6F2DA8"
            strokeWidth={2}
            dot={false}
          />
            <defs>
          <linearGradient id="colorUv" x1="14" y1="181" x2="14" y2="6.57961e-08" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DEB8FF"/>
          <stop offset="1" stopColor="#E6EDFF" stopOpacity="0"/>
          </linearGradient>
          </defs>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default SubscriptionChart
