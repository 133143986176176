import React, { useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { useMediaQuery } from 'react-responsive'
import { Dropdown } from 'react-materialize'

const data = [
  {
    name: '1 Oct',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '7 Oct',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '15 Oct',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: '25 Oct',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: '27 Oct',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '30 Oct',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '31 Oct',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
]
function PaymentReceivedChart() {
  const [number, setNumber] = useState(3)
  const isMobile = useMediaQuery({ query: '(max-width:768px)' })

  return (
    <div
      className="quantity-channelized-chart p-1 box white full-width"
      style={{ borderRadius: '12px' }}
    >
      <div className="flex space-between align-center mb-1">
        <h5>Payment received</h5>
        <div className="flex align-center gap-1"></div>
        <div
          className="flex align-center"
          style={{
            background: '#fff',
            borderRadius: '8px',
            padding: '5px',
            boxShadow: '0px 2px 10px 0px rgba(124, 141, 181, 0.12)',
          }}
        >
          <Dropdown
            id="monthsDropdown"
            trigger={
              <div className="flex align-center">
                <p
                  className="cercle-purple-text"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Last {number} Months
                </p>
                <span className="material-symbols-outlined cercle-purple-text">
                  arrow_drop_down
                </span>
              </div>
            }
          >
            <span onClick={() => setNumber(3)}>3 Months</span>
            <span onClick={() => setNumber(6)}>6 Months</span>
            <span onClick={() => setNumber(12)}>1 Year</span>
            <span onClick={() => setNumber(24)}>2 Years</span>
          </Dropdown>
        </div>
      </div>
      <div className="flex gap-2 mb-1">
        <div className="flex align-center gap-1">
          <span
            style={{
              width: '10px',
              height: '5px',
              borderRadius: '50%',
              background: '#4A3AFF',
              padding: '5px',
            }}
          ></span>
          <p>Wasteknot Plan</p>
        </div>
        <div className="flex align-center gap-1">
          <span
            style={{
              width: '10px',
              height: '5px',
              borderRadius: '50%',
              background: '#C893FD',
              padding: '5px',
            }}
          ></span>
          <p>Pickup Sales</p>
        </div>
      </div>
      <ResponsiveContainer width={isMobile ? '100%' : '100%'} height={250}>
        <LineChart
          layout="horizontal"
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: isMobile ? 10 : 30,
            left: isMobile ? 0 : 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" type="category" />
          <YAxis type="number" domain={[0, 'dataMax + 1000']} />
          <Tooltip />

          <Line dataKey="pv" stroke="#4A3AFF" dot={{ strokeWidth: 1, r: 5 }} />
          <Line dataKey="uv" stroke="#C893FD" dot={{ strokeWidth: 1, r: 5 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PaymentReceivedChart
