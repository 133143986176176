import React from 'react'

export default function EditContract() {
  return (
    <div className="p-2 full-width">
      <div className="flex align-center gap-1 full-width ">
        <div className='full-width'>
          <b>Waste Generator<span className='red-text'>*</span></b>
          <div className="input-field-style">
            <input
              type="text"
              id="id"
              className="browser-default input-date"
              style={{ border: 'none', outline: 'none' }}
            />
          </div>
        </div>
        <div className='full-width'>
          <b>Waste Processor<span className='red-text'>*</span></b>
          <div className="input-field-style">
            <input
              type="text"
              id="id"
              className="browser-default input-date full-width"
              style={{ border: 'none', outline: 'none' }}
            />
          </div>
        </div>
      </div>

      <div className="flex align-center gap-1 mt-1">
        <div className='full-width'>
          <b>Start Date<span className='red-text'>*</span></b>
          <div className="input-field-style">
            <input
              type="text"
              id="id"
              className="browser-default input-date full-width"
              style={{ border: 'none', outline: 'none' }}
            />
          </div>
        </div>
        <div className='full-width'>
          <b>End Date<span className='red-text'>*</span></b>
          <div className="input-field-style">
            <input
              type="text"
              id="id"
              className="browser-default input-date full-width"
              style={{ border: 'none', outline: 'none' }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-1">
        <button className="btn">Save and submit</button>
      </div>
    </div>
  )
}
