import React, { useState } from 'react'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import AddScrap from './AddScrap'
import DeleteScrap from './DeleteScrap'
import { wasteKnotURL } from '../../../../redux/config/urlConfig'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import { deletePickuplAction } from '../../../../redux/action/pickups'
import { removeScrapAction } from '../../../../redux/action/organization'
import { Button } from 'react-materialize'

export default function Scrap({ organizationDetails }) {
  const [searchQuery, setSearchQuery] = useState('')
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [open, setOpen] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const dispacth = useDispatch()
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const onOpenDeleteModal = () => setOpenDeleteModal(true)
  const onCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleSearch = (event) => {
    setSearchQuery(event.target.value)
  }

  const filteredScraps = organizationDetails.scraps?.filter((item) => {
    const searchTerm = searchQuery.toLowerCase()
    const itemName = `${item?.name} ${item?.variant}`.toLowerCase()
    return itemName.includes(searchTerm)
  })
  const modalCustomStyles = {
    modal: {
      maxWidth: '800px',
      width: isMobile ? '90%' : '60%',
      bottom: isMobile ? '' : '10%',
    },
  }

  const modalDeleteCustomStyles = {
    modal: {
      maxWidth: '600px',
      width: '60%',
    },
    overlay: {
      background: 'rgba(38, 50, 56, 0.20)',
    },
  }

  return (
    <div>
      <div>
        {/* <div
          className={
            isMobile
              ? 'flex column'
              : 'flex align-center full-width space-between'
          }
        >
          <div>
            <h6 className="bold-title">Scraps</h6>
          </div>
          <div className="flex align-center gap-1">
            <div
              className="input-style flex align-center small-text"
              style={{
                margin: 0,
                color: '#BFC3CE',
                background: '#FAFAFA',
                width: '100%',
                marginRight: '10px',
              }}
            >
              <span className="material-symbols-outlined mr-1">search</span>
              <input
          style={{ margin: 0, caretColor: '#000000' }}
          placeholder="Search"
          className="hide-focus-underline"
          value={searchQuery}
          onChange={handleSearch}
        />
            </div>

            <button
              className="btn full-width"
              style={{ margin: 0, width: isTablet ? '79%' : '55%' }}
              onClick={onOpenModal}
            >
              Add Scrap
            </button>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames="invite-user"
              styles={modalCustomStyles}
              showCloseIcon={false}
            >
              <AddScrap
                onCloseModal={onCloseModal}
                organizationDetails={organizationDetails}
              />
            </Modal>
          </div>
        </div> */}

        <div className={isMobile ? 'flex column' : 'flex align-center p-1'}>
          <div className={isTablet ? '' : 'full-width'}>
            <h6 className="bold-title mr-1">Scraps</h6>
          </div>
          <div
            style={{ justifyContent: 'flex-end' }}
            className="flex align-center full-width"
          >
            <div
              className="input-field-style flex align-center small-text"
              style={{
                margin: 0,
                color: '#BFC3CE',
                width: isTablet ? '100%' : '40%',
                marginRight: '10px',
              }}
            >
              <span className="material-symbols-outlined mr-1">search</span>
              <input
                style={{ margin: 0, caretColor: 'black' }}
                placeholder="Search"
                className="hide-focus-underline"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div className="flex gap-1">
              <button className="btn" onClick={onOpenModal}>
                Add Scrap
              </button>
              <Modal
                open={open}
                onClose={onCloseModal}
                center
                classNames="invite-user"
                styles={modalCustomStyles}
                showCloseIcon={false}
              >
                <AddScrap
                  onCloseModal={onCloseModal}
                  organizationDetails={organizationDetails}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div className="locationtable  ">
        {organizationDetails &&
        organizationDetails.scraps &&
        organizationDetails.scraps.length > 0 ? (
          filteredScraps.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>IMAGE</th>
                  <th>NAME</th>
                  <th>ACTION</th>
                </tr>
              </thead>

              <tbody>
                {filteredScraps && filteredScraps.length > 0 ? (
                  filteredScraps.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="lowercase">
                          <img
                            src={`${wasteKnotURL}/scraps/read/image/${item?._id}`}
                            alt="scrap"
                            style={{
                              width: '50px',
                              height: '50px',
                              borderRadius: '50%',
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td className="capitalize">
                          {item?.name} - {item?.variant}
                        </td>

                        <td>
                           <span
                            className="material-symbols-outlined red-text pointer"
                            onClick={() => {
                              dispacth(removeScrapAction(item?._id))
                              onOpenDeleteModal()
                            }}
                          >
                            delete
                          </span> 
                         
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <div className="flex column align-center p-1">
                    <img
                      src="../images/no-data.svg"
                      width={100}
                      alt="No data"
                    />
                    <p className="grey-text">Sorry, no matching data found</p>
                  </div>
                )}
              </tbody>
            </table>
          ) : (
            <div className="flex column align-center p-1">
              <img src="../images/no-data.svg" width={100} alt="No data" />
              <p className="grey-text">Sorry, no matching data found</p>
            </div>
          )
        ) : (
          <div className="flex column align-center p-1">
            <img src="../images/no-data.svg" alt="no-data" width={100} />
            <p className="grey-text">
              Sorry, currently you don’t have any data.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
