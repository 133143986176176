import React, { useEffect } from 'react'
import LocationChart from './components/LocationChart'
import BrandsChart from './components/BrandsChart'
import QuantityChart from './components/Quantitychart'
import ChannelizeWasteChart from './components/ChannelizeWasteChart'
import PickupHistory from './components/PickupHistory'
import { useMediaQuery } from 'react-responsive'
import AnalyticsCard from '../../modules/AnalyticsCard'
import { useDispatch, useSelector } from 'react-redux'
import { getReportAnalytics } from '../../redux/action/report'
import { getSingleOrgHistoryAction } from '../../redux/action/organization'

const cardData = [
  {
    icon: 'local_shipping',
    bgColor: '#FAF4FF',
    iconColor: 'rgba(111, 45, 168, 1)',
  },
  { icon: 'group', bgColor: '#D6EAF8', iconColor: 'rgba(52, 122, 226, 1)' },
  {
    icon: 'local_shipping',
    bgColor: 'rgba(255, 176, 58, 0.20)',
    iconColor: 'rgba(255, 136, 27, 1)',
  },
  { icon: 'forklift', bgColor: '#F0FFF6', iconColor: 'rgba(39, 174, 96, 1)' },
]

export default function Report() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const dispacth = useDispatch()

  const { reportAnalytics } = useSelector((state) => state.reportAnalytics)

  useEffect(() => {
    dispacth(getReportAnalytics())
    dispacth(getSingleOrgHistoryAction())
  }, [dispacth])
  return (
    <div>
      <b className="normal-size">Report</b>
      <div className={isMobile ? 'flex column gap-1' : 'flex gap-2'}>
        <div
          className={isMobile ? 'flex column gap-1' : 'flex gap-1 full-width'}
        >
          <div
            className={
              isMobile ? 'flex column gap-1' : 'flex column gap-1 full-width'
            }
          >
            <AnalyticsCard
              count={`${
                reportAnalytics.totalPickups ? reportAnalytics.totalPickups : 0
              }`}
              title="Total Pickups"
              trend="20%"
              icon={cardData[0].icon}
              bgColor={cardData[0].bgColor}
              iconColor={cardData[0].iconColor}
            />
            <AnalyticsCard
              count={`${
                reportAnalytics.totalPendingPickups
                  ? reportAnalytics.totalPendingPickups
                  : 0
              }`}
              title="Total Pending Pickups"
              trend="20%"
              icon={cardData[1].icon}
              bgColor={cardData[1].bgColor}
              iconColor={cardData[1].iconColor}
            />
          </div>
          <div className="flex column gap-1 full-width">
            <AnalyticsCard
              count={0}
              title="Total Brand"
              trend="20%"
              icon={cardData[2].icon}
              bgColor={cardData[2].bgColor}
              iconColor={cardData[2].iconColor}
            />
            <AnalyticsCard
              count={0}
              title="Total Channelized "
              trend="12%"
              icon={cardData[3].icon}
              bgColor={cardData[3].bgColor}
              iconColor={cardData[3].iconColor}
            />
          </div>
        </div>
        <ChannelizeWasteChart />
      </div>

      <div className={isMobile ? 'flex column gap-1 mt-1' : 'flex gap-1 mt-1'}>
        <LocationChart />
        <BrandsChart />
        <QuantityChart />
      </div>
      <PickupHistory />
    </div>
  )
}
