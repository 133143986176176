import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { getSingleProcessorVehiclesAction } from "../../../../redux/action/processor";

function ProcessorDetails({
  step,
  setStep,
  handleChange,
  setPickupData,
  pickupData,
  hasUnsavedChanges,
  setHasUnsavedChanges,
}) {
  const dispatch = useDispatch();
  const [vehiclesData, setVehiclesData] = useState([]);
  const [driversData, setDriversData] = useState([]);
  const { processor, loading } = useSelector((state) => state.processor);
  const { loading: vehicleLoading, vehicles } = useSelector(
    (state) => state.processorVehicles
  );
  console.log(vehicles);
  const { allNetwork, singleProcessorVehicles } = useSelector((state) => ({
    allNetwork: state.allNetworkStore.allNetwork,
    loading: state.allNetworkStore.loading,
    singleProcessorVehicles: state.processor.singleProcessorVehicles,
  }));

  useEffect(() => {
    if (allNetwork && allNetwork.length > 0) {
      const traderAggregatorNetwork = allNetwork.find(
        (network) => network.type === "trader/aggregator"
      );

      if (traderAggregatorNetwork && traderAggregatorNetwork._id) {
        dispatch(getSingleProcessorVehiclesAction(traderAggregatorNetwork._id));
      }
      console.log(traderAggregatorNetwork, "traderAggregatorNetwork");
    }
  }, [allNetwork, dispatch]);

  const handleSelectChange = (selectedOptions, name) => {
    const newData = selectedOptions.map((item) => {
      const vehicle = vehicles.find((v) => v._id === item.value);
      return {
        value: item.value,
        label: item.label,
        vehicleNumber: vehicle ? vehicle.vehicleNumber : "",
      };
    });

    setPickupData((oldData) => ({
      ...oldData,
      vehicles: selectedOptions.map((item) => item.value),
    }));
  };

  useEffect(() => {
    setDriversData([]);
    vehicles.map((item) => {
      setVehiclesData((oldData) => [
        ...oldData,
        { value: item._id, label: item.vehicleNumber },
      ]);
    });

    processor?.members.map((item) => {
      if (item.role == "driver") {
        setDriversData((oldData) => [
          ...oldData,
          { value: item.account._id, label: item.account.name },
        ]);
      }
    });
  }, [processor, vehicles]);

  useEffect(() => {
    const initialPickupData = pickupData; // Store initial state

    const handleChange = () => {
      setHasUnsavedChanges(
        JSON.stringify(initialPickupData) !== JSON.stringify(pickupData)
      );
    };

    return () => {};
  }, [pickupData, setHasUnsavedChanges]);

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    });

    return () => {
      window.removeEventListener("beforeunload", handleChange);
    };
  }, [hasUnsavedChanges]);

  const handleNextStep = () => {
    if (hasUnsavedChanges) {
      confirmAlert({
        title: "Unsaved Changes",
        message: "Do you want to save your changes before proceeding?",
        buttons: [
          {
            label: "Save",
            onClick: () => {
              // Implement saving logic here
              // ...
              setStep(step + 1);
            },
          },
          {
            label: "Discard",
            onClick: () => {
              setStep(step + 1);
            },
          },
          {
            label: "Cancel",
          },
        ],
      });
    } else {
      setStep(step + 1);
    }
  };

  return (
    <div className="white p-1 ">
      <div>
        <label>Number Of Vehicles*</label>
        <input
          type="number"
          name="numberOfVehicles"
          onChange={(e) => {
            handleChange(e);
          }}
          className="browser-default input-style mb-1"
          placeholder="Enter Number Of Vehicles"
          value={pickupData.numberOfVehicles}
        />
      </div>

      <div>
        <label>Select Driver*</label>
        <Select
          options={driversData}
          isMulti={pickupData.numberOfVehicles > 1 ? true : false}
          closeMenuOnScroll={false}
          onChange={(e) => {
            if (pickupData.numberOfVehicles > 1) {
              handleSelectChange(e, "drivers");
            } else {
              setPickupData((oldData) => ({
                ...oldData,
                drivers: [e.value],
              }));
            }
          }}
        />
      </div>

      <div>
        <label>Select Vehicles*</label>
        <Select
          options={vehiclesData}
          isMulti={true}
          closeMenuOnSelect={false}
          onChange={(e) => handleSelectChange(e, "vehicles")}
          formatOptionLabel={(option) => (
            <div>
              <span>{option.label}</span>
              {/* <span style={{ marginLeft: '5px', color: 'gray' }}>
                  {option?.vehicleNumber}
                </span> */}
            </div>
          )}
        />
      </div>

      <label>Select Drop Location*</label>
      <select
        className="browser-default input-style mb-1"
        name="select-pickup-location"
        placeholder="Select Driver"
        onChange={(e) => {
          setPickupData((oldData) => ({
            ...oldData,
            dropLocation: JSON.parse(e.target.value),
          }));
        }}
      >
        <option value="">Select an option</option>
        {processor.locations?.map((item, index) => {
          const isSelected =
            pickupData.dropLocation &&
            pickupData.dropLocation.latitude === item.latitude &&
            pickupData.dropLocation.longitude === item.longitude;
          return (
            <option
              key={index}
              value={JSON.stringify({
                latitude: item.latitude,
                longitude: item.longitude,
                address: item.address,
                nickname: item.nickname,
              })}
              selected={isSelected}
            >
              {item.nickname}-{item.address}
            </option>
          );
        })}
      </select>

      <div className="flex align-center  space-between mt-2">
        <button
          className=" btn-outline cercle-btn "
          onClick={() => setStep(step - 1)}
        >
          Back
        </button>
        <button
          className="btn cercle-btn  "
          onClick={handleNextStep}
          disabled={
            !pickupData.numberOfVehicles ||
            !pickupData.drivers ||
            pickupData.drivers.length === 0 ||
            !pickupData.vehicles ||
            pickupData.vehicles.length === 0 ||
            !pickupData.dropLocation
          }
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ProcessorDetails;
