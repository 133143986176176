import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  { name: 'Group A', value: 1400 },
  { name: 'Group B', value: 400 },
  { name: 'Group C', value: 250 },
  { name: 'Group D', value: 200 },
  { name: 'Group E', value: 50 },
]
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#6F2DA8']

const TotalPickupChart = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
  return (
    <div
      className="white p-1"
      style={{
        width: isLaptop ? '100%' : '25%',
        height: '360px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <h5 style={{ textAlign: 'center' }}>% Scrap generated by quantity</h5>
      <hr color="#E5E5EF" />

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ outline: 'none' }}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default TotalPickupChart
