import { toast } from "react-toastify";
import React from "react";
import { useLocation } from "react-router-dom";
import M from "materialize-css";
import { Howl } from "howler";

export const handleError = (error) => {
  toast.error(error.response ? error.response?.data : error.message);
};

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];


export const getCurrentDateTime = (dateString) => {
  const date = new Date(dateString);
  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const month = monthsOfYear[date.getUTCMonth()];
  const year = date.getFullYear()
  const dayOfMonth = date.getUTCDate();
  const formattedDate = `${month} ${dayOfMonth} ${year} `  ;
  return formattedDate;
};



export function useQuery() {
  const { search } = useLocation();
  console.log(new URLSearchParams(search));
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function notify(msg, bg) {
  M.toast({ html: msg, classes: `${bg ? bg : ""}` });
}

export function notifyError(msg) {
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_LEFT,
    hideProgressBar: true,
    autoClose: 3000
  });
}

export function calculateTotal(scrapPrice, quantityInTonnes) {
  scrapPrice = parseFloat(scrapPrice);
  let tonnesToKg = parseFloat(quantityInTonnes) * 1000;

  if (!quantityInTonnes) return 0;
  return (tonnesToKg * scrapPrice).toLocaleString();
}

export function playSound() {
  const audio = new Howl({
    src: ["/effects/notification_sound.mp3"],
  });

  audio.play();
}

export function onNotificationClick(data, navigate) {
  const pickups = [
    "pickup_assigned",
    "pickup_completed",
    "pickup_requested",
    "driver_arrived",
    "pickup_ignored",
  ];

  if (pickups.includes(data.notificationType))
    return navigate("/timeline/" + data.pickup);

  return navigate("/pickups");
}

export const checkForProcessor = (groupId, contract) => {
  if (contract?.processorGroup === groupId) return true;
};

export const checkForGenerator = (groupId, contract) => {
  if (contract?.generatorGroup === groupId) return true;
};

export function decimalToPercentage(decimal) {
  return (decimal * 100).toString() + "%";
}

export function calculateInvoiceTotal(scrapPrice, quantityInTonnes, TCS) {
  scrapPrice = parseFloat(scrapPrice);
  let tonnesToKg = parseFloat(quantityInTonnes) * 1000;

  if (!quantityInTonnes) return 0;
  let amount = tonnesToKg * scrapPrice;

  return (amount + TCS).toLocaleString();
}

export function showStatus(pickup) {
  if (pickup.ignored) return <span style={{ color: "#FF00EE" }}>Ignored</span>;
  if (pickup.completed)
    return <span style={{ color: "#FF00EE" }}>Ignored</span>;
  return <span style={{ color: "#FF00EE" }}>Pending</span>;
}

const currentDate = new Date();
export const isToday = (givenDate) => {
  return (
    currentDate.getFullYear() === givenDate.getFullYear() &&
    currentDate.getMonth() === givenDate.getMonth() &&
    currentDate.getDate() === givenDate.getDate()
  );
};


