import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { AiOutlineEye } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, Button } from 'react-materialize'
import AddBrand from './AddBrand'
import { getAllNetworkAction } from '../../../redux/action/network'

export default function NetworkList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width:768px)' })
  const { allNetwork, loading } = useSelector((state) => state.allNetworkStore)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const closeModal = () => {
    setModalIsOpen(false)
  }

  useEffect(() => {
    dispatch(getAllNetworkAction())
  }, [dispatch])

  return (
    <div className={isMobile ? 'white mt-1 p-1' : ' full-width pb-1 card p-1 '}>
      <span className="flex align-center space-between ">
        <b className="normal-size">Networks</b>

        <span className="flex gap-2 ">
          <Button
            node="button"
            className="btn-small hover"
            onClick={() => setModalIsOpen(true)}
          >
            Add new
          </Button>
          <button
            className={' hover view-all-btn'}
            onClick={() => navigate('/all-networks')}
          >
            View All
          </button>
        </span>
      </span>

      <Modal open={modalIsOpen} options={{ onCloseEnd: closeModal }}  actions={<></>}>
        <AddBrand modal_id={'add-new-brand'} onClose={closeModal} />
      </Modal>
      <div className="full-width" style={{ overflowX: isMobile ? 'auto' : '' }}>
        <table className="striped">
          <thead>
            <tr>
              <th className="purple-text">Organization</th>
              <th className="purple-text">Role</th>
              <th className="purple-text">Locations</th>
              <th className="purple-text">Action</th>
            </tr>
          </thead>
          {loading && (
            <div className="flex align-center justify-center full-width p-2">
              Loading...
            </div>
          )}
          <tbody>
            {allNetwork?.map((item, index) => {
              if (index < 4) {
                return (
                  <tr key={index}>
                    <td className={isTablet && 'small-text'}>{item.name}</td>
                    <td className={isTablet && 'small-text'}>{item.type}</td>
                    <td className={isTablet && 'green-text small-text'}>
                      {item.locations.length}
                    </td>
                    <td>
                      <Link to={`/single-organization/${item._id}`}>
                        <AiOutlineEye size={18} />
                      </Link>
                    </td>
                  </tr>
                )
              }
              return null
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
