import { showLoading, hideLoading } from './loading'
import { wasteknot, Auth } from '../config/urlConfig'
import { toast } from 'react-toastify'

export const userPermissionAction = (toastID) => async (dispatch) => {
  const token = localStorage.getItem('wasteknot_admin')
  if (wasteknot.defaults.headers.hasOwnProperty('x-authentication-token')) {
  } else {
    wasteknot.defaults.headers.common['x-authentication-token'] = token
  }
  toast.update(toastID, {
    render: 'Verifying your credentials...',
    isLoading: true,
  })
  dispatch({ type: 'GETTING_ACCOUNT' })

  const config = {
    headers: { 'x-authentication-token': token },
  }

  wasteknot
    .get('/account/read', {}, config)
    .then((res) => {
      dispatch({ type: 'GOT_ACCOUNT', payload: res.data })
      if (res.data?.role === 'admin') {
        localStorage.setItem('is_wasteknot_admin', true)
        window.location.href = '/'
        toast.update(toastID, {
          render: 'your account verified successfully',
          isLoading: false,
          type: 'success',
          autoClose: 5000,
        })
      } else {
        toast.update(toastID, {
          render: 'You are not an admin',
          isLoading: false,
          autoClose: 5000,
          type: 'error',
        })
        window.location.href = '/'
        localStorage.setItem('is_wasteknot_admin', false)
      }
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_ACCOUNT' })
      toast.update(toastID, {
        render: error.response ? error.response?.data : error.message,
        isLoading: false,
        autoClose: 5000,
        type: 'error',
      })
    })
}

export const readAccountAction = () => async (dispatch) => {
  dispatch({ type: 'GETTING_ACCOUNT' })
  wasteknot
    .get('/account/read')
    .then((res) => {
      dispatch({ type: 'GOT_ACCOUNT', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_ACCOUNT' })
    })
}

export const getOrganizationAction = () => async (dispatch) => {
  dispatch({ type: 'GETTING_ORGANIZATION' })
  wasteknot
    .get('/organization/read')
    .then((res) => {
      dispatch({ type: 'GOT_ORGANIZATION', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_ORGANIZATION' })
    })
}

export const loginUser = (medium, password) => async (dispatch) => {
  const id = toast.loading('Logging you in...')
  dispatch(showLoading())
  Auth.post('/account/login/step-1', {
    medium: medium,
    password: password,
  })
    .then((res) => {
      localStorage.setItem('CCXT', res.data)
      dispatch(hideLoading())
      try {
        const config = {
          headers: {
            'x-authentication-token': res.data,
          },
        }
        toast.update(id, {
          render: 'Verifying your credentials...',
          isLoading: true,
        })

        wasteknot.post('/authenticate', {}, config).then((res) => {
          dispatch(hideLoading())
          localStorage.setItem('wasteknot_admin', res.data)
          dispatch(userPermissionAction(id))
        })
      } catch (error) {
        dispatch(hideLoading())
        toast.update(id, {
          render: error.response ? error.response?.data : error.message,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        })
      }
    })
    .catch((error) => {
      ;(() => {
        dispatch(hideLoading())
        toast.update(id, {
          render: error.response ? error.response?.data : error.message,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        })
      })()
    })
}

export const confirmOTP = (phoneNumber, OTP, session, navigate) => async (
  dispatch,
) => {
  const confirmOTPToast = toast.loading('Please wait a moment...')
  dispatch(showLoading())
  try {
    Auth.post('/account/login/step-2', {
      phoneNumber: phoneNumber,
      OTP: OTP,
      sessionId: session,
    })
      .then((res) => {
        if (res.data?.session) {
          dispatch(hideLoading())
        } else {
          try {
            localStorage.setItem('CCXT_auth', res.data)
            const config = {
              headers: {
                'x-authentication-token': res.data,
              },
            }
            toast.update(confirmOTPToast, {
              render: 'OTP verified successfully',
              isLoading: true,
              type: 'success',
            })
            wasteknot.post('/authenticate', {}, config).then((res) => {
              dispatch(hideLoading())
              localStorage.setItem('wasteknot_admin', res.data)
              dispatch(userPermissionAction(confirmOTPToast))
            })
          } catch (error) {
            dispatch(hideLoading())
            toast.update(confirmOTPToast, {
              render: error.response ? error.response?.data : error.message,
              type: 'error',
              isLoading: false,
              autoClose: 5000,
            })
          }
        }
      })
      .catch((error) => {
        dispatch(hideLoading())
        toast.update(confirmOTPToast, {
          render: error.response ? error.response?.data : error.message,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        })
      })
  } catch (error) {
    dispatch(hideLoading())
    toast.update(confirmOTPToast, {
      render: error.response ? error.response?.data : error.message,
      type: 'error',
      isLoading: false,
      autoClose: 5000,
    })
  }
}

export const logoutAction = () => async () => {
  localStorage.removeItem('wasteknot_admin')
  localStorage.removeItem('is_wasteknot_admin')
  window.location.href = '/'
}

export const requestOtpAction = (phoneNumber, navigate) => async (dispatch) => {
  const OTPToast = toast.loading('Requesting OTP...')

  dispatch(showLoading())
  try {
    const data = await Auth.post(`/account/login/step-1?method=OTP`, {
      medium: phoneNumber,
    })
    toast.update(OTPToast, {
      render: 'OTP sent successfully',
      type: 'success',
      isLoading: false,
      autoClose: 5000,
    })
    dispatch(hideLoading())

    navigate(`/confirm-otp/${data?.data.session} `, {
      state: { session: data?.data?.session, phoneNumber: phoneNumber },
    })
  } catch (error) {
    dispatch(hideLoading())
    toast.update(OTPToast, {
      render: error.response ? error.response?.data : error.message,
      type: 'error',
      isLoading: false,
      autoClose: 5000,
    })
  }
}
