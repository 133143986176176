import React from 'react'
import { useState, useEffect } from 'react'
import { Table, Modal } from 'react-materialize'
import { useDispatch, useSelector } from 'react-redux'
import { addVehicleAction, getVechiclesAction } from '../../../redux/action/cmd'
import { toast } from 'react-toastify'
import M from 'materialize-css'
import { wasteKnotURL } from '../../../redux/config/urlConfig'

function Vehicles() {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [capacity, setCapacity] = useState('')
  const [registrationCertificate, setRegistrationCertificate] = useState('')
  const [pollutionCertificate, setPollutionCertificate] = useState('')
  const [photo, setPhoto] = useState('')
  const { vehicles, loadingVehicles } = useSelector(
    (state) => state.organization,
  )

  const [uploadedImage, setUploadedImage] = useState(null) // New state to store the uploaded image
  const [imageSelected, setImageSelected] = useState(false)
  const [, forceUpdate] = useState()
  const handleChangePhoto = (e) => {
    setPhoto(e.target.files[0])
    setUploadedImage(URL.createObjectURL(e.target.files[0]))
  }

  const resetForm = () => {
    setVehicleNumber('')
    setCapacity('')
    setRegistrationCertificate('')
    setPollutionCertificate('')
    setPhoto('')
    setUploadedImage(null)
    setImageSelected(false)
  }

  const submit = async (e) => {
    e.preventDefault()

    if (
      !vehicleNumber ||
      !capacity ||
      !registrationCertificate ||
      !pollutionCertificate ||
      !photo
    ) {
      toast.error('All fields are required')
      return
    }

    const formData = new FormData()
    formData.append('vehicleNumber', vehicleNumber)
    formData.append('capacity', capacity)
    if (registrationCertificate)
      formData.append('registrationCertificate', registrationCertificate)
    if (pollutionCertificate)
      formData.append('pollutionCertificate', pollutionCertificate)
    if (photo) formData.append('image_0', photo)

    await dispatch(addVehicleAction(formData))
    await dispatch(getVechiclesAction())
    forceUpdate({})

    // Close the modal
    setModalOpen(false)
    resetForm()
  }

  useEffect(() => {
    dispatch(getVechiclesAction())
    const intervalId = setInterval(() => {
      dispatch(getVechiclesAction())
    }, 5000)

    return () => clearInterval(intervalId)
  }, [dispatch])

  return (
    <div className="mt-1">
      <div className="flex justify-end  ">
        <Modal
          bottomSheet={false}
          fixedFooter={false}
          id="Modal-10"
          open={modalOpen}
          options={{
            onCloseEnd: () => {
              setModalOpen(false)
              resetForm() // Reset the form when the modal is closed
            },
          }}
          trigger={<button className="btn">Add Vehicle</button>}
          actions={<></>}
        >
          <form className="flex column" onSubmit={submit}>
            <b className="mb-1">Add New Vehicle</b>
            <div className="flex align-center gap-1">
              <span className="flex column ">
                <label>Upload Vehicle Photo*</label>
                <div className="flex gap-1">
                  <label htmlFor="photo-input" className="vehicle-photo">
                    {imageSelected ? '' : ''}
                    {uploadedImage && (
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                      />
                    )}
                    {!imageSelected && !uploadedImage && (
                      <span className="material-symbols-outlined upload-photo-icon ">
                        cloud_upload
                      </span>
                    )}
                  </label>
                </div>
                <input
                  type="file"
                  id="photo-input"
                  onChange={(e) => {
                    handleChangePhoto(e)
                    setImageSelected(!!e.target.files[0])
                  }}
                  accept="image/*"
                />
              </span>
            </div>

            <div className="flex align-center gap-1 ">
              <span className="full-width">
                <label>Enter Vehicle Number*</label>
                <input
                  onChange={(e) => setVehicleNumber(e.target.value)}
                  value={vehicleNumber}
                  className="browser-default input-style "
                  type="text"
                  placeholder="Enter Vehicle Number"
                />
              </span>

              <span className="full-width">
                <label>Enter Capacity*</label>
                <input
                  onChange={(e) => setCapacity(e.target.value)}
                  value={capacity}
                  className="browser-default input-style "
                  type="number"
                  placeholder="Enter Capacity"
                />
              </span>
            </div>

            <div className="flex align-center gap-1 mt-1 ">
              <span className="full-width flex column ">
                <label> Enter Registration Certificate* </label>
                <input
                  onChange={(e) =>
                    setRegistrationCertificate(e.target.files[0])
                  }
                  className="browser-default custom-file-input input-style"
                  type="file"
                  accept="application/pdf"
                />
              </span>

              <span className="full-width">
                <label>Pollution Certificate*</label>
                <input
                  onChange={(e) => setPollutionCertificate(e.target.files[0])}
                  className="browser-default custom-file-input input-style "
                  type="file"
                  accept="application/pdf"
                />
              </span>
            </div>

            <div className="flex align-center justify-end mt-1 gap-1">
              <button
                className="btn-outline cercle-btn "
                type="button"
                onClick={() => {
                  const modalInstance = M.Modal.getInstance(
                    document.getElementById('Modal-10'),
                  )
                  modalInstance.close()
                }}
              >
                Close
              </button>

              <button
                className="cercle-btn btn"
                type="submit"
                disabled={
                  !vehicleNumber ||
                  !capacity ||
                  !registrationCertificate ||
                  !pollutionCertificate ||
                  !photo
                }
                onClick={() => {
                  const modalInstance = M.Modal.getInstance(
                    document.getElementById('Modal-10'),
                  )
                  modalInstance.close()
                }}
              >
                Send
              </button>
            </div>
          </form>
        </Modal>
      </div>
      <div className="flex flex-wrap gap-1 mt-1  ">
        {vehicles.map((item, index) => {
          console.log(vehicles, 'vehicle-img')
          return (
            <div
              className={`flex align-center`}
              key={index}
              style={{
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                borderRadius: '7px',
                background: '#fff',
                padding: '15px',
                width:'20%'
              }}
            >
              <div style={{ width: '50px', height: '50px' }}>
                <img
                  src={`${wasteKnotURL}/vehicles/read/image/${item._id}`}
                  style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                />
              </div>
              <div>
                <p className="purple-text">
                  Vehicle Number: {item.vehicleNumber}
                </p>
                <p>Capacity: {item.capacity} Tons</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Vehicles
