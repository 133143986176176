export const getAllNetworkReducer = (
  state = {
    loading: false,
    allNetwork: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_NETWORKS':
      return { ...state, loading: true }
    case 'GOT_ALL_NETWORK':
      return { ...state, loading: false, allNetwork: action.payload }
    case 'CANNOT_GET_NETWORK':
      return { ...state, loading: false }
    default:
      return state
  }
}
