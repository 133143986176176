import { wasteknot } from "../config/urlConfig";
import { toast } from "react-toastify";

export const getBrandPickupsAction = (formData) => async (dispacth) => {
  const location = formData.location.split("-");
  const locationObj = {
    latitude: 0,
    longitude: 0,
    address: location[1].trim(),
    nickname: location[0].trim(),
  };

  const requestData = {
    date: formData?.date,
    pickupLocation: locationObj,
    scrap: formData.scrap,
    numberOfVehicles: formData.numberOfVehicles,
    price: formData.price,
    processor: formData?.processor,
    generator: formData?.generator,
  };

  requestData.pickupLocation.longitude = parseFloat(
    requestData.pickupLocation.longitude
  );
  dispacth({ type: "RAISE_PICKUP" });
  wasteknot
    .post(`/pickups/create/admin/direct`, requestData)
    .then((res) => {
      dispacth({ type: "RAISED_PICKUP", payload: res.data });
      toast.success("pickup raised successfully");
    })
    .catch((error) => {
      dispacth({ type: "CANNOT_RAISE_PICKUP" });
      console.log(error);
    });
};

export const addNewPickupAction = (data, setStep) => async (dispatch) => {
  const addNewPickup = toast.loading("Adding Pickup...");

  dispatch({ type: "ADD_NEW_PICKUP" });
  wasteknot
    .post("/pickups/create/add-pickup-data", data)
    .then((res) => {
      toast.update(addNewPickup, {
        render: "Pickup has been added ",
        isLoading: false,
        autoClose: 3000,
      });
      dispatch({ type: "ADD_NEW_PICKUP_COMPLETE" });
      setStep(1);
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_NEW_PICKUP" });
      console.log(error);
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const deletePickuplAction = (id) => async (dispatch) => {
  dispatch({ type: "DELETE_PICKUP" });
  wasteknot
    .delete(`/pickups/delete/admin/${id}`)
    .then((res) => {
      dispatch({ type: "PICKUP_DELETED" });
      toast.success("Deleted pickup Successfully");
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_PICKUP_DELETED" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const AddNewPickupsAction = (addNewPickups) => async (dispatch) => {
  dispatch({ type: "CREATING_NEW_PICKUP" });
  const { eWaybill, invoiceManifest, ...otherData } = addNewPickups;
  const formData = new FormData();
  formData.append("generator", otherData.generator);
  formData.append("processor", otherData.processor);
  formData.append("scrap", otherData.scrap);
  formData.append("quantity", otherData.quantity);
  formData.append("price", otherData.price);
  formData.append("date", otherData.date);
  formData.append("pickupLocation", otherData.pickupLocation);
  formData.append("dropLocation", otherData.dropLocation);
  formData.append("numberOfVehicles", otherData.numberOfVehicles);
  formData.append("drivers", otherData.drivers);
  formData.append("vehicles", otherData.vehicles);
  formData.append("image_0", otherData.image_0);
  formData.append("eWaybill", eWaybill);
  formData.append("invoiceManifest", invoiceManifest);

  wasteknot
    .post("/pickups/create/add-pickup-data", addNewPickups)
    .then((res) => {
      dispatch({ type: "NEW_SCRAP_PICKUP_COMPLETE" });
      window.location.reload();
      toast.success("Pickups Has Been Added Successfully.");
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_CREATE_NEW_PICKUP" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};
