export const getSingleOrganizationReducer = (
  state = {
    loading: false,
    organization: {},
  },
  action
) => {
  switch (action.type) {
    case "GETTING_ORGANIZATION":
      return { ...state, loading: true };
    case "GOT_ORGANIZATION":
      return { ...state, loading: false, organization: action.payload };
    case "CANNOT_GET_ORGANIZATION":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getPickupsHistorySingleOrgReducer = (
  state = {
    loading: false,
    pickupsHistory: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_PICKUPS_HISTORY":
      return { ...state, loading: true };
    case "GOT_PICKUPS_HISTORY_SINGLEORG":
      return { ...state, loading: false, pickupsHistory: action.payload };
    case "CANNOT_GET_PICKUPS_HISTORY":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getPickupsHistorySingleOrgReducerV2 = (
  state = {
    loading: false,
    pickupsHistoryV2: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_PICKUPS_HISTORY_NEW":
      return { ...state, loading: true };
    case "GOT_PICKUPS_HISTORY_SINGLEORG_NEW":
      return { ...state, loading: false, pickupsHistoryV2: action.payload };
    case "GOT_PICKUPS_AFTER_UPDATE":
      return { ...state, loading: false, pickupsHistoryV2: action.payload };
    case "CANNOT_GET_PICKUPS_HISTORY_NEW":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getNetworkOfAnOrganizationReducer = (
  state = {
    networkOfAnOrganization: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case "GETTING_ORGANIZATION_NETWORK":
      return { ...state, loading: true };
    case "GOT_ORGANIZATION_NETWORK":
      return {
        ...state,
        loading: false,
        networkOfAnOrganization: action.payload,
      };
    case "CANNOT_GET_NETWORK":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getAllPickupsProgressReducer = (
  state = {
    loading: false,
    progress: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_PROGRESS":
      return { ...state, loading: true };
    case "GOT_PROGRESS":
      return { ...state, loading: false, progress: action.payload };
    case "CANNOT_GET_PROGRESS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getPaginationReducer = (
  state = {
    loading: false,
    pagination: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_PAGINATION":
      return { ...state, loading: true };
    case "GOT_PAGINATION":
      return { ...state, loading: false, pagination: action.payload };
    case "CANNOT_GET_PAGINATION":
    case "GOT_PICKUPS_AFTER_UPDATE":
      return { ...state, loading: false, pagination: action.payload };
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleOrgInvitesReducer = (
  state = {
    loading: false,
    invites: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_INVITES":
      return { ...state, loading: true };
    case "GOT_PICKUPS_INVITESGOT_PICKUPS_INVITES":
      return { ...state, loading: false, invites: action.payload };
    case "CANNOT_GET_INVITES":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleOrgSummaryReducer = (
  state = {
    loading: false,
    summary: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_SUMMARY":
      return { ...state, loading: true };
    case "GOT_SUMMARY":
      return { ...state, loading: false, summary: action.payload };
    case "CANNOT_GET_SUMMARY":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getAllScrapsReducer = (
  state = {
    loading: false,
    scraps: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_ALL_SCRAP":
      return { ...state, loading: true };
    case "GOT_ALL_SCRAP":
      return { ...state, loading: false, scraps: action.payload };
    case "CANNOT_GET_ALL_SCRAP":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getHistorySingleOrgReducer = (
  state = {
    loading: false,
    history: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_PICKUPS_HISTORY":
      return { ...state, loading: true };
    case "GOT_PICKUPS_HISTORY_SINGLEORG":
      return { ...state, loading: false, history: action.payload };
    case "CANNOT_GET_PICKUPS_HISTORY":
      return { ...state, loading: false };
    case "GOT_PICKUPS_AFTER_UPDATE":
      return { ...state, loading: false, history: action.payload };
    default:
      return state;
  }
};

export const getQuantityPerScrapReducer = (
  state = {
    loading: false,
    quantity: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_QUANTITY":
      return { ...state, loading: true };
    case "GOT_QUANTITY":
      return { ...state, loading: false, quantity: action.payload };
    case "CANNOT_GET_QUANTITY":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getLocationChartReducer = (
  state = {
    loading: false,
    locationchart: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_LOCATION":
      return { ...state, loading: true };
    case "GOT_LOCATION":
      return { ...state, loading: false, locationchart: action.payload };
    case "CANNOT_GET_LOCATION":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const organizationReducer = (
  state = {
    vehicles: [],
    loadingVehicles: false,

    drivers: [],
    loadingDrivers: false,

    locations: [],
    loadingLocations: false,

    accounts: [],
    loadingAccounts: false,

    groups: [],
    group: {},
    loadingGroups: false,

    invitations: [],
    loadingInvitations: false,

    notifications: [],
    loadingNotifications: false,
    seenNotifications: true,

    organization: {},

    network: [],
    networkLoading: false,

    closestNetwork: [],
  },
  action
) => {
  switch (action.type) {
    //******  Reading Organization  ****//

    case "GETTING_NETWORK":
      return { ...state, networkLoading: true };

    case "GOT_NETWORK":
      return { ...state, network: action.payload, networkLoading: false };

    case "CANNOT_GET_NETWORK":
      return { ...state, networkLoading: false };

    case "GETTING_CLOSEST_NETWORK":
      return { ...state, networkLoading: true };

    case "GOT_CLOSEST_NETWORK":
      return {
        ...state,
        closestNetwork: action.payload,
        networkLoading: false,
      };

    case "CANNOT_GET_CLOSEST_NETWORK":
      return { ...state, networkLoading: false };

    case "GETTING_ORGANIZATION":
      return { ...state, loading: true };

    case "GOT_ORGANIZATION":
      return { ...state, organization: action.payload, loading: false };

    case "CANNOT_GET_ORGANIZATION":
      return { ...state, loading: false };

    case "GETTING_VEHICLES":
      return { ...state, loadingVehicles: true };

    case "GOT_VEHICLES":
      return { ...state, vehicles: action.payload, loadingVehicles: false };

    case "CANNOT_GET_VEHICLES":
      return { ...state, loadingVehicles: false };

    case "GETTING_LOCATIONS":
      return { ...state, loadingLocations: true };

    case "GOT_LOCATIONS":
      return { ...state, locations: action.payload, loadingLocations: false };

    case "CANNOT_GET_LOCATIONS":
      return { ...state, loadingLocations: false };

    case "GETTING_DRIVERS":
      return { ...state, loadingDrivers: true };

    case "GOT_DRIVERS":
      return { ...state, drivers: action.payload, loadingDrivers: false };

    case "CANNOT_GET_DRIVERS":
      return { ...state, loadingDrivers: false };

    case "GETTING_ACCOUNTS":
      return { ...state, loadingAccounts: true };

    case "GOT_ACCOUNTS":
      return { ...state, accounts: action.payload, loading: false };

    case "CANNOT_GET_ACCOUNTS":
      return { ...state };

    case "GETTING_GROUPS":
      return { ...state, loadingGroups: true };

    case "GOT_GROUPS":
      return { ...state, groups: action.payload, loadingGroups: false };

    case "CANNOT_GET_GROUPS":
      return { ...state };

    case "GETTING_INVITATIONS":
      return { ...state, loadingInvitations: true };

    case "GOT_INVITATIONS":
      return { ...state, invitations: action.payload };

    case "CANNOT_GET_INVITATIONS":
      return { ...state };

    case "ADDING_LOCATION":
      return { ...state, loadingLocations: true };

    case "ADDED_LOCATION":
      return {
        ...state,
        loadingLocations: false,
      };
    case "CANNOT_ADD_LOCATION":
      return { ...state, loadingLocations: false };

    case "INVITING_ACCOUNT":
      return { ...state, loadingAccounts: true };

    case "INVITED_ACCOUNT":
      return { ...state, loadingAccounts: false };

    case "CANNOT_INVITE_ACCOUNT":
      return { ...state, loadingAccounts: false };

    case "UPDATING_ORGANIZATION":
      return { ...state, loading: true };

    case "UPDATED_ORGANIZATION":
      return { ...state, loading: false };

    case "CANNOT_UPDATE_ORGANIZATION":
      return { ...state, loading: false };

    case "UPDATING_NETWORK":
      return { ...state, networkLoading: true };

    case "UPDATED_NETWORK":
      return { ...state, networkLoading: false };

    case "CANNOT_UPDATE_NETWORK":
      return { ...state, networkLoading: false };

    //****** End Of Reading ****//

    default:
      return state;
  }
};

// traderIdReducer.js
const traderIdReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_TRADER_ID":
      console.log("Action Payload:", action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default traderIdReducer;
