import { wasteknot } from '../config/urlConfig'
import { toast } from 'react-toastify'

export const addVehicleAction = (data) => async (dispatch) => {
  const loadingToast = toast.loading('Please wait a moment...')
  dispatch({ type: 'ADD_VIHICLE' })
  wasteknot
    .post('/vehicles/create', data)
    .then((res) => {
      dispatch({ type: 'ADDED_VEHICLE', payload: res.data })
      toast.update(loadingToast, {
        render: 'Vehicle Added Successfully',
        isLoading: false,
        type: 'success',
        autoClose: 5000,
      })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_ADD_VEHICLE' })
      toast.update(loadingToast, {
        render: error.response ? error.response?.data : error.message,
        isLoading: false,
        type: 'error',
        autoClose: 5000,
      })
    })
}

export const addDriverAction = (driver) => async (dispatch) => {
  const toastifyId = toast.loading('Please wait a moment...')

  dispatch({ type: 'ADD_DRIVER' })
  wasteknot
    .put('/organization/update/invite-account', driver)
    .then((res) => {
      toast.update(toastifyId, {
        render: res.data,
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      })
      dispatch({ type: 'ADDED_DRIVER', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_ADD_DRIVER' })
      toast.update(toastifyId, {
        render: error.response ? error.response?.data : error.message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      })
    })
}

export const getDriversAction = () => async (dispatch) => {
  dispatch({ type: 'GETTING_DRIVERS' })
  wasteknot
    .get('/organization/read/drivers')
    .then((res) => {
      dispatch({ type: 'GOT_DRIVERS', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_DRIVERS' })
      toast.error(error.response ? error.response?.data : error.message)
    })
}

export const getVechiclesAction = () => async (dispatch) => {
  dispatch({ type: 'GETTING_VEHICLES' })
  wasteknot
    .get('/vehicles/read')
    .then((res) => {
      dispatch({ type: 'GOT_VEHICLES', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_VEHICLES' })
      toast.error(error.response ? error.response?.data : error.message)
    })
}
