import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import {
  getPaginationAction,
  getSingleOrgHistoryAction,
  updateQuantityAction,
} from '../../../redux/action/organization'
import { useParams } from 'react-router'
import Loading from '../../../layout/Loading'
import { getCurrentDateTime } from '../../../helpers/helper'
import { useNavigate } from 'react-router'
import { deletePickuplAction } from '../../../redux/action/pickups'
import { Modal, Button, Pagination, Icon } from 'react-materialize'
import { Carousel } from 'react-materialize'
import { wasteKnotURL } from '../../../redux/config/urlConfig'
import ReassignDriver from '../components/ReassignDriver'
import ReassignTrader from '../components/ReassignTrader'
import M from "materialize-css";


function AllPickups() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const dispacth = useDispatch()
  const navigate = useNavigate()
 
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [onOpenViewImg, setOpenViewImg] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [openTraderModal, setOpenTraderModal] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [historyPagination, setHistoryPagination] = useState([])
  const [numberOfRow, setNumberOfRow] = useState(40)
  const isTablet = useMediaQuery({ query: '(max-width: 824px)' })
  const isLaptop = useMediaQuery({ query: "(max-width: 1600px)" });
  const [editedQuantityValue, setEditedQuantityValue] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  const { pickupsHistory, loading } = useSelector(
    (state) => state.singleOrganizationPickupsHistory,
  )
  const { pagination } = useSelector((state) => state.pagination)

  
  const onCloseDelete = () => setOpenDelete(false)
 

  const onOpenTraderModal = () => setOpenTraderModal(true)
  const onCloseTraderModal = () => setOpenTraderModal(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const closeModal = () => {
    setModalIsOpen(false)
  }
 
  // const numberOfRow = 100
  const { id } = useParams()
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null)
  const [selectedPickup, setSelectedPickup] = useState(null)

  const [skipPagination, setSkipPagination] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const handleDropdownToggle = (index) => {
    if (activeDropdownIndex === index) {
      setActiveDropdownIndex(null)
    } else {
      setActiveDropdownIndex(index)
    }
  }
 
  const getIconForIndex = (index) => {
    return activeDropdownIndex === index
      ? 'expand_circle_up'
      : 'expand_circle_down'
  }

 
  const handleDeleteConfirmation = async (itemId) => {
    await dispacth(deletePickuplAction(itemId))
    onCloseDelete()
    setIsDeleted(true)
  }

 

  
  const handleCloseModal = (itemId) => {
    var elem = document.getElementById(`modal-id-${itemId}`);
    var instance = M.Modal.getInstance(elem);
    instance.close();
  };


  const handleSaveChangesClick = (e) => {
    e.preventDefault();
 
    dispacth(
      updateQuantityAction(
        selectedPickup,
        editedQuantityValue,
        id,
        handleCloseModal,
        setUpdateLoading,
        getPaginationAction
      )
    );

  };

  

  useEffect(() => {
    dispacth(getSingleOrgHistoryAction())
    dispacth(getPaginationAction(id, skipPagination, numberOfRow))
    const intervalId = setInterval(() => {
      if (isDeleted) {
        dispacth(getSingleOrgHistoryAction())

        setIsDeleted(false)
      }
    }, 10000)

    return () => clearInterval(intervalId)
  }, [dispacth, isDeleted])

  // pagination

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    // renderTableBody();
    setSkipPagination((pageNumber - 1) * numberOfRow)
  }

  useEffect(() => {
    dispacth(getPaginationAction(id, skipPagination, numberOfRow))
  }, [dispacth, id, skipPagination, numberOfRow])

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }
  return (
    <div className="box full-width white p-1">
      <div className="flex space-between align-center">
        <b className="bold normal-size">Pickups History </b>
      </div>
      <table className="striped ">
        <thead>
          <tr>
            <th className="purple-text">Date</th>
            <th className="purple-text">Scrap</th>
            <th className="purple-text">Quantity</th>
            <th className="purple-text">Processor</th>
            <th className="purple-text">Price (INR)</th>
            <th className="purple-text">Status</th>
            <th className="purple-text">Action</th>
          </tr>
        </thead>
        <tbody>
          {pagination?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td className={isTablet && 'small-text'}>
                    <div className="flex align-center gap-1">
                      <span className="flex  ml-1">
                        <span
                          className="material-symbols-outlined purple-text pointer assign-dropdown"
                          style={{ fontSize: '20px' }}
                          onClick={() => handleDropdownToggle(index)}
                        >
                          {getIconForIndex(index)}
                        </span>
                      </span>

                      <span>{getCurrentDateTime(item.date)}</span>
                    </div>
                  </td>
                  <td className={isTablet && 'small-text'}>
                    {item.scrap.name} - {item.scrap.variant}
                  </td>
                  <td className={isTablet && 'small-text'}>
                    {item.quantity ? item.quantity + 'MT' : '---'}
                  </td>
                  <td className={isTablet && 'small-text'}>
                    {item.processor.name}
                  </td>
                  <td className={isTablet && 'small-text'}>{item.price} ₹</td>
                  <td className={isTablet && 'small-text'}>
                    <p
                      className={
                        item.status === 'requested'
                          ? 'pending'
                          : item.status === 'accepted'
                          ? 'accepted'
                          : item.status === 'completed'
                          ? 'completed'
                          : item.status === 'assigned'
                          ? 'assigned'
                          : 'active'
                      }
                    >
                      {item.status}
                    </p>
                  </td>
                  <td className={isTablet && 'small-text'}>
                    <span
                      className="material-symbols-outlined purple-text pointer"
                      onClick={() =>
                        navigate(`/singlebrandpickupview/${item._id}`, {
                          state: { organizationId: item },
                        })
                      }
                    >
                      visibility
                    </span>

                    
                            <Modal
                              className="modal-container"
                              id={`modal-id-${item._id}`}
                              trigger={
                                <span>
                                  <span
                                    className="material-symbols-outlined blue-text pointer"
                                    onClick={() => {
                                      setSelectedPickup(item._id);
                                      setEditedQuantityValue(item.quantity);
                                    }}
                                  >
                                    edit
                                  </span>
                                </span>
                              }
                              actions={<></>}
                            >
                              <div className="modal-content Edit-quantity ">
                                <form onSubmit={handleSaveChangesClick}>
                                  <div className="flex align-center gap-1 space-between full-width">
                                    <p>Edit Quantity: </p>
                                  </div>
                                  <div className="flex gap-1 mt-1">
                                    <div
                                      className="full-width flex column gap-1 "
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        className="input-field-style full-width edit-quantity "
                                        style={{ width: "49%" }}
                                      >
                                        <input
                                          value={editedQuantityValue}
                                          type="number"
                                          placeholder="Enter Quantity"
                                          onChange={(e) =>
                                            setEditedQuantityValue(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <span
                                        class="material-symbols-outlined"
                                        style={{
                                          position: "absolute",
                                          left: isLaptop ? 320 : 420,
                                          top: 1,
                                          color: "#6F2DA8",
                                          background: "#ECE4F4",
                                          borderRadius: "0 6px 6px 0",
                                          padding: "15px",
                                        }}
                                      >
                                        <p style={{ fontSize: "14px" }}>MT</p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="flex justify-end gap-2 mt-1">
                                    <button
                                      className="btn edit-button"
                                      disabled={
                                        !editedQuantityValue || updateLoading
                                      }
                                      type="submit"
                                    >
                                      {updateLoading ? "loading..." : " Submit"}
                                    </button>
                                    <Button
                                      className="btn-outline-small"
                                      modal="close"
                                    >
                                      Close
                                    </Button>
                                  </div>
                                </form>
                              </div>
                            </Modal>
                        
                  
                      <Modal
                        className="modal-container-new"
                        id="add_new"
                        trigger={
                          <span className="material-symbols-outlined red-text pointer">
                            delete
                          </span>
                        }
                        actions={<></>}
                      >
                        <div className="modal-content Edit-quantity ">
                          <b>Confirm Delete</b>
                          <div className="flex space-between">
                            <p className="mt-1">
                              Are you sure you want to want delete Pickups ?
                            </p>
                            <div className="flex gap-1">
                              <Button
                                className=" btn-small green"
                                modal="close"
                                onClick={() => {
                                  handleDeleteConfirmation(item._id) // Perform delete logic
                                  closeModal() // Close the modal after deletion
                                }}
                              >
                                YES
                              </Button>
                              <Button
                                flat
                                className="btn-small red white-text"
                                node="button"
                                onClick={closeModal}
                                modal="close"
                              >
                                NO
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                  </td>
                </tr>
                {activeDropdownIndex === index && (
                  <tr className="full-width">
                    <td colSpan="7" style={{ border: 'none' }}>
                      <div
                        className={
                          isMobile
                            ? 'flex column'
                            : 'reassign-pickup flex space-between p-1 align-center'
                        }
                        style={{ background: '#FBF5FF' }}
                      >
                        <div className="flex gap-2">
                          <div className="p-1 pickup-location">
                            <p className="grey-text">
                              PICKUP LOCATION - OFFICE{' '}
                            </p>
                            <p>{item.pickupLocation.address}</p>
                          </div>
                          {item.status === 'requested' && (
                            <div className="p-1 processor-name">
                              <p className="grey-text">PROCESSOR</p>
                              <p>{item.processor.name}</p>
                            </div>
                          )}
                          {item.status === 'accepted' && (
                            <div className="p-1 processor-name">
                              <p className="grey-text">PROCESSOR</p>
                              <p>{item.processor.name}</p>
                            </div>
                          )}
                          {item.status === 'assigned' && (
                            <>
                              <div className="p-1 processor-name">
                                <p className="grey-text">PROCESSOR</p>
                                <p>{item.processor.name}</p>
                              </div>
                              {item?.vehicles.map((vehicles, index) => (
                                <div key={index} className="p-1 driver-name">
                                  <p className="grey-text">VEHICLE NUMBER</p>
                                  <p>{vehicles.vehicleNumber}</p>
                                </div>
                              ))}
                              {item?.drivers.map((driver, index) => (
                                <div key={index} className="p-1 driver-name">
                                  <p className="grey-text">DRIVER</p>
                                  <p>{driver.name}</p>
                                </div>
                              ))}
                            </>
                          )}
                          {item.status === 'completed' && (
                            <>
                              <div className="p-1 processor-name">
                                <p className="grey-text">PROCESSOR</p>
                                <p>{item.processor.name}</p>
                              </div>
                              <div className="p-1 pickup-location">
                                <p className="grey-text">
                                  Drop LOCATION - OFFICE{' '}
                                </p>
                                <p>{item.dropLocation.address}</p>
                              </div>

                              {item?.vehicles.map((vehicles, index) => (
                                <div key={index} className="p-1 driver-name">
                                  <p className="grey-text">VEHICLE NUMBER</p>
                                  <p>{vehicles.vehicleNumber}</p>
                                </div>
                              ))}
                              {item?.drivers.map((driver, index) => (
                                <div key={index} className="p-1 driver-name">
                                  <p className="grey-text">DRIVER</p>
                                  <p>{driver.name}</p>
                                </div>
                              ))}
                              <div className="p-1 quantity">
                                <p className="grey-text">QUANTITY</p>
                                <p>
                                  {item.quantity ? item.quantity + 'MT' : '---'}
                                </p>
                              </div>
                            </>
                          )}
                        </div>

                        <div
                          className="flex gap-1"
                          style={{ overflowY: 'none' }}
                        >
                          {item.status === 'completed' && (
                            <Modal
                              className="modal-container"
                              id="add_new"
                              trigger={
                                <button
                                  className="btn green"
                                  // onClick={onOpenViewImgModal}
                                  style={{
                                    fontSize: isMobile ? '12px' : '',
                                  }}
                                >
                                  View
                                </button>
                              }
                            >
                              {item.images.length ? (
                                <Carousel
                                  carouselId="Carousel-36"
                                  className="custom-carousel"
                                  images={item.images.map((img, i) => {
                                    return `${wasteKnotURL}/pickups/read/image/${
                                      item._id
                                    }?count=${i + 1}`
                                  })}
                                  options={{
                                    fullWidth: true,
                                    indicators: true,
                                  }}
                                />
                              ) : (
                                <div className="center">
                                  <p>No Images uploaded</p>
                                </div>
                              )}
                            </Modal>
                          )}

                          {['requested', 'assigned', 'accepted'].includes(
                            item.status,
                          ) && (
                            <>
                              <button
                                className="btn-outline"
                                onClick={onOpenModal}
                                style={{
                                  fontSize: isMobile ? '12px' : '',
                                }}
                              >
                                REASSIGN DRIVER
                              </button>
                              <button
                                className="btn"
                                onClick={onOpenTraderModal}
                                style={{
                                  fontSize: isMobile ? '12px' : '',
                                }}
                              >
                                REASSIGN TRADER
                              </button>
                            </>
                          )}

                          <Modal
                            center
                            styles={{
                              modal: {
                                borderRadius: '10px',
                                padding: 0,
                                width: isTablet ? '380px' : '800px',
                                margin: 0,
                              },
                            }}
                            open={open}
                            onClose={onCloseModal}
                            closeIcon={<></>}
                          >
                            <>
                              <div className="cercle-purple p-1 white-text flex align-center space-between">
                                <h4>Reassign Driver</h4>
                                <div
                                  onClick={onCloseModal}
                                  className="flex align-center justify-center"
                                  style={{
                                    width: 40,
                                    height: 40,
                                    background: 'rgba(255, 255, 255, 0.50)',
                                    borderRadius: '6px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <span className="material-symbols-outlined">
                                    close
                                  </span>
                                </div>
                              </div>
                              <ReassignDriver />
                            </>
                          </Modal>

                          <Modal
                            center
                            styles={{
                              modal: {
                                borderRadius: '10px',
                                padding: 0,
                                width: isTablet ? '380px' : '800px',
                                margin: 0,
                              },
                            }}
                            open={openTraderModal}
                            onClose={onCloseTraderModal}
                            closeIcon={<></>}
                          >
                            <>
                              <div className="cercle-purple p-1 white-text flex align-center space-between">
                                <h4>Reassign trader</h4>
                                <div
                                  onClick={onCloseTraderModal}
                                  className="flex align-center justify-center"
                                  style={{
                                    width: 40,
                                    height: 40,
                                    background: 'rgba(255, 255, 255, 0.50)',
                                    borderRadius: '6px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <span className="material-symbols-outlined">
                                    close
                                  </span>
                                </div>
                              </div>
                              <ReassignTrader />
                            </>
                          </Modal>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
      <div className="flex align-center justify-center">
        <Pagination
          activePage={currentPage}
          items={currentPage + 1}
          leftBtn={
            <button
              className="material-symbols-outlined"
              onClick={() => handlePageChange(currentPage - 1)}
              // disabled={(currentPage = 1)}
            >
              navigate_before
            </button>
          }
          rightBtn={
            <button
              className="material-symbols-outlined"
              onClick={(e) => {
                handlePageChange(currentPage + 1)
                handlePageChange(e)
              }}
              disabled={numberOfRow > pagination?.length}
            >
              navigate_next
            </button>
          }
          className="mt-2"
          onSelect={(e) =>
            handlePageChange(
              e < currentPage
                ? e
                : numberOfRow > pagination?.length
                ? currentPage
                : e,
            )
          }
        />
      </div>
    </div>
  )
}

export default AllPickups
