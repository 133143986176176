import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addBandAction } from '../../../redux/action/network'
import { toast } from 'react-toastify'

function AddBrand({ modal_id, onClose }) {
  const dispacth = useDispatch()

  const [data, setData] = useState({
    email: '',
    application: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (data.email === '' || data.application === '') {
      return toast.error('Please fill all the fields')
    }

    dispacth(addBandAction(data))
  }

  return (
    <div>
      <b>Add New Brand</b>

      <div>
        <form className="mt-1" onSubmit={handleSubmit}>
          <span className="flex align-center gap-1 ">
            <span className="full-width">
              <b>Brand Email</b>
              <div className="input-field-style full-width">
                <span className="material-symbols-outlined primary">email</span>
                <input
                  className="browser-default input-field"
                  placeholder="Enter brand email"
                  onChange={(e) => handleChange(e)}
                  type="email"
                  name="email"
                />
              </div>
            </span>

            <span className="full-width">
              <b>Select Platform</b>
              <div className="input-field-style full-width">
                <select
                  className="browser-default"
                  onChange={(e) =>
                    setData({ ...data, application: e.target.value })
                  }
                >
                  <option>--select--</option>
                  <option value={'5e4e3e9e4b8b2b000604b32a'}>WasteKnot</option>
                </select>
              </div>
            </span>
          </span>
          <div className="flex justify-end gap-2 mt-1">
            <button className="btn-outline" type="button" onClick={onClose}>
              Close
            </button>
            <button
              className="btn"
              type="submit"
              onClick={onClose}
              disabled={!data.email || !data.application}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddBrand
