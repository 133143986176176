import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

function Notification({}) {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const notifications = []
  if (notifications.length == 0 || !notifications) {
    return (
      <div className="flex column white ">
        <div className="flex space-between p-1">
          <b>Notification & Updates</b>
          <Link to="/notification">
            {' '}
            <button
              className={
                isMobile ? 'bold purple-text white ' : 'box btn-outline'
              }
            >
              View All
            </button>
          </Link>
        </div>
        <p className="flex justify-center">No notifications available.</p>
      </div>
    )
  }
}

export default Notification
