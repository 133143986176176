import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getCurrentDateTime } from '../../../helpers/helper'
import { useNavigate } from 'react-router'
import { Modal } from 'react-materialize'
import { useSelector } from 'react-redux'
import RejectPickup from '../../trader/components/RejectPickup'
import { getSingleOrgHistoryAction } from '../../../redux/action/organization'
import { useDispatch } from 'react-redux'

export default function AllTrader() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const dispacth = useDispatch()
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const { pickupsHistory } = useSelector(
    (state) => state.singleOrganizationPickupsHistory,
  )
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  useEffect(() => {
    dispacth(getSingleOrgHistoryAction())
  }, [dispacth])

  return (
    <div className="box full-width white p-1 card ">
      <div className="flex space-between align-center">
        <b className="bold normal-size">Trader List</b>
      </div>
      <div style={{ overflowX: isLaptop ? 'auto' : '' }}>
        <table className="striped">
          <thead>
            <tr>
              <th className="purple-text">Date</th>
              <th className="purple-text">Scrap</th>
              <th className="purple-text">Quantity</th>
              <th className="purple-text">Processor</th>
              <th className="purple-text">Price (INR)</th>
              <th className="purple-text">Status</th>
              <th className="purple-text">Action</th>
            </tr>
          </thead>
          <tbody>
            {pickupsHistory?.map((item, index) => {
              if (index < 10)
                return (
                  <tr key={index}>
                    <td className={isTablet && 'small-text'}>
                      {getCurrentDateTime(item.date)}
                    </td>
                    <td className={isTablet && 'small-text'}>
                      {item.scrap.name}
                      {item.scrap.variant}
                    </td>
                    <td className={isTablet && 'small-text'}>
                      {item.quantity ? item.quantity + 'MT' : '---'}
                    </td>
                    <td className={isTablet && 'small-text'}>
                      {item.processor.name}
                    </td>
                    <td className={isTablet && 'small-text'}>{item.price} ₹</td>
                    <td className="flex gap-1 align-center">
                      <p
                        className={
                          item.status === 'requested'
                            ? 'pending'
                            : item.status === 'accepted'
                            ? 'accepted'
                            : item.status === 'completed'
                            ? 'completed'
                            : item.status === 'assigned'
                            ? 'assigned'
                            : 'active'
                        }
                      >
                        {item.status}
                      </p>
                    </td>
                    <td className={isTablet && 'small-text action-data'}>
                      <span
                        className="material-symbols-outlined purple-text pointer"
                        onClick={() =>
                          navigate(`/singlebrandpickupview/${item._id}`, {
                            state: { organizationId: item },
                          })
                        }
                      >
                        visibility
                      </span>
                      {item.status === 'requested' && (
                        <span
                          className="material-symbols-outlined red-text pointer"
                          onClick={onOpenModal}
                        >
                          close
                        </span>
                      )}
                    </td>
                  </tr>
                )
            })}
          </tbody>
        </table>
      </div>
      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isTablet ? '380px' : '800px',
            margin: 0,
          },
        }}
        open={open}
        onClose={onCloseModal}
        closeIcon={<></>}
      >
        <RejectPickup onCloseModal={onCloseModal} />
      </Modal>
    </div>
  )
}
