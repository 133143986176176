import React from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router'
import { useMediaQuery } from 'react-responsive'

export default function PickupRaise() {
  const navigate = useNavigate()
  const { id } = useParams()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const handlePickupRaise = () => {
    navigate(`/single-organization/${id}/raise-pickup`)
  }

  const handleAddNewPickup = () => {
    navigate(`/single-organization/${id}/add-pickup`)
  }

  return (
    <div
      className={isMobile ? 'flex column' : 'flex align-center space-between'}
    >
      <b>Single Organization</b>
      <div className="flex gap-1">
        <button className="btn" onClick={handlePickupRaise}>
          Pickup Raise
        </button>
        <button className="btn-outline" onClick={handleAddNewPickup}>
          Add New Pickup
        </button>
      </div>
    </div>
  )
}
