import React, { useState } from 'react'
import 'react-responsive-modal/styles.css'
import InviteUser from './InviteUser'
import { useMediaQuery } from 'react-responsive'
import { Button, Dropdown, Modal } from 'react-materialize'
import Loading from '../../../../layout/Loading'

export default function User({ organizationDetails, loading }) {
  const [open, setOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const handleCancel = () => {
    setOpen(false)
  }

  const filteredMembers = organizationDetails?.members?.filter((item) => {
    const { name, email, phoneNumber } = item.account
    const { role } = item
    const normalizedQuery = searchQuery.toLowerCase().trim()
    const formattedPhoneNumber = phoneNumber ? String(phoneNumber) : ''

    return (
      name.toLowerCase().includes(normalizedQuery) ||
      email.toLowerCase().includes(normalizedQuery) ||
      formattedPhoneNumber.toLowerCase().includes(normalizedQuery) ||
      role.toLowerCase().includes(normalizedQuery)
    )
  })

  const modalCustomStyles = {
    modal: {
      maxWidth: isMobile ? '1000px' : '600px',
      width: isTablet ? '90%' : '60%',
    },
  }

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <div>
        <div className={isMobile ? 'flex column' : 'flex align-center p-1'}>
          <div className={isTablet ? '' : 'full-width'}>
            <h6 className="bold-title mr-1">Account</h6>
          </div>
          <div
            style={{ justifyContent: 'flex-end' }}
            className="flex align-center full-width"
          >
            <div
              className="input-field-style flex align-center small-text"
              style={{
                margin: 0,
                color: '#BFC3CE',
                width: isTablet ? '100%' : '40%',
                marginRight: '10px',
              }}
            >
              <span className="material-symbols-outlined mr-1">search</span>
              <input
                style={{ margin: 0, caretColor: 'black' }}
                placeholder="Search"
                className="hide-focus-underline"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="flex gap-1">
              {/* 
              <Modal
                open={open}
                onClose={onCloseModal}
                center
                classNames="invite-user"
                styles={modalCustomStyles}
                actions={<></>}
              >
                <InviteUser handleCancel={handleCancel} />
              </Modal> */}
                <Modal
                actions={<></>}
                bottomSheet={false}
                fixedFooter={false}
                id="Modal-0"
                open={false}
                options={{
                  dismissible: true,
                  endingTop: '10%',
                  inDuration: 250,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  opacity: 0.5,
                  outDuration: 250,
                  preventScrolling: true,
                  startingTop: '4%',
                }}
                trigger={
                  <button className="btn" onClick={onOpenModal}>
                  Add New User
                </button>
                }
              >
                <InviteUser />
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div
        className="accounttable"
        style={{ overflowX: isMobile ? 'auto' : '' }}
      >
        {organizationDetails &&
        organizationDetails.locations &&
        organizationDetails.members &&
        organizationDetails.members.length > 0 ? (
          filteredMembers && filteredMembers.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>NAME</th>
                  <th>EMAIL</th>
                  <th>PHONE NUMBER </th>
                  <th>ROLE</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {filteredMembers.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="capitalize">{item.account.name}</td>
                      <td className="lowercase">{item.account.email}</td>
                      <td>{item.account.phoneNumber}</td>
                      <td className="capitalize">{item.role}</td>
                      <td className="flex justify-center">
                        <div className="active-status">Active</div>
                      </td>
                      <td>
                        <Dropdown
                          trigger={
                            <div>
                              <i className="material-symbols-outlined">
                                more_vert
                              </i>
                            </div>
                          }
                        >
                          <span>
                            {item.enabled
                              ? 'Deactivate Account'
                              : 'Activate Account'}
                          </span>

                          <Modal
                            actions={[]}
                            id="Modal-10"
                            open={false}
                            bottomSheet={false}
                            trigger={
                              <span node="button">
                                {item.enabled
                                  ? 'Delete Account'
                                  : 'Activate Account'}
                              </span>
                            }
                            header="Delete Account"
                          >
                            <p>Are you sure you want to Delete this account?</p>
                            <div className="flex  justify-end gap-2  ">
                              <Button
                                className="green btn-small "
                                // onClick={() => deleteUser(item._id)}
                                modal="close"
                              >
                                Yes
                              </Button>

                              <Button
                                className="red btn-small"
                                node="button"
                                modal="close"
                              >
                                No
                              </Button>
                            </div>
                          </Modal>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="flex column align-center p-1">
              <img src="../images/no-data.svg" width={100} alt="No data" />
              <p className="grey-text">Sorry, no matching data found</p>
            </div>
          )
        ) : (
          <div className="flex column align-center p-1">
            <img src="../images/no-data.svg" width={100} alt="No data" />
            <p className="grey-text">
              Sorry, currently you don’t have any data
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
