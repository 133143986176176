import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux';

export default function ReassignTrader() {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const {  organization } = useSelector(
    (state) => state.singleOrganization
  );
  
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const handleDriverClick = (index) => {
    if (selectedDriver === index) {
      setSelectedDriver(null)
    } else {
      setSelectedDriver(index)
    }
  };

  const filteredOrganizations = organization?.network
  .filter((network) => network.role === 'trader/aggregator')
  .filter((item) => item.organization.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="full-width">
      <div
        className={isTablet ? 'flex column p-1':"flex align-center space-between p-1"}
        style={{ boxShadow: '0px 4px 2px 0px rgba(0, 0, 0, 0.06)' }}
      >
        <div>
          <h4>Network list</h4>
        </div>
        <div className="flex gap-1">
          <div
            className="input-field-style flex align-center small-text"
            style={{
              margin: 0,
              color: '#BFC3CE',
              width: isTablet ? '100%' : '100%',
              marginRight: '10px',
            }}
          >
            <span className="material-symbols-outlined mr-1">search</span>
            <input
              style={{ margin: 0, caretColor: 'black' }}
              placeholder="Search"
              className="hide-focus-underline"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
         
          {/* <button className="btn">Search</button> */}
        </div>
      </div>
      {filteredOrganizations.length > 0 ? (
      <div
        className={isTablet ? 'flex flex-wrap justify-start ml-1':"flex flex-wrap gap-1 mt-2 driver-container justify-center "}
        style={{ height: '25vh', overflowY: 'auto' }}
      >
        {filteredOrganizations.map((item, index) => {
          return (
            <div
              className={`flex align-center gap-2 p-1 driver-box`}
              key={index}
              style={{
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                height: '30%',
                width:'40%',
                border:
                  selectedDriver === index
                    ? '1px solid purple'
                    : '1px solid transparent',
                borderRadius: selectedDriver === index ? '8px' : '8px',
                background:
                  selectedDriver === index
                    ? 'rgba(251, 245, 255, 1)'
                    : 'transparent',
              }}
              onClick={() => handleDriverClick(index)}
            >
              <span
                className="flex"
                style={{
                  background: 'rgba(236, 228, 244, 1)',
                  borderRadius: '50%',
                  padding: '12px',
                }}
              >
                <span className="material-symbols-outlined purple-text">
                  corporate_fare
                </span>
              </span>
              <div>
                <p>{item.organization.name}</p>
              </div>
            </div>
          )
        })}
      </div>
      ) : (
        <div className="flex justify-center mt-2">
          <p>No matching organizations found.</p>
        </div>
      )}
      <div
        className="flex justify-center mt-1 full-width"
        style={{
          background: '#F9F9F9',
          padding: '12px 0',
          boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <button className="btn">Submit</button>
      </div>
    </div>
  )
}
