import React from 'react'

function Notification() {
  return (
    <div>
      Notification 
    </div>
  )
}

export default Notification
