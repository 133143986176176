import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPickupsProgress } from '../../../redux/action/organization'
import { getCurrentDateTime } from '../../../helpers/helper'

import Loading from '../../../layout/Loading'
export default function AllProgressPickups() {
  const dispatch = useDispatch()

  const isTablet = useMediaQuery({ query: '(max-width: 824px)' })
  const isMobile = useMediaQuery({ query: '(max-width:740px)' })
  const { progress, loading } = useSelector((state) => state.progress)

  useEffect(() => {
    dispatch(getAllPickupsProgress())
  }, [dispatch])

  if (loading) {
    return (
      <div className="full-width">
        <Loading />
      </div>
    )
  }

  return (
    <div className={isMobile ? '' : ' full-width pb-1 card p-1 '}>
      <span className="flex align-center space-between ">
        <b className="normal-size">All Pickups in progress</b>
      </span>
      <div className="full-width">
        <table className="striped ">
          <thead>
            <tr>
              <th className="purple-text">Date</th>
              <th className="purple-text">Brand</th>
              <th className="purple-text">Scrap</th>
              <th className="purple-text">Processor</th>
              <th className="purple-text">Quantity</th>
              <th className="purple-text">Price</th>
              <th className="purple-text">Status</th>
            </tr>
          </thead>
          <tbody>
            {progress.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={isTablet && 'small-text'}>
                    {' '}
                    {getCurrentDateTime(item.date)}
                  </td>
                  <td className={isTablet && 'small-text'}>
                    {item?.generator?.name}
                  </td>
                  <td className={isTablet && 'small-text'}>
                    {item.scrap.name}
                    {item.scrap.variant}
                  </td>
                  <td className={isTablet && 'small-text'}>
                    {item?.processor?.name}
                  </td>
                  <td className={isTablet && 'small-text'}>
                    {item.quantity ? item.quantity + 'MT' : '---'}
                  </td>
                  <td className={isTablet && 'small-text'}>{item?.price} ₹</td>
                  <td>
                    <p
                      className={
                        item.status === 'requested'
                          ? 'pending'
                          : item.status === 'accepted'
                          ? 'accepted'
                          : item.status === 'completed'
                          ? 'completed'
                          : item.status === 'assigned'
                          ? 'assigned'
                          : 'active'
                      }
                    >
                      {item?.status}
                    </p>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
