import React from 'react'

export default function AddNewSubscriptionBrand() {
  return (
    <div className="p-1">
      <div className="full-width" style={{ position: 'relative' }}>
        <b>Brand Email</b>
        <span
          className="material-symbols-outlined purple-text"
          style={{
            background: '#ECE4F4',
            padding: '10px',
            borderRadius: '5px',
            position: 'absolute',
            top: '23px',
            left: 0,
          }}
        >
          mail
        </span>
        <span className="input-field-style">
          <input placeholder="Enter brand email" />
        </span>
      </div>

      <div className="full-width mt-1" style={{ position: 'relative' }}>
        <b>Select Platform</b>
        <span
          className="material-symbols-outlined purple-text"
          style={{
            background: '#ECE4F4',
            padding: '10px',
            borderRadius: '5px',
            position: 'absolute',
            top: '23px',
            right: 0,
          }}
        >
          expand_more
        </span>
        <select className="input-field-style">
          <option>-- Select --</option>
          <option>Whatsapp</option>
          <option>Email</option>
          <option>Text message</option>
        </select>
      </div>
      <div className="flex justify-end mt-2">
        <button className="btn">Submit</button>
      </div>
    </div>
  )
}
