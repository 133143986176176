import React, { useEffect } from 'react'
import { DashboardCard } from '../../modules'
import { useMediaQuery } from 'react-responsive'
import MainChart from './components/MainChart'
import NetworkList from './components/NetworkList'
import { useSelector, useDispatch } from 'react-redux'
import BrandList from './components/BrandList'
import TraderList from './components/TraderList'
import BrandPickupsProgress from './components/BrandPickupsProgress'
import { getOverviewAction } from '../../redux/action/overview'

export default function Overview() {
  const dispatch = useDispatch()
  const { overview } = useSelector((state) => state.overview)
  const amount = overview.totalQuantityChannelized
    ? overview.totalQuantityChannelized
    : 0
  const formattedAmount = parseFloat(amount).toFixed(3)
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    dispatch(getOverviewAction())
  }, [dispatch])
  return (
    <React.Fragment>
      <b className="normal-size">Overview</b>

      <div className={isMobile ? 'flex column gap-1' : 'flex gap-2 mt-1'}>
        <DashboardCard
          title="THIS MONTH QUANTITY"
          desc="This Month Quantity"
          amount={`${
            overview.quantityThisMonth
              ? parseFloat(overview.quantityThisMonth).toFixed(3)
              : '0'
          }`}
          img="../images/quantity.svg"
        />
        <DashboardCard
          title="TOTAL PICKUPS"
          desc="This Month"
          amount={`${
            overview.totalPickupsThisMonth ? overview.totalPickupsThisMonth : 0
          } Pickups`}
          img="../images/calendar.png"
        />

        <DashboardCard
          title="CURRENT MILESTONE"
          desc=""
          amount={`${
            overview.currentMilestone ? overview.currentMilestone : 0
          }`}
          img="../images/currentMilestone.png"
        />

        <DashboardCard
          title="TOTAL QUANTITY CHANNELIZED"
          desc=""
          amount={formattedAmount}
          img="../images/totalQuantityChannelized.jpg"
        />
      </div>

      <div
        className={
          isTablet
            ? ' flex column gap-1'
            : 'flex align-center space-between full-width gap-2 mt-1'
        }
      >
        <NetworkList />
        <BrandPickupsProgress />
      </div>
      <div
        className={
          isTablet
            ? ' flex column gap-1'
            : 'flex align-center space-between full-width gap-2'
        }
      >
        <BrandList />
        <TraderList />
      </div>

      {/* <MainChart /> */}
    </React.Fragment>
  )
}
