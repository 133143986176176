import React from 'react'
import { useMediaQuery } from 'react-responsive'

export default function AnalyticsCard({count,title,trend,icon,bgColor,iconColor}) {
  const isTablet = useMediaQuery({ query: '(max-width:1024px)' })
  
  return (
    <div className="full-width flex column space-between gap-1">
      <div
        className={
          isTablet ? 'flex column gap-2' : 'flex full-width gap-2 card-item'
        }
      >
        <div
          style={{
            background: '#fff',
            borderRadius: '12px',
            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
          }}
          className={isTablet ? 'box' : 'flex column gap-1 full-width'}
        >
          <div className="flex align-center space-between">
            <div className="flex column p-1">
              <h2>{count}</h2>
              <p
                className="grey-text"
                style={{ fontSize: isTablet ? '1.2rem' : '1.3rem' }}
              >
               {title}
              </p>
            </div>
            <div
              style={{
                width: 55,
                height: 55,
                background: `${bgColor}`,
              }}
              className="circle flex align-center justify-center mr-1"
            >
              <span
                className={`material-icons`}
                style={{ color: `${iconColor}`}}
              >
               {icon}
              </span>
            </div>
          </div>
          <div className="flex align-center gap-2 p-1">
            <span className="material-icons green-text">north_east</span>
            <span
              className="flex gap-1 align-center"
              style={{ color: '#27AE60' }}
            >
              {trend}
              <p style={{ color: 'rgba(0, 0, 0, 0.35)' }}>vs last 30 days</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
