import { wasteknot } from '../config/urlConfig'

export const getOverviewAction = () => async (dispatch) => {
  dispatch({ type: 'GETTING_OVERVIEW' })
  wasteknot
    .get('/analytics/read/admin/overview')
    .then((res) => {
      dispatch({ type: 'GOT_ALL_OVERVIEW', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_ALL_OVERVIEW' })
      console.log(error)
    })
}
