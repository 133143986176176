export const getSelectDriverReducer = (
  state = {
    loading: false,
    dropLocation: {},
    drivers: [],
    vehicles: [],
  },
  action,
) => {
  switch (action.type) {
    case 'SELECT_PICKUP':
      return { ...state, loading: true }
    case 'SELECTED_PICKUP':
      return { ...state, loading: false, dropLocation: action.payload }
    case 'CANNOT_SELECT_PICKUP':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getVehicleReducer = (
  state = {
    loading: false,
    vehicle: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_VEHICLE':
      return { ...state, loading: true }
    case 'GOT_VEHICLE':
      return { ...state, loading: false, vehicle: action.payload }
    case 'CANNOT_GET_VEHICLE':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getVehicleDataReducer = (
  state = {
    loading: false,
    vehicledata: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_VEHICLE_DATA':
      return { ...state, loading: true }
    case 'GOT_VEHICLE_DATA':
      return { ...state, loading: false, vehicledata: action.payload }
    case 'CANNOT_GET_VEHICLE_DATA':
      return { ...state, loading: false }
    default:
      return state
  }
}
