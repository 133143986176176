import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { AiOutlineEye } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { getAllNetworkAction } from '../../../redux/action/network'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../layout/Loading'
import { getSingleProcessorVehiclesAction } from '../../../redux/action/processor'

export default function BrandList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTablet = useMediaQuery({ query: '(max-width: 824px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const { allNetwork, loading } = useSelector((state) => state.allNetworkStore)

  useEffect(() => {
    dispatch(getAllNetworkAction())
  }, [dispatch])

  if (loading) {
    return (
      <div className="full-width">
        <Loading />
      </div>
    )
  }
  return (
    <div className="box full-width white p-1 card ">
      <div className="flex space-between align-center">
        <b className="bold normal-size">Brand List</b>
        <button
          className={isMobile ? 'view-all-btn' : 'view-all-btn pointer'}
          onClick={() => navigate('/all-brandlist')}
        >
          View All
        </button>
      </div>
      <table className="striped ">
        <thead>
          <tr>
            <th className="purple-text">Organization</th>
            <th className="purple-text">Role</th>
            <th className="purple-text">Location</th>
            <th className="purple-text">Action</th>
          </tr>
        </thead>
         <tbody>
         {allNetwork?.map((item, index) => {
          if (item.type === 'industry' && index < 8) {
            return (
              <tr key={index}>
                <td className={isTablet ? "small-text" : ""}>{item.name}</td>
                <td className={isTablet ? "small-text" : ""}>{item.type}</td>
                <td className={isTablet ? "green-text small-text" : ""}>
                  {item.locations.length}
                </td>
                <td>
                  <Link to={`/single-organization/${item._id}`}
                   onClick={() => {
                    dispatch(getSingleProcessorVehiclesAction(item._id))
                  }}
                  >
                    <AiOutlineEye size={18} />
                  </Link>
                </td>
              </tr>
            );
          }
          return null;
        })}
        </tbody>
      </table>
    </div>
  )
}
