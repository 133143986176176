import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNetworkOfAnOrganizationAction,
  getSingleOrganizationAction,
} from '../../../redux/action/organization'
import { useParams } from 'react-router'

function ContractsList({
  setNetwork,
  scrap,
  pickupLocation,
  processor,
  selectedOption,
  handleProcessorSelect,
}) {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [dataToShow, setDataToShow] = useState([])

  const { organization } = useSelector((state) => state.singleOrganization)

  useEffect(() => {
    if (pickupLocation && !scrap) {
      setDataToShow(
        organization.filter((network) =>
          network?.locations.includes(pickupLocation),
        ),
      )
    }

    if (!pickupLocation && scrap) {
      setDataToShow(
        organization.filter((network) => network.scraps.includes(scrap)),
      )
    }

    if (pickupLocation && scrap) {
      setDataToShow(
        organization.filter(
          (network) =>
            network?.locations.includes(pickupLocation) &&
            network.scraps.includes(scrap),
        ),
      )
    }
  }, [organization, pickupLocation, scrap])

  useEffect(() => {
    setNetwork(dataToShow[0])
  }, [dataToShow, setNetwork])

  useEffect(() => {
    dispatch(getSingleOrganizationAction(id))
    dispatch(getNetworkOfAnOrganizationAction(id))
  }, [dispatch, id])

  return (
    <div className="mt-1 " style={{ height: '60vh' }}>
      {processor.length === 0 && (
        <div className="flex align-center justify-center box full-height no-processor-msg">
          <div className="text-center">
            <span
              className="material-symbols-outlined"
              style={{ fontSize: 50, color: '#666' }}
            >
              group
            </span>
            <p className="text-center">
              You have no waste processors that can handle this in this location
            </p>
          </div>
        </div>
      )}

      {processor.length > 0 && selectedOption && (
        <div className="full-height full-width trader-list ">
          {selectedOption && (
            <>
              {processor
                .filter(
                  (item, index) =>
                    processor.findIndex(
                      (elem) => elem?.processor?.name === item?.processor?.name,
                    ) === index,
                )
                ?.map((item, index) => (
                  <div key={index}>
                    <div
                      key={index}
                      onClick={() =>
                        handleProcessorSelect(
                          item.processor.name,
                          item.processor._id,
                        )
                      }
                      className="white p-1 mb-1 aggregator-list"
                    >
                      <div className="mb-1">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span className="material-symbols-outlined cercle-purple-text mr-1">
                            apartment
                          </span>
                          <p
                            className="capitalize"
                            style={{
                              color: '#6f2da8',
                            }}
                          >
                            {item?.processor?.name}
                          </p>
                        </div>

                        <div className="flex align-center">
                          <span className="material-symbols-outlined cercle-purple-text mr-1">
                            handshake
                          </span>
                          <p className="capitalize">Trader/Aggregator</p>
                        </div>
                      </div>

                      <div
                        style={{
                          width: '100%',
                          height: '3px',
                          background: '#6f2da8',
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ContractsList
