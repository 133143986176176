import { wasteknot } from "../config/urlConfig";
import { toast } from "react-toastify";

export const getSingleOrganizationAction = (id) => async (dispacth) => {
  dispacth({ type: "GETTING_ORGANIZATION" });
  wasteknot
    .get(`/organization/read/admin/single/${id}`)
    .then((res) => {
      dispacth({ type: "GOT_ORGANIZATION", payload: res.data });
    })
    .catch((error) => {
      dispacth({ type: "CANNOT_GET_ORGANIZATION" });
    });
};

export const getNetworkOfAnOrganizationAction = (id) => async (dispacth) => {
  dispacth({ type: "GETTING_ORGANIZATION_NETWORK" });
  wasteknot
    .get(`/network/read/admin/${id}`)
    .then((res) => {
      dispacth({ type: "GOT_ORGANIZATION_NETWORK", payload: res.data });
    })
    .catch((error) => {
      dispacth({ type: "CANNOT_GET_NETWORK" });
    });
};

export const getAllPickupsProgress = () => async (dispatch) => {
  dispatch({ type: "GETTING_PROGRESS" });
  wasteknot
    .get("/pickups/read/admin/pickups-in-progress")
    .then((res) => {
      dispatch({ type: "GOT_PROGRESS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PROGRESS" });
    });
};

export const updateQuantityAction =
  (
    id,
    editedQuantity,
    organizationID,
    handleCloseModal,
    setUpdateLoading,
    getPaginationAction,
    skipPagination,
    numberOfRow
  ) =>
  async (dispatch) => {
    setUpdateLoading(true);
    dispatch({ type: "UPDATE_QUANTITY" });

    wasteknot
      .put(`/pickups/update/modify/admin/${id}`, { quantity: editedQuantity })
      .then((res) => {
        dispatch(getPickupsAfterUpdateAction(organizationID));
        toast.success(res.data);
        setUpdateLoading(false);
        if (getPaginationAction) {
          dispatch(
            getAllPickupsAfterUpdate(
              organizationID,
              skipPagination,
              numberOfRow
            )
          );
        }
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
      })
      .finally(() => {
        setUpdateLoading(false);
        handleCloseModal(id);
      });
  };

export const getSingleOrgSummaryAction = (id) => async (dispatch) => {
  dispatch({ type: "GETTING_SUMMARY" });
  wasteknot
    .get(`/analytics/read/admin/organization/summary/${id}`)
    .then((res) => {
      dispatch({ type: "GOT_SUMMARY", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_SUMMARY" });
    });
};

export const getAllScrapsAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_ALL_SCRAP" });
  wasteknot
    .get("/scraps/read")
    .then((res) => {
      dispatch({ type: "GOT_ALL_SCRAP", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ALL_SCRAP" });
    });
};

export const addLocation = (id, locationData, callback) => async (dispatch) => {
  dispatch({ type: "ADDING_LOCATION" });
  wasteknot
    .put(`/organization/update/admin/add-location/${id}`, locationData)
    .then((res) => {
      dispatch({ type: "ADDED_LOCATION", payload: res.data });
      window.location.reload();
      toast.success("Location Added successfully");
      callback();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_LOCATION" });
    });
};

export const addScraps = (id, body, callback) => async (dispatch) => {
  dispatch({ type: "ADDING_SCRAPS" });
  wasteknot
    .put(`/organization/update/admin/add-scraps/${id}`, body)
    .then((res) => {
      dispatch({ type: "ADDED_SCRAPS", payload: res.data });
      callback(res.data);
      toast.success("Scrap Added successfully");
      callback();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_SCRAPS" });
    });
};

export const removeScrapAction = (scrapId) => async (dispatch) => {
  dispatch({ type: "REMOVE_SCRAP" });
  wasteknot
    .put(`/organization/update/remove-scraps/${scrapId}`)
    .then((res) => {
      dispatch({ type: "REMOVED_SCRAP" });
      toast.success("Scrap removed successfully");
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_REMOVE_SCRAP" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const removeLocationAction =
  (organizationId, locationId) => async (dispatch) => {
    dispatch({ type: "REMOVE_LOCATION" });
    wasteknot
      .put(`/organization/update/admin/remove-location/${locationId}`, {
        organizationId: organizationId,
      })
      .then((res) => {
        dispatch({ type: "REMOVED_LOCATION" });
        toast.success("Location removed successfully");
        window.location.reload();
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_REMOVE_LOCATION" });
        // toast.error(error.response ? error.response?.data : error.message)
      });
  };

export const getSingleOrgHistoryAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_PICKUPS_HISTORY" });
  wasteknot
    .get(`/pickups/read/admin/history`)
    .then((res) => {
      dispatch({ type: "GOT_PICKUPS_HISTORY_SINGLEORG", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PICKUPS_HISTORY" });
    });
};

export const getSingleOrgHistoryActionV2 = (id) => async (dispatch) => {
  dispatch({ type: "GETTING_PICKUPS_HISTORY_NEW" });
  wasteknot
    .get(`/pickups/read/admin/history/${id}`)
    .then((res) => {
      dispatch({
        type: "GOT_PICKUPS_HISTORY_SINGLEORG_NEW",
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PICKUPS_HISTORY_NEW" });
    });
};

export const getQuantityPerScrapAction = (id) => async (dispatch) => {
  dispatch({ type: "GETTING_QUANTITY" });
  wasteknot
    .get(`/analytics/read/admin/quantity-per-scrap/${id}`)
    .then((res) => {
      dispatch({ type: "GOT_QUANTITY", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_QUANTITY" });
    });
};

export const getLocationChartAction = (id) => async (dispatch) => {
  dispatch({ type: "GETTING_LOCATION" });
  wasteknot
    .get(`/analytics/read/admin/pickups-per-location/${id}`)
    .then((res) => {
      dispatch({ type: "GOT_LOCATION", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_LOCATION" });
    });
};

export const setTraderId = (traderId) => ({
  type: "SET_TRADER_ID",
  payload: traderId,
});

export const getPaginationAction = (id, skip, limit) => async (dispatch) => {
  dispatch({ type: "GETTING_PAGINATION" });
  wasteknot
    .get(`/pickups/read/admin/history/${id}?skip=${skip}&limit=${limit}`)
    .then((res) => {
      dispatch({ type: "GOT_PAGINATION", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PAGINATION" });
      console.log(error);
    });
};

export const getPickupsAfterUpdateAction = (id) => async (dispatch) => {
  dispatch({ type: "GETTING_PICKUPS_AFTER_UPDATE" });
  wasteknot
    .get(`/pickups/read/admin/history/${id}`)
    .then((res) => {
      dispatch({ type: "GOT_PICKUPS_AFTER_UPDATE", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PICKUPS_AFTER_UPDATE" });
    });
};

export const getAllPickupsAfterUpdate =
  (id, skipPagination, numberOfRow) => (dispacth) => {
    dispacth({ type: " GETING_PICKUPS" });
    wasteknot
      .get(
        `/pickups/read/admin/history/${id}?skip=${skipPagination}&limit=${numberOfRow}`
      )
      .then((res) => {
        dispacth({ type: "GOT_PICKUPS_AFTER_UPDATE", payload: res.data });
      })
      .catch((error) => {});
  };
