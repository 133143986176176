export const getSingleProcessorReducer = (
  state = {
    loading: false,
    processor: {},
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_PROCESSOR':
      return { ...state, loading: true }
    case 'GOT_PROCESSOR':
      return { ...state, loading: false, processor: action.payload }
    case 'CANNOT_GET_PROCESSOR':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getSingleProcessorVehiclesReducer = (
  state = {
    loading: false,
    vehicles: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_PROCESSOR_VEHICLES':
      return { ...state, loading: true }
    case 'GOT_PROCESSOR_VEHICLES':
      return { ...state, loading: false, vehicles: action.payload }
    case 'CANNOT_GET_PROCESSOR_VEHICLES':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const getSingleVehicleDetails = (
  state = {
    loading: false,
    VehicleDetails: [],
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_PROCESSOR_VEHICLES_DETAILS':
      return { ...state, loading: true }
    case 'GOT_PROCESSOR_VEHICLES':
      return { ...state, loading: false, VehicleDetails: action.payload }
    case 'CANNOT_GET_PROCESSOR_VEHICLES_DETAILS':
      return { ...state, loading: false }
    default:
      return state
  }
}
