import { React, useState } from 'react'
import { Tab, Tabs } from 'react-materialize'

import Account from './components/Account'
import ChangePassword from './components/ChangePassword'
import EditProfile from './components/EditProfile'
import Notification from './components/Notification'


function Settings() {

  return (
    // <div className="full-width mt-1">
    //   <Tabs className="tab-demo mt-1" scope="tabs-22">
    //     <Tab
    //       options={{
    //         duration: 300,
    //         onShow: null,
    //         responsiveThreshold: Infinity,
    //         swipeable: false,
    //       }}
    //       className="mt-1 "
    //       title="Account"
    //     >
    //       <Account />
    //     </Tab>
    //     <Tab
    //       active
    //       options={{
    //         duration: 300,
    //         onShow: null,
    //         responsiveThreshold: Infinity,
    //         swipeable: false,
    //       }}
    //       className="mt-1"
    //       title="Change Password"
    //     >
    //       <ChangePassword />
    //     </Tab>
    //     <Tab
    //       options={{
    //         duration: 300,
    //         onShow: null,
    //         responsiveThreshold: Infinity,
    //         swipeable: false,
    //       }}
    //       className="mt-1"
    //       title="Edit Profile"
    //     >
    //       <EditProfile />
    //     </Tab>
    //     <Tab
    //       options={{
    //         duration: 300,
    //         onShow: null,
    //         responsiveThreshold: Infinity,
    //         swipeable: false,
    //       }}
    //       className="mt-1"
    //       title="Notification"
    //     >
    //       <Notification />
    //     </Tab>
    //   </Tabs>
    // </div>
    <div>
    </div>
  )
}

export default Settings
