import React from 'react'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getNetworkOfAnOrganizationAction } from '../../../redux/action/organization'
import Modal from 'react-responsive-modal'
import InviteNetwork from './InviteNetwork'
import Loading from '../../../layout/Loading'

export default function NetworkList() {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const isTablet = useMediaQuery({ query: '(max-width: 824px)' })
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width:740px)' })

  const { networkOfAnOrganization, loading } = useSelector(
    (state) => state.networkOfAnOrganization,
  )

  const { id } = useParams()
  const dispacth = useDispatch()

  useEffect(() => {
    dispacth(getNetworkOfAnOrganizationAction(id))
  }, [dispacth, id])

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <div className="full-width white p-1">
      <span className="flex align-center space-between ">
        <b className="normal-size">Network List</b>
        <div className="flex gap-1 align-center">
          {/* <button className="btn" onClick={onOpenModal}>
            Add New
          </button> */}
          <Modal
            center
            styles={{
              modal: {
                borderRadius: '10px',
                padding: 0,
                width: isTablet ? '380px' : '800px',
                margin: 0,
              },
            }}
            open={open}
            onClose={onCloseModal}
            closeIcon={<></>}
          >
            <>
              <div className="cercle-purple p-1 white-text flex align-center space-between">
                <h4>Invite network </h4>
                <div
                  onClick={onCloseModal}
                  className="flex align-center justify-center"
                  style={{
                    width: 40,
                    height: 40,
                    background: 'rgba(255, 255, 255, 0.50)',
                    borderRadius: '6px',
                    cursor: 'pointer',
                  }}
                >
                  <span className="material-symbols-outlined">close</span>
                </div>
              </div>
              <InviteNetwork onCloseModal={onCloseModal} />
            </>
          </Modal>
          {networkOfAnOrganization.length > 0 && (
            <button
              className="view-all-btn hover"
              onClick={() =>
                navigate(`/single-organization/${id}/all-networks`)
              }
            >
              View All
            </button>
          )}
        </div>
      </span>
      {networkOfAnOrganization && networkOfAnOrganization.length === 0 ? (
        <div className="flex column align-center p-1">
          <img src="../images/no-data.svg" alt="no-data" width={100} />
          <p className="grey-text">Sorry, currently you don’t have any data</p>
        </div>
      ) : (
        <table className="striped">
          <thead>
            <tr>
              <th className="purple-text">Name</th>
              <th className="purple-text">Phone Number</th>
              <th className="purple-text">Locations</th>
              <th className="purple-text">Email</th>
              <th className="purple-text">Role</th>
              <th className="purple-text">Action</th>
            </tr>
          </thead>
          <tbody>
            {networkOfAnOrganization?.map((item, index) => {
              if (index < 5) {
                return (
                  <tr key={index}>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.organization?.name}
                    </td>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.organization.owner?.phoneNumber}
                    </td>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.locations.length}
                    </td>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.organization.owner?.email}
                    </td>
                    <td className={isMobile ? 'small-text' : ''}>
                      {item.role}
                    </td>
                    <td
                      className={
                        item.wasInvited ? 'active-status' : 'cancel-status'
                      }
                    >
                      {item.wasInvited ? 'Active' : 'cancel'}
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}
