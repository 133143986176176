import React, { useState, useEffect } from 'react'
import { Button, Switch } from 'react-materialize'
import { useMediaQuery } from 'react-responsive'

export default function InviteUser({ handleCancel }) {
  const [showPermissions, setShowPermissions] = useState(false)
  const [selectedRole, setSelectedRole] = useState('')
  const [standardPermissionsEnabled, setStandardPermissionsEnabled] = useState(
    false,
  )
  const [adminPermissionsEnabled, setAdminPermissionsEnabled] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  useEffect(() => {
    if (selectedRole === 'Admin') {
      setStandardPermissionsEnabled(true)
      setAdminPermissionsEnabled(true)
    } else if (selectedRole === 'Manager') {
      setStandardPermissionsEnabled(true)
      setAdminPermissionsEnabled(false)
    } else {
      setStandardPermissionsEnabled(false)
      setAdminPermissionsEnabled(false)
    }
  }, [selectedRole])

  const standardpermission = [
    'Create-contract',
    'Update-contract',
    'Create-auction',
    'Raise-pickup',
    'Read-analytics',
  ]

  const adminPermission = [
    'Remove-location',
    'Assign-location',
    'Remove-network',
    'Add-member',
    'Accept-invitation',
    'Remove-member',
    'Remove-scraps',
    'Add-network',
    'Update-network',
  ]

  const handleRoleSelection = (e) => {
    const role = e.target.value
    setSelectedRole(role)
  }

  const handleSwitchChange = (permission) => {
    console.log(`Switch for ${permission} changed`)
  }

  return (
    <div className={isMobile ? 'full-width' : ''}>
      <h6>Invite An Account</h6>
      <p>Invite a new account to your organization.</p>
      <div className="mt-1">
        <p>Email</p>
        <div className="input-field-style">
          <input
            placeholder="Enter teammate’s Email "
            className="browser-default input-date full-width"
            style={{ border: 'none', outline: 'none' }}
          />
        </div>
      </div>
      <div className="mt-1">
        <span>Select Type</span>
        <select className="input-field-style" onChange={handleRoleSelection}>
          <option value="">Select the type of the member</option>
          <option value="Industry">Industry</option>
          <option value="Recycler">Recycler</option>
        </select>
      </div>
      <div className="mt-1">
        <span>Select Role</span>
        <select className="input-field-style" onChange={handleRoleSelection}>
          <option value="">Select the role of the member</option>
          <option value="Admin">Admin</option>
          <option value="Manager">Manager</option>
        </select>
      </div>
      <p
        className="bold purple-text mt-1 flex align-center"
        onClick={() => setShowPermissions(!showPermissions)}
      >
        <span className="material-symbols-outlined">add</span>
        Add Permissions
      </p>
      {showPermissions && (
        <div
          className={
            isMobile ? 'flex column' : 'flex gap-1 Standard-Permissions mt-1'
          }
        >
          <span className="permission">
            <b className="mb-2">Standard Permissions</b>
            {standardpermission.map((label, index) => (
              <div
                key={index}
                className="full-width flex align-center gap-1 space-between"
              >
                <p>{label}</p>
                <Switch
                  className="green-text"
                  id={`Switch-${label}`}
                  offLabel="Off"
                  onChange={() => handleSwitchChange(label)}
                  onLabel="On"
                  checked={standardPermissionsEnabled}
                />
              </div>
            ))}
          </span>
          <span className="permission">
            <b className="mb-1">Admin Permissions</b>
            {adminPermission.map((label, index) => (
              <div
                key={index}
                className="full-width flex align-center gap-1 space-between"
              >
                <p>{label}</p>
                <Switch
                  id={`Switch-${label}`}
                  offLabel="Off"
                  onChange={() => handleSwitchChange(label)}
                  onLabel="On"
                  checked={adminPermissionsEnabled}
                />
              </div>
            ))}
          </span>
        </div>
      )}
      <div className="flex justify-end gap-1 mt-1">
        {/* <button className="btn-outline">
          Cancel
        </button> */}
        <Button className="btn-outline-small" node="button" modal="close">
          close
        </Button>
        <button className="btn-small" onClick={handleCancel}>
          Send Invitation
        </button>
      </div>
    </div>
  )
}
