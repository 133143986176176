import React from 'react'
import { useEffect, useState } from 'react'
import { getAllScrapsAction } from '../../../redux/action/organization'
import { useSelector, useDispatch } from 'react-redux'
import { inviteNetworkAction } from '../../../redux/action/network'
import { notifyError, notify } from '../../../helpers/helper'
import M from 'materialize-css'

export default function InviteNetwork({ onCloseModal }) {
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [role, setRole] = useState('')
  const [scrap, setScrap] = useState('')
  const [location, setLocation] = useState('')

  const { scraps } = useSelector((state) => state.scraps)

  function sendInvitation(e) {
    e.preventDefault()

    if (!name || !email || !phoneNumber || !role || !scrap || !location) {
      return notifyError('Please fill all fields')
    }

    const data = {
      name,
      email,
      phoneNumber,
      role,
      scrap,
      location,
    }

    dispatch(
      inviteNetworkAction(data, () => {
        let elem = document.getElementById('invitation-modal')
        let instance = M.Modal.getInstance(elem)
        instance.close()
        notify('Invitation sent to user', 'cercle-purple')
        onCloseModal()
        setName('')
        setEmail('')
        setPhoneNumber('')
        setRole('')
        setScrap('')
        setLocation('')
      }),
    )
  }
  useEffect(() => {
    dispatch(getAllScrapsAction())
  }, [dispatch])
  return (
    <div>
      <div className="p-1 flex align-center gap-1">
        <div className="full-width">
          <b>
            Organization Name <span className="red-text">*</span>
          </b>
          <span className="input-field-style">
            <input
              placeholder="Enter your organization name"
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </span>
        </div>

        <div className="full-width">
          <b>
            Email <span className="red-text">*</span>
          </b>
          <span className="input-field-style">
            <input
              placeholder="Enter your email ID"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </span>
        </div>
      </div>

      <div className="p-1 flex align-center gap-1">
        <div className="full-width">
          <b>
            Phone Number <span className="red-text">*</span>
          </b>
          <span className="input-field-style">
            <input
              placeholder="Enter your Phone Number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
          </span>
        </div>

        <div className="full-width">
          <b>
            Role <span className="red-text">*</span>
          </b>
          <select
            className="input-field-style"
            onChange={(e) => setRole(e.target.value)}
            value={role}
          >
            <option>-- Select role --</option>
            <option value={'recycler'}>Recycler</option>
            <option value={'trader'}>Trader/Aggregator</option>
          </select>
        </div>
      </div>

      <div className="p-1 flex align-center gap-1">
        <div className="full-width">
          <b>
            Scraps <span className="red-text">*</span>
          </b>
          <select
            className="input-field-style"
            onChange={(e) => setScrap(e.target.value)}
            value={scrap}
          >
            <option>-- Select scrap --</option>
            {scraps.map((scrap) => (
              <option key={scrap._id} value={scrap._id}>
                {scrap.name} - {scrap.variant}
              </option>
            ))}
          </select>
        </div>

        <div className="full-width">
          <b>
            Location <span className="red-text">*</span>
          </b>
          <span className="input-field-style">
            <input
              placeholder="Enter your Location"
              onChange={(e) => setLocation(e.target.value)}
              value={location}
            />
          </span>
        </div>
      </div>
      <div className="flex justify-end p-1">
        <button
          className="btn"
          onClick={(e) => {
            sendInvitation(e) // Pass the event object to sendInvitation function
            onCloseModal()
          }}
          disabled={
            !name || !email || !phoneNumber || !role || !scrap || !location
          }
        >
          Save and submit
        </button>
      </div>
    </div>
  )
}
