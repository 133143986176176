import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { AiOutlineEye } from 'react-icons/ai'
import { getAllNetworkAction } from '../../../redux/action/network'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function TraderList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isTablet = useMediaQuery({ query: '(max-width: 824px)' })
  const { allNetwork, loading } = useSelector((state) => state.allNetworkStore)
  const filterTraderList = allNetwork?.filter(
    (item) => item.type === 'trader/aggregator',
  )

  useEffect(() => {
    dispatch(getAllNetworkAction())
  }, [dispatch])

  return (
    <div className="box full-width white p-1 card ">
      <div className="flex space-between align-center">
        <b className="bold normal-size">Trader List</b>
        <button
          onClick={() => navigate('/all-trader')}
          className={' hover view-all-btn'}
        >
          View All
        </button>
      </div>
      <table className="striped ">
        <thead>
          <tr>
            <th className="purple-text">Organization</th>
            <th className="purple-text">Role</th>
            <th className="purple-text">Location</th>
            <th className="purple-text">Action</th>
          </tr>
        </thead>
        <tbody>
          {allNetwork?.map((item, index) => {
            if (item.type === 'trader/aggregator' && index < 5) {
              return (
                <tr key={index}>
                  <td className={isTablet ? 'small-text' : ''}>{item.name}</td>
                  <td className={isTablet ? 'small-text' : ''}>{item.type}</td>
                  <td className={isTablet ? 'green-text small-text' : ''}>
                    {item.locations.length}
                  </td>
                  <td>
                    {/* <Link to={`/trader-view/${item._id}`}>
                    <AiOutlineEye size={18} />
                  </Link> */}

                    <AiOutlineEye
                      className="purple-text"
                      size={18}
                      onClick={() =>
                        navigate(`/trader-view/${item._id}`, {
                          state: { traderIdPass: item },
                        })
                      }
                    />
                  </td>
                </tr>
              )
            }
            return null
          })}
        </tbody>
      </table>
    </div>
  )
}
