import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Table } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { addDriverAction, getDriversAction } from "../../../redux/action/cmd";
import M from "materialize-css";
function Drivers() {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const { organization } = useSelector((state) => state.singleOrganization);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError(true);
      return;
    }
    dispatch(addDriverAction({ email, role: "driver" }));
  };

  const handleModalClose = () => {
    setEmail("");
    setError(false);
  };

  return (
    <div className="mt-1">
      <div className="flex justify-end   ">
        <Modal
          bottomSheet={false}
          fixedFooter={false}
          id="add_driver"
          open={false}
          trigger={
            <button node="button" className="btn">
              Add Driver
            </button>
          }
          options={{
            onCloseEnd: handleModalClose // Attach the cleanup function
          }}
          actions={<></>}
        >
          <form className="flex column" onSubmit={handleSubmit}>
            <b className="mb-1">Add New Driver</b>
            <label>Enter Driver Email </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Enter Driver Email"
              className="browser-default input-style"
              type="email"
              accept="image/*"
            />

            <div className="flex justify-end mt-1 gap-1">
              <button
                className="btn-outline cercle-btn "
                type="button"
                onClick={() => {
                  const modalInstance = M.Modal.getInstance(
                    document.getElementById("add_driver")
                  );
                  modalInstance.close();
                }}
              >
                Close
              </button>
              <button
                className="cercle-btn btn "
                type="submit"
                disabled={!email}
              >
                Send
              </button>
            </div>
          </form>
        </Modal>
      </div>
      <div className="mt-1  flex flex-wrap gap-1  ">
        {organization.members?.map((item, index) => {
          if (item.role == "driver") {
            return (
              <div
                className={`flex  driver-box gap-1  `}
                key={index}
                style={{
                  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "7px",
                  background: "#fff",
                  padding: "15px",
                  width: "20%"
                }}
              >
                <img src="../images/user.png" style={{ width: "45px" }} />
                <span>
                  <p className="purple-text">
                    Driver Name : {item.account.name}
                  </p>

                  <p>Phone Number: {item.account.phoneNumber}</p>
                </span>
              </div>
            );

          }
        })}
      </div>
    </div>
  );
}

export default Drivers;
