import React, { useState } from 'react'
import BrandDetails from '../components/addPickup/BrandDetails'
import LogisticsDetails from '../components/addPickup/LogisticsDetails'
import ProcessorDetails from '../components/addPickup/ProcessorDetails'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getSingleOrganizationAction } from '../../../redux/action/organization'
import Loading from '../../../layout/Loading'
import { useNavigate } from 'react-router-dom'
import { getNetworkOfAnOrganizationAction } from '../../../redux/action/organization'
import { getSingleProcessorVehiclesAction } from '../../../redux/action/processor'

function AddPickup() {
  const [step, setStep] = useState(1)
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [pickupData, setPickupData] = useState({
    generator: id,
    processor: '',
    scrap: '',
    quantity: '',
    price: 0,
    date: '',
    pickupLocation: {
      latitude: '',
      longitude: '',
      address: '',
      nickname: '',
    },
    dropLocation: {
      latitude: '',
      longitude: '',
      address: '',
      nickname: '',
    },
    numberOfVehicles: 0,
    drivers: [],
    vehicles: [],
    eWaybill: '',
    invoiceManifest: '',
    image_0: '',
  })
  const { organization, loading } = useSelector(
    (state) => state.singleOrganization,
  )

  const handleChange = (e) => {
    const { name, value } = e.target
    setPickupData({ ...pickupData, [name]: value || null })
  }

  useEffect(() => {
    dispatch(getSingleOrganizationAction(id))
    dispatch(getNetworkOfAnOrganizationAction(id))
    dispatch(getSingleProcessorVehiclesAction(id))
  }, [dispatch, id])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <span className="flex align-center gap-1 hover  ">
        <span
          className="material-symbols-outlined"
          onClick={() => navigate(-1)}
        >
          arrow_back
        </span>
        <b>Go Back</b>
      </span>
      <div className="mt-2 container ">
        <div className="mt-2 cercle-purple flex column align-center justify-center full-width green add-pickup-header">
          <span className=" p-2 align-center justify-center flex column  ">
            <b className="white-text large-size ">Add New Pickup</b>
            <p className="white-text">{organization.name}</p>

            <span
              className="flex mt-2 align-center space-between  "
              style={{ width: '35vw' }}
            >
              <span
                className={`flex column align-center  normal-size ${
                  step === 1 ? 'orange-text' : 'white-text'
                } `}
              >
                <span className="material-symbols-outlined  ">flag_circle</span>
                <b>Brand</b>
              </span>
              <span
                className={`flex column align-center  normal-size ${
                  step === 2 ? 'orange-text' : 'white-text'
                } `}
              >
                <span className="material-symbols-outlined  ">flag_circle</span>
                <b>Processor</b>
              </span>

              <span
                className={`flex column align-center  normal-size ${
                  step === 3 ? 'orange-text' : 'white-text'
                } `}
              >
                <span className="material-symbols-outlined  ">flag_circle</span>
                <b>Logistics</b>
              </span>
            </span>
          </span>
        </div>
        {step == 1 ? (
          <BrandDetails
            step={step}
            setStep={setStep}
            pickupData={pickupData}
            handleChange={handleChange}
            setPickupData={setPickupData}
          />
        ) : step == 3 ? (
          <LogisticsDetails
            step={step}
            setStep={setStep}
            pickupData={pickupData}
            handleChange={handleChange}
            setPickupData={setPickupData}
          />
        ) : step == 2 ? (
          <ProcessorDetails
            step={step}
            setStep={setStep}
            pickupData={pickupData}
            setPickupData={setPickupData}
            handleChange={handleChange}
            hasUnsavedChanges={hasUnsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default AddPickup
