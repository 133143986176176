import React from 'react'
import { useSelector } from 'react-redux'
import { AiOutlineEye } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

export default function AllBrandList() {
  const { allNetwork, loading } = useSelector((state) => state.allNetworkStore)
  const isTablet = useMediaQuery({ query: '(max-width: 824px)' })

  return (
    <div className="box full-width white p-1 card ">
      <div className="flex space-between align-center">
        <b className="bold normal-size">Brand List</b>
      </div>
      <table className="striped ">
        <thead>
          <tr>
            <th className="purple-text">Organization</th>
            <th className="purple-text">Role</th>
            <th className="purple-text">Location</th>
            <th className="purple-text">Action</th>
          </tr>
        </thead>
        <tbody>
          {allNetwork?.map((item, index) => {
            if (item.type === 'industry') {
              return (
                <tr key={index}>
                  <td className={isTablet ? 'small-text' : ''}>{item.name}</td>
                  <td className={isTablet ? 'small-text' : ''}>{item.type}</td>
                  <td className={isTablet ? 'green-text small-text' : ''}>
                    {item.locations.length}
                  </td>
                  <td>
                    <Link to={`/single-organization/${item._id}`}>
                      <AiOutlineEye size={18} />
                    </Link>
                  </td>
                </tr>
              )
            }
            return null
          })}
        </tbody>
      </table>
    </div>
  )
}
