import React from 'react'
import GaugeComponent from 'react-gauge-component'
import { useMediaQuery } from 'react-responsive';

const percentage = 80;

  const SalesGoalChart = () => {
    const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
    const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const colorForPercentage = (percent) => {
      return percent <= percentage ? '#6F2DA8' : '#ECE4F4';
    };

  return (
    <div
      className="white p-1"
      style={{
        width: isMobile ? '100%':'22%',
        height: '350px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <div className="flex space-between">
        <div>
          <p className="grey-text">Sales goal</p>
          <h5>₹3,00,000</h5>
        </div>
        <div>
          <p className="red-text">Remaining</p>
          <h5>₹84,000</h5>
        </div>
      </div>
      <hr color="#E5E5EF" />

       <div style={{ 
        width:isLaptop? '100%': '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        position: 'absolute', 
        bottom: 80,
        right:5 }}>
       <GaugeComponent
          type="semicircle"
          arc={{
            colorArray: [colorForPercentage(percentage), '#ECE4F4'],
            padding: 0,
            subArcs: [
              { limit: percentage },
            ],
          }}
          pointer={{ type: 'blob', animationDelay: 0 }}
          value={percentage}
        />
      </div>
    </div>
  )
}

export default SalesGoalChart
