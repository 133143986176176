import React from 'react'
import { useMediaQuery } from 'react-responsive'

const DATA = [
  {
    id: 1,
    number: '9,30,000',
    name: 'Total Revenue',
    trend: '12%',
    icon: 'currency_rupee',
    bgColor: '#D6EAF8',
    iconColor: 'rgba(52, 122, 226, 1)',
  },
  {
    id: 2,
    number: '4,69,000',
    name: 'Total Expense',
    trend: '25%',
    icon: 'group',
    bgColor: '#FAF4FF',
    iconColor: 'rgba(111, 45, 168, 1)',
  },
  {
    id: 3,
    number: '6',
    name: 'New Customers',
    trend: '20%',
    icon: 'group',
    bgColor: '#FAF4FF',
    iconColor: 'rgba(111, 45, 168, 1)',
  },
  {
    id: 4,
    number: '24',
    name: 'Pickup Sold',
    trend: '20%',
    icon: 'group',
    bgColor: '#FAF4FF',
    iconColor: 'rgba(111, 45, 168, 1)',
  },
]

export default function Analytics() {
  const isTablet = useMediaQuery({ query: '(max-width: 824px)' })

  const groupedCards = []
  for (let i = 0; i < DATA.length; i += 2) {
    groupedCards.push(DATA.slice(i, i + 2))
  }

  return (
    <div className="full-width flex column space-between gap-1">
      {groupedCards.map((group, groupIndex) => (
        <div
          key={groupIndex}
          className={
            isTablet ? 'flex column gap-2' : 'flex full-width gap-2 card-item'
          }
        >
          {group.map((item, index) => (
            <div
              key={index}
              style={{
                background: '#fff',
                borderRadius: '12px',
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
              }}
              className={isTablet ? 'box' : 'flex column gap-1 full-width'}
            >
              <div className="flex align-center space-between">
                <div className="flex column p-1">
                  <h2>{item.number}</h2>
                </div>
              </div>
              <div className="flex space-between align-center">
                <div className="flex column p-1">
                  <p
                    className="grey-text"
                    style={{ fontSize: isTablet ? '1.2rem' : '1.3rem' }}
                  >
                    {item.name}
                  </p>
                  <div className="flex">
                    <span className="material-icons green-text">
                      north_east
                    </span>
                    <span
                      className="flex gap-1 align-center"
                      style={{ color: '#27AE60' }}
                    >
                      {item.trend}{' '}
                      <p style={{ color: 'rgba(0, 0, 0, 0.35)' }}>
                        vs last 30 days
                      </p>
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    width: 55,
                    height: 55,
                    background: item.bgColor,
                  }}
                  className="circle flex align-center justify-center mr-1"
                >
                  <span
                    className={`material-icons`}
                    style={{ color: item.iconColor }}
                  >
                    {item.icon}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
