export const getReportAnalyticsReducer = (
  state = {
    loading: false,
    reportAnalytics: {},
  },
  action,
) => {
  switch (action.type) {
    case 'GETTING_ANALYTICS':
      return { ...state, loading: true }
    case 'GOT_ANALYTICS':
      return { ...state, loading: false, reportAnalytics: action.payload }
    case 'CANNOT_GET_ANALYTICS':
      return { ...state, loading: false }
    default:
      return state
  }
}
