import React from 'react'
import { useMediaQuery } from 'react-responsive'

export default function AddExpenses() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <div className="p-1 full-width">
      <div className={isMobile ? 'flex column' : 'flex gap-2 full-width p-1'}>
        <div className="full-width" style={{ position: 'relative' }}>
          <b>Petrol expenses</b>
          <span
            className="material-symbols-outlined purple-text"
            style={{
              background: '#ECE4F4',
              padding: '10px',
              borderRadius: '5px',
              position: 'absolute',
              top: '23px',
              left: 0,
            }}
          >
            currency_rupee
          </span>
          <span className="input-field-style">
            <input placeholder="Enter petrol expenses" />
          </span>
        </div>
        <div className="full-width" style={{ position: 'relative' }}>
          <b>Weight bridge charge</b>
          <span
            className="material-symbols-outlined purple-text"
            style={{
              background: '#ECE4F4',
              padding: '10px',
              borderRadius: '5px',
              position: 'absolute',
              top: '23px',
              left: 0,
            }}
          >
            currency_rupee
          </span>
          <span className="input-field-style">
            <input placeholder="Enter weight bridge cost" />
          </span>
        </div>
        <div className="full-width" style={{ position: 'relative' }}>
          <b>Vehicle charge</b>
          <span
            className="material-symbols-outlined purple-text"
            style={{
              background: '#ECE4F4',
              padding: '10px',
              borderRadius: '5px',
              position: 'absolute',
              top: '23px',
              left: 0,
            }}
          >
            currency_rupee
          </span>
          <span className="input-field-style">
            <input placeholder="Enter vehicle charge" />
          </span>
        </div>
      </div>

      <div className={isMobile ? 'flex column' : 'flex full-width p-1 gap-2'}>
        <div className="full-width" style={{ position: 'relative' }}>
          <b>Labor charge</b>
          <span
            className="material-symbols-outlined purple-text"
            style={{
              background: '#ECE4F4',
              padding: '10px',
              borderRadius: '5px',
              position: 'absolute',
              top: '23px',
              left: 0,
            }}
          >
            currency_rupee
          </span>
          <span className="input-field-style">
            <input placeholder="Enter labor charge" />
          </span>
        </div>
        <div className="full-width" style={{ position: 'relative' }}>
          <b>Water wash expenses</b>
          <span
            className="material-symbols-outlined purple-text"
            style={{
              background: '#ECE4F4',
              padding: '10px',
              borderRadius: '5px',
              position: 'absolute',
              top: '23px',
              left: 0,
            }}
          >
            currency_rupee
          </span>
          <span className="input-field-style">
            <input placeholder="Enter water-wash cost" />
          </span>
        </div>
        <div className="full-width" style={{ position: 'relative' }}>
          <b>Other expenses</b>
          <span
            className="material-symbols-outlined purple-text"
            style={{
              background: '#ECE4F4',
              padding: '10px',
              borderRadius: '5px',
              position: 'absolute',
              top: '23px',
              left: 0,
            }}
          >
            currency_rupee
          </span>
          <span className="input-field-style">
            <input placeholder="Enter other expenses" />
          </span>
        </div>
      </div>

      <div className="flex align-center space-between mt-1 p-1">
        <h5>
          Total <span className="purple-text">₹ 2400</span>
        </h5>
        <button className="btn">Submit</button>
      </div>
    </div>
  )
}
