import React, { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { ResponsiveContainer } from 'recharts'
import { Dropdown } from 'react-materialize'
import { useMediaQuery } from 'react-responsive'

const CustomBar = (props) => {
  const { x, y, width, height } = props

  const dotSize = 20

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill="#EDD8FF" rx={8} />

      <circle
        cx={x + 10}
        cy={y}
        r={dotSize / 2}
        fill="#6B3E99"
        filter="drop-shadow(0px 7px 8px rgba(74, 58, 255, 0.13))"
      />
    </g>
  )
}

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
]

function SalesGoalAreaChart() {
  const [number, setNumber] = useState(3)
  const isMobile = useMediaQuery({ query: '(max-width:740px)' })
  return (
    <div
      className="quantity-channelized-chart box white"
      style={{ borderRadius: '12px', width: isMobile ? '100%' : '40%' }}
    >
      <div className="flex space-between align-center mb-1 p-1">
        <h5>Top Pickups Sales by brands</h5>
        <div className="flex align-center gap-1"></div>
        <div
          className={isMobile ? '' : 'flex align-center'}
          style={{
            background: '#fff',
            borderRadius: '8px',
            padding: '5px',
            boxShadow: '0px 2px 10px 0px rgba(124, 141, 181, 0.12)',
          }}
        >
          <Dropdown
            id="monthsDropdown"
            trigger={
              <div className="flex align-center">
                <p
                  className="cercle-purple-text"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Last {number} Months
                </p>
                <span className="material-symbols-outlined cercle-purple-text">
                  arrow_drop_down
                </span>
              </div>
            }
          >
            <span onClick={() => setNumber(3)}>3 Months</span>
            <span onClick={() => setNumber(6)}>6 Months</span>
            <span onClick={() => setNumber(12)}>1 Year</span>
            <span onClick={() => setNumber(24)}>2 Years</span>
          </Dropdown>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          barSize={20}
        >
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 10, right: 10 }}
          />
          <YAxis />
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" />

          <Bar dataKey="pv" shape={<CustomBar />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default SalesGoalAreaChart
