import React, { useState, useEffect } from 'react'
import RaisePickups from './components/RaisePickups'
import ContractsList from './components/ContractsList'
import {
  getNetworkOfAnOrganizationAction,
  getSingleOrgHistoryAction,
  getSingleOrganizationAction,
} from '../../redux/action/organization'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

export default function BrandPickups() {
  const [pickupLocation, setPickupLocation] = useState('')
  const [network, setNetwork] = useState({})
  const [scrapContracts, setScrapContracts] = useState([])
  const [scrap, setScrap] = useState('')
  const [scrapContract, setScrapContract] = useState('')
  const [processorName, setProcessorName] = useState('')

  const { organization } = useSelector((state) => state.singleOrganization)
  const { pickupsHistory } = useSelector(
    (state) => state.singleOrganizationPickupsHistory,
  )

  const processorId = pickupsHistory[0]?.processor

  const handleProcessorSelect = (
    selectedProcessorName,
    selectedProcessorId,
  ) => {
    if (selectedProcessorId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        processor: selectedProcessorId,
      }))
      setProcessorName(selectedProcessorName)
    } else {
      console.error('Processor ID is empty or not available')
    }
  }

  const [formData, setFormData] = useState({
    date: '',
    pickupLocation: {
      latitude: 0,
      longitude: 0,
      address: '',
      nickname: '',
    },
    price: '',
    scrap: '',
    numberOfVehicles: 1,
    quantity: '',
    processor: '',
    generator: organization?._id,
  })

  const dispacth = useDispatch()
  const { id } = useParams()

  const [selectedOption, setSelectedOption] = useState('')

  const handleSelectOption = (option) => {
    setSelectedOption(option)
  }

  useEffect(() => {
    dispacth(getSingleOrganizationAction(id)).then((response) => {
      const initialProcessor = response?.data?.initialProcessor
      if (initialProcessor) {
        setProcessorName(initialProcessor)
      }
    })

    dispacth(getNetworkOfAnOrganizationAction(id))
    dispacth(getSingleOrgHistoryAction())
  }, [dispacth, id])

  return (
    <div className="flex full-width">
      <RaisePickups
        selectedNetwork={organization}
        scrapContracts={scrapContracts}
        scrap={scrap}
        setScrap={setScrap}
        setPickupLocation={setPickupLocation}
        pickupLocation={pickupLocation}
        scrapContract={scrapContract}
        onSelect={handleSelectOption}
        selectedProcessor={processorName}
        formData={formData}
        setFormData={setFormData}
        processor={pickupsHistory}
      />

      <div
        className="raise-pickups-sidebar full-height p-1 "
        style={{ height: '35vh' }}
      >
        <p className="bold">Available Waste Processors</p>
        <ContractsList
          setNetwork={setNetwork}
          selectedNetwork={organization}
          scrap={scrap}
          setPickupLocation={setPickupLocation}
          pickupLocation={pickupLocation}
          processor={pickupsHistory}
          selectedOption={selectedOption}
          handleProcessorSelect={handleProcessorSelect}
          formData={formData?.generator}
          setFormData={setFormData}
        />
      </div>
    </div>
  )
}
