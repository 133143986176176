import React, { useState } from 'react'
import Header from '../frame/Header'
import Aside from '../frame/Aside'

function Frame({ children, userInfo }) {
  const [openAside, setOpenAside] = useState(false)


  return (
    <div className="frame">
      <Header
        setOpenAside={setOpenAside}
        openAside={openAside}
        userInfo={userInfo}
      />
      <div className="overlay">
        <Aside openAside={openAside} setOpenAside={setOpenAside} />
        <section className={openAside ? 'section opne-asid' : 'section'}>
          {children}
        </section>
      </div>
    </div>
  )
}

export default Frame
