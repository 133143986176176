import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import { confirmOTP } from '../../redux/action/auth'

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { phoneNumber } = location.state
  const { session } = useParams()
  const [OTP, setOTP] = useState('')
  const [OTPError, setOTPError] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width:740px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 824px)' })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (OTP === '' || OTP.length < 4) return setOTPError('No OTP Entered.')
    dispatch(confirmOTP(phoneNumber, OTP, session, navigate))
  }

  return (
    <div className="auth-page">
      <div className="flex center">
        <div
          className=" flex justify-center align-center full-width"
          style={{ height: '100vh', position: 'relative' }}
        >
          {isMobile ? (
            ''
          ) : (
            <div
              className="p-1"
              style={{ position: 'absolute', top: 0, left: 0 }}
            >
              <img src="../images/logo.png" style={{ width: '150px' }} />
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            className="flex column white p-2"
            style={{ width: '35%' }}
          >
            <div className="flex align-center justify-center gap-1">
              <img src="../images/appLogo.png" width="50px" height="50px" />
              <b className="large-text" style={{ fontSize: '18px' }}>
                wasteknot
              </b>
            </div>
            <b
              className=" flex justify-center mb-2 normal-text"
              style={{ fontSize: '18px' }}
            >
              {!isTablet && <span className="purple-text">Welcome!</span>}
              <span>&nbsp;SignUp</span>
            </b>

            <div className="flex column align-start ">
              <b>Enter Your OTP below</b>

              <div className="input-field-style full-width">
                <img src="../images/OTPIcon.svg" width={30} />
                <input
                  className="browser-default input-field "
                  placeholder="Enter OTP"
                  type="text"
                  onChange={(e) => {
                    setOTP(e.target.value)
                    setOTPError('')
                  }}
                  value={OTP}
                  maxLength="6"
                />
              </div>
            </div>

            <div className="submit-btn mv-2 column flex align-center full-width mt-1 ">
              <button className="btn mb-1 full-width" type="submit">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
