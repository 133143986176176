import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  { name: 'Location A', value: 800 },
  { name: 'Location B', value: 500 },
  { name: 'Location C', value: 300 },
  { name: 'Location D', value: 600 },
]

const COLORS = ['#00C49F', '#0088FE', '#FFBB28', '#FF8042']

const RevenueGenearteBrand = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <div
      className="white p-1"
      style={{
        width: isMobile ? '100%' : '22%',
        height: '350px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <h5 style={{ textAlign: 'center' }}>% Revenue generated by brands</h5>
      <hr color="#E5E5EF" />

      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Tooltip formatter={(value, name) => [value, name]} />
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ outline: 'none' }}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default RevenueGenearteBrand
