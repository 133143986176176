import { wasteknot } from '../config/urlConfig'
import { toast } from 'react-toastify'

export const getAllNetworkAction = () => async (dispatch) => {
  dispatch({ type: 'GETTING_NETWORKS' })
  wasteknot
    .get('/organization/read/admin/all')
    .then((res) => {
      dispatch({ type: 'GOT_ALL_NETWORK', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_NETWORK' })
    })
}

export const addBandAction = (data, MODALID) => async (dispatch) => {
  // const addBrand = toast.loading("Adding new brand...");
  wasteknot
    .post('organization/create/grant-access', data)
    .then((res) => {
      // toast.update(addBrand, {
      //   render: 'verifying',
      //   isLoading: false,
      //   autoClose: 5000,
      // });
      dispatch({ type: 'ADDED_BRAND' })
      toast.success('Access granted')
      // var elem = document.getElementById(MODALID);
      // var instance = M.Modal.getInstance(elem);
      // instance.close();
    })
    .catch((error) => {
      // toast.update(addBrand, {
      //   render: error.response ? error.response?.data : error.message,
      //   type: "error",
      //   isLoading: false,
      //   autoClose: 5000,
      // });
      toast.error(error.response ? error.response?.data : error.message)
    })
}

export const getSingleOrgNetworkAction = (org_id) => async (dispatch) => {
  dispatch({ type: 'GETTING_SINGLE_ORG' })

  wasteknot
    .get(`/network/read/admin/${org_id}`)
    .then((res) => {
      dispatch({ type: 'GOT_SINGLE_ORG_NETWORK', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_SINGLE_ORG_NETWORK' })
    })
}

export const inviteNetworkAction = (data) => async (dispatch) => {
  dispatch({ type: 'INVITING_NEW_NETWORK' })
  wasteknot
    .post('/network/create/admin/invite', data)
    .then((res) => {
      dispatch({ type: 'NEW_NETWORK_INVITED' })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_INVITE_NEW_NETWORK' })
    })
}
