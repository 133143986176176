import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import TimeLine from '../../../layout/TimeLine'
import { Carousel, Divider, Modal } from 'react-materialize'
import { wasteKnotURL } from '../../../redux/config/urlConfig'

function PickupTimeLine({ orgId }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const [showDetails, setShowDetails] = useState(true)
  const createdAtDate = new Date(orgId.createdAt)
  const acceptedDate = new Date(orgId.acceptedOn)
  const assignedDate = new Date(orgId.assignedOn)
  const completedDate = new Date(orgId.completedOn)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalOpen = () => {
    setIsModalOpen(true)
    document.body.style.overflow = 'hidden'
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    document.body.style.overflow = 'auto'
  }
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])
  return (
    <>
      <p>TIMELINE OF EVENTS</p>

      <div className={!isTabletOrMobile && 'container justify-center flex '}>
        <div className="time-line-page">
          {['requested', 'completed', 'accepted', 'assigned'].includes(
            orgId.status,
          ) && (
            <div className="align-center justify-center flex request-pickup">
              {!isTabletOrMobile && (
                <div className="ml-2 mr-2 " style={{ width: '50vw' }}></div>
              )}
              <TimeLine color={'#34A853'} />
              <div style={{ width: '100%' }} className="ml-2">
                <p>{createdAtDate.toLocaleString()}</p>
                <div
                  className=" box time-line-box mr-2"
                  style={{ borderColor: '#34A853' }}
                >
                  <div
                    className="space-between align-center flex hover"
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Raised pickup request</b>
                    {!showDetails ? (
                      <span className=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span className="material-symbols-outlined purple-text">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>

                  {showDetails && (
                    <div className="mt-1 request-box">
                      <p>
                        {orgId.generator.name} raised a pickup request for{' '}
                        {orgId.scrap?.name}-{orgId.scrap.variant} on{' '}
                        {createdAtDate.toLocaleString()}
                      </p>

                      <div className="flex justify-end">
                        <div className="user-chip">
                          <img src="/images/user.png" alt="user" />
                          <p>Generator</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {['accepted', 'completed', 'assigned'].includes(orgId.status) && (
            <div className="align-center justify-center flex accept-pickup">
              {isTabletOrMobile && <TimeLine color={'#FFAB00'} />}
              <div className="ml-2 mr-2 time-line-left-step ">
                {orgId.acceptedOn && <p>{acceptedDate.toLocaleString()}</p>}
                <div
                  className=" box time-line-box  "
                  style={{ borderColor: '#FFAB00' }}
                >
                  <div
                    className="space-between align-center flex hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Pickup accepted</b>
                    {!showDetails ? (
                      <span className=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span className="material-symbols-outlined primary">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>
                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        {orgId.processor.name}accepted the pickup request and
                        will be assigning driver and a vehicle
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {!isTabletOrMobile && <TimeLine color={'#FFAB00'} />}
              {!isTabletOrMobile && (
                <div style={{ width: '100%' }} className="ml-2"></div>
              )}
            </div>
          )}
          {['assigned', 'completed', 'assigned'].includes(orgId.status) && (
            <div className="align-center justify-center flex assigned-pickup">
              {!isTabletOrMobile && (
                <div className="ml-2 mr-2 " style={{ width: '50vw' }}></div>
              )}
              <TimeLine color={'#FF5B67'} />
              <div style={{ width: '100%' }} className="ml-2">
                {orgId.assignedOn && <p>{assignedDate.toLocaleString()}</p>}

                <div
                  className=" box time-line-box mr-2 "
                  style={{ borderColor: '#FF5B67' }}
                >
                  <div
                    className="space-between align-center flex hover hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Driver and Vehicle assigned</b>
                    {!showDetails ? (
                      <span className=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span className="material-symbols-outlined primary">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>
                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        Driver, vehicle has been assigned for the pickup. You
                        shall be notified on pickup day upon arrival.
                      </p>

                      <Modal
                        trigger={
                          <button className="btn-small pink">
                            View Logistics Info
                          </button>
                        }
                      >
                        <p className="bold">Logistics</p>

                        <table>
                          <tbody>
                            {orgId.drivers.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <img
                                      src={`${wasteKnotURL}/account/read/photo/${item._id}`}
                                      alt="driver"
                                      style={{
                                        width: '50px',
                                        height: '50px',
                                      }}
                                    />
                                  </td>
                                  <td className="capitalize">{item?.name}</td>
                                  <td>+{item.phoneNumber}</td>
                                </tr>
                              )
                            })}
                          </tbody>

                          <tbody>
                            {orgId.vehicles.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <img
                                      src={`${wasteKnotURL}/vehicles/read/image/${orgId?.vehicles[index]?._id}`}
                                      alt="driver"
                                      style={{
                                        width: '50px',
                                        height: '50px',
                                      }}
                                    />
                                  </td>
                                  <td className="capitalize">
                                    {item.vehicleNumber}
                                  </td>
                                  <td>{item.capacity} Tonnes</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {['assigned', 'completed', 'assigned'].includes(orgId.status) && (
            <div className="align-center justify-center flex driver-arrived-pickup">
              {isTabletOrMobile && <TimeLine color={'#FF821C'} />}
              <div className="ml-2 mr-2 time-line-left-step ">
                {orgId.assignedOn && <p>{assignedDate.toLocaleString()}</p>}

                <div
                  className=" box time-line-box  "
                  style={{ borderColor: '#FF821C' }}
                >
                  <div
                    className="space-between align-center flex hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Driver arrived at pickup location</b>
                    {!showDetails ? (
                      <span className=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span className="material-symbols-outlined primary">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>
                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        Driver(s) arrived at the pickup location to pickup the
                        waste
                      </p>

                      {orgId.drivers.map((driver, i) => {
                        return (
                          <ul key={i} className="mt-1">
                            <li className="capitalize">
                              <p className="capitalize">
                                Driver : {driver?.name}
                              </p>
                              <p className="capitalize">
                                Vehicle No. : {orgId.vehicles[i].vehicleNumber}
                              </p>
                              <Divider />
                            </li>
                          </ul>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
              {!isTabletOrMobile && <TimeLine color={'#FF821C'} />}
              {!isTabletOrMobile && (
                <div style={{ width: '100%' }} className="ml-2"></div>
              )}
            </div>
          )}
          {orgId.status === 'completed' && (
            <div className="align-center justify-center flex complete-pickup">
              {!isTabletOrMobile && (
                <div className="ml-2 mr-2 " style={{ width: '50vw' }}></div>
              )}
              <TimeLine color={'#34A853'} />
              <div style={{ width: '100%' }} className="ml-2">
                {orgId.completedOn && <p>{completedDate.toLocaleString()}</p>}
                <div
                  className=" box time-line-box mr-2 "
                  style={{ borderColor: '#34A853' }}
                >
                  <div
                    className="space-between align-center flex hover hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Bills and Documents uploaded</b>
                    {!showDetails ? (
                      <span className=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span className="material-symbols-outlined primary">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>

                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        Bills and other relevant Documents for the pickup has
                        been uploaded
                      </p>

                      <div className="">
                        <button
                          className="btn-small blue mr-1"
                          onClick={() => {
                            window.open(
                              `${wasteKnotURL}/pickups/read/invoiceManifest/${orgId._id}`,
                              '_blank',
                            )
                          }}
                        >
                          View Invoice Manifest
                        </button>
                        <button
                          className="btn-small blue lighten-1 mt-1"
                          onClick={() => {
                            window.open(
                              `${wasteKnotURL}/pickups/read/eWayBill/${orgId._id}`,
                              '_blank',
                            )
                          }}
                        >
                          View eWay Bill
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {orgId.status === 'completed' && (
            <div className="align-center justify-center flex complete-pickup-tonne">
              {isTabletOrMobile && <TimeLine color={'#FFAB00'} />}
              <div className="ml-2 mr-2 time-line-left-step ">
                <p>{completedDate.toLocaleString()}</p>
                <div
                  className=" box time-line-box  "
                  style={{ borderColor: '#FFAB00' }}
                >
                  <div
                    className="space-between align-center flex hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Pickup is completed</b>
                    {!showDetails ? (
                      <span className=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span className="material-symbols-outlined primary">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>
                  {showDetails && (
                    <div className="mt-1 mb-1">
                      <p>
                        Pickup request for {orgId.quantity} Tonne(s) of{' '}
                        {orgId.scrap.name} - {orgId.scrap.variant} has been
                        completed
                      </p>
                      <b className="mt-1">Quantity: {orgId.quantity} Tons</b>
                    </div>
                  )}
                  <Modal
                    onClose={handleModalClose}
                    trigger={
                      <button
                        className="btn-small green"
                        onClick={handleModalOpen}
                      >
                        View Images
                      </button>
                    }
                    className="carousel-modal"
                  >
                    {orgId.images.length ? (
                      <Carousel
                        carouselId="Carousel-36"
                        images={orgId.images.map((item, i) => {
                          const imageUrl = `${wasteKnotURL}/pickups/read/image/${
                            orgId._id
                          }?count=${i + 1}`
                          return imageUrl
                        })}
                        options={{
                          fullWidth: true,
                          indicators: true,
                        }}
                        className="custom-carousel"
                      />
                    ) : (
                      <div className="center">
                        <p>No Images uploaded</p>
                      </div>
                    )}
                  </Modal>
                </div>
              </div>
              {!isTabletOrMobile && <TimeLine color={'#FFAB00'} />}
              {!isTabletOrMobile && (
                <div style={{ width: '100%' }} className="ml-2"></div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PickupTimeLine
