import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Frame from './layout/Frame'
import store from './redux/store'
import Overview from './pages/overView/Overview'
import Report from './pages/reports/Report'
import Settings from './pages/settings/Settings'
import { Provider } from 'react-redux'
import Notification from './pages/Notification'
import Subscriptions from './pages/subscriptions/Subscriptions'
import Login from './pages/auth/Login'
import ConfirmOTP from './pages/auth/ConfirmOTP'
import AllNetworks from './pages/overView/children/AllNetworks'
import SingleOrganization from './pages/singleorganization/SingleOrganization'
import AllPickups from './pages/singleorganization/children/AllPickups'
import OrganizationAllNetworks from './pages/overView/children/AllNetworks'
import Forbidden from './pages/forbidden/Forbidden'
import MemberVehicleListings from './pages/Member & Vehicle Listings/Member&VehicleListings'
import SingleBrandPickups from './pages/SingleBrandPickups/SingleBrandView'
import BrandPickups from './pages/brandpickups/BrandPickups'
import TraderView from './pages/trader/TraderView'
import AllTrader from './pages/overView/children/AllTrader'
import AllPickupsProgress from './pages/overView/children/AllProgressPickups'
import AddNewPickups from './pages/singleorganization/children/AddNewPickups'
import AddPickup from './pages/singleorganization/children/AddPickup'
import Payments from './pages/payments/Payments'
import AllContractList from './pages/contracts/components/AllContractList'
import AllPickupsHistory from './pages/reports/components/AllPickupsHistory'
import AllBrandList from './pages/overView/children/AllBrandList'
import AllNetworkList from './pages/singleorganization/components/AllNetworkList'

const token = localStorage.getItem('wasteknot_admin')
const is_wasteknot_admin = localStorage.getItem('is_wasteknot_admin') === 'true'

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        {!is_wasteknot_admin && token ? (
          <ForbiddenStock />
        ) : token && is_wasteknot_admin ? (
          <Auth />
        ) : (
          <UnAuth />
        )}
      </BrowserRouter>
    </Provider>
  )
}

function UnAuth() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/confirm-otp" element={<ConfirmOTP />} />
      <Route path="/confirm-otp/:session" element={<ConfirmOTP />} />
    </Routes>
  )
}

function ForbiddenStock() {
  return (
    <Routes>
      <Route path="/" element={<Forbidden />} />
    </Routes>
  )
}

function Auth() {
  return (
    <Frame>
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/all-networks" element={<AllNetworks />} />
        <Route path="/report" element={<Report />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/single-organization/:id/add-pickup"
          element={<AddPickup />}
        />
        <Route path="/all-contractList/:id" element={<AllContractList />} />
        <Route path="/trader-view/:id" element={<TraderView />} />
        <Route path="/all-trader" element={<AllTrader />} />
        <Route path="/all-brandlist" element={<AllBrandList />} />
        <Route
          path="/member-vehicle-listings"
          element={<MemberVehicleListings />}
        />

        <Route
          path="/single-organization/:id"
          element={<SingleOrganization />}
        />

        <Route
          path="/single-organization/:id/all-pickups"
          element={<AllPickups />}
        />

        <Route
          path="/single-organization/:id/all-networks"
          element={<AllNetworkList />}
        />

        <Route path="/notification" element={<Notification />} />

        <Route
          path="/singlebrandpickupview/:id"
          element={<SingleBrandPickups />}
        />
        <Route path="/all-pickups-progress" element={<AllPickupsProgress />} />
        <Route
          path="/single-organization/:id/raisepickup"
          element={<BrandPickups />}
        />
        <Route
          path="/single-organization/:id/add-new-pickups"
          element={<AddNewPickups />}
        />
        <Route
          path="/single-organization/:id/raise-pickup"
          element={<BrandPickups />}
        />
        <Route path="/all-pickups-history" element={<AllPickupsHistory />} />
      </Routes>
    </Frame>
  )
}

export default App
