import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'react-responsive-modal'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import { logoutAction } from '../redux/action/auth'
function Aside({ openAside, setOpenAside }) {
  const isTablet = useMediaQuery({ query: '(max-width: 1330px)' })

  const [logout, setLogout] = useState(false)
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logoutUser = () => {
    dispatch(logoutAction())
  }
  return (
    <aside className={openAside ? 'aside-list-open' : ' aside-list'}>
      <ul className="aside-items-list">
        <li
          className={location.pathname === '/' ? 'active-aside-items-list' : ''}
          onClick={() => {
            navigate('/')
            setOpenAside(false)
          }}
        >
          <span className="material-symbols-outlined icon " role="button">
            dashboard
          </span>
          {openAside && <p className="semi-bold">Overview</p>}
        </li>

        <li
          className={
            location.pathname === '/report' ? 'active-aside-items-list' : ''
          }
          onClick={() => {
            navigate('/report')
            setOpenAside(false)
          }}
        >
          <span className="material-symbols-outlined">summarize</span>
          {openAside && <p className="semi-bold">Report</p>}
        </li>

        <li
          className={
            location.pathname === '/member-vehicle-listings'
              ? 'active-aside-items-list'
              : ''
          }
          onClick={() => {
            navigate('/member-vehicle-listings')
            setOpenAside(false)
          }}
        >
          <span className="material-symbols-outlined">
            deployed_code_account
          </span>
          {openAside && <p className="semi-bold">Listings</p>}
        </li>

        <li
          className={
            location.pathname === '/subscriptions'
              ? 'active-aside-items-list'
              : ''
          }
          onClick={() => {
            navigate('/subscriptions')
            setOpenAside(false)
          }}
        >
          <span className="material-symbols-outlined">subscriptions</span>
          {openAside && <p className="semi-bold">Subscriptions</p>}
        </li>

        <li
          className={
            location.pathname === '/payments' ? 'active-aside-items-list' : ''
          }
          onClick={() => {
            navigate('/payments')
            setOpenAside(false)
          }}
        >
          <span className="material-symbols-outlined">payments</span>
          {openAside && <p className="semi-bold">Payments</p>}
        </li>

        <li
          className={
            location.pathname === '/settings' ? 'active-aside-items-list' : ''
          }
          onClick={() => {
            navigate('/settings')
            setOpenAside(false)
          }}
        >
          <span className="material-symbols-outlined">settings</span>
          {openAside && <p className="semi-bold">Settings</p>}
        </li>
      </ul>

      <div
        className="flex mb-5 ml-3 align-center full-width  hover"
        onClick={() => {
          setLogout(true)
          onOpenModal()
        }}
      >
        <span className="material-symbols-outlined icon ">logout</span>
        {openAside && <p className="semi-bold">Logout</p>}
      </div>

      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: '10px',
            width: isTablet ? '380px' : '800px',
            margin: 0,
            bottom: '200px',
          },
        }}
        open={open}
        onClose={onCloseModal}
        closeIcon={<></>}
      >
        <div className="p-1">
          <b className="normal-size">Confirm Logout </b>
          <p className="semi-bold">Are you sure you want to logout?</p>

          <div
            className="flex align-center justify-end gap-1 modal-content"
            style={{ marginBottom: '15px' }}
          >
            <button className="green btn-small" onClick={logoutUser}>
              Yes
            </button>

            <button
              className="red btn-small"
              onClick={() => {
                setLogout(false)
                onCloseModal()
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </aside>
  )
}

export default Aside
