import axios from "axios";
import { authenticationService, IWMService } from "./config";

function getAuthURL() {
  return `${authenticationService.baseURL}`;
}

function getAuth() {
  let Auth = axios.create(authenticationService);
  const token = localStorage.getItem("CCXT");
  if (token) Auth.defaults.headers.common["x-authentication-token"] = token;
  return Auth;
}

function getWasteknotURL() {
  return `${IWMService.baseURL}`;
}

function getWasteknot() {
  
  let Auth = axios.create(IWMService);
  const wasteToken = localStorage.getItem("wasteknot_admin");
  
  if (wasteToken)
    Auth.defaults.headers.common["x-authentication-token"] = wasteToken;
  return Auth;
}

// function getWasteknot() {
//   let Auth = axios.create(IWMService);
//   const IWMToken = localStorage.getItem("wasteknot_admin");

//   if (IWMToken)
//     Auth.defaults.headers.common["x-authentication-token"] = IWMToken;
//   return Auth;
// }


export const Auth = getAuth();
export const AuthURL = getAuthURL();
export const wasteknot = getWasteknot();
export const wasteKnotURL = getWasteknotURL();
export const recycler = "";
