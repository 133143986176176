export const authenticationService = {
  // baseURL: 'https://auth.thinktrash.co',
  // baseURL: "https://api.authentication.thinktrash.co/"
  baseURL:"https://api.auth.cerclex.com"
};

export const IWMService = {
  // baseURL: "https://staging.api.wasteknot.thinktrash.co"
  baseURL: "https://server1.wasteknot.io",
};
