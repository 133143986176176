export const getOverviewReducer = (
  state = { loading: false, overview: {} },
  action,
) => {
  switch (action.type) {
    case 'GETTING_OVERVIEW':
      return { ...state, loading: true }
    case 'GOT_ALL_OVERVIEW':
      return { ...state, loading: false, overview: action.payload }
    case 'CANNOT_GET_ALL_OVERVIEW':
      return { ...state, loading: false }
    default:
      return state
  }
}
