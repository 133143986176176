import { wasteknot } from '../config/urlConfig'

export const getAllContractsAction = () => async (dispatch) => {
  dispatch({ type: 'GETTING_ALL_CONTRACTS' })
  wasteknot
    .get('/contracts/read')
    .then((res) => {
      dispatch({ type: 'GOT_ALL_CONTRACTS', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_ALL_CONTRACTS' })
    })
}

export const getSingleOrgContractsAction = (orgID) => async (dispatch) => {
  dispatch({ type: 'GETTING_SINGLE_ORG_CONTRACTS' })

  wasteknot
    .get(`/contracts/read/admin/${orgID}`)
    .then((res) => {
      dispatch({ type: 'GOT_SINGLE_ORG_CONTRACTS', payload: res.data })
    })
    .catch((error) => {
      dispatch({ type: 'CANNOT_GET_SINGLE_ORG_CONTRACTS' })
    })
}
