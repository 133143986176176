import { BiUser } from "react-icons/bi";
import { wasteKnotURL } from "../redux/config/urlConfig";

function UserProfileImage({ userId, style, URL }) {
  if (URL)
    return (
      <div>
        <img
          src={URL}
          className="circle"
          alt="user profile image "
          style={style ? style : {}}
          crossOrigin="anonymous"
        />
      </div>
    );

  return (
    <>
      <img
        src={`${wasteKnotURL}/account/read/photo/${userId}`}
        className="circle"
        alt="img"
        crossOrigin="anonymous"
        style={{ height: "70%", width: "40px" }}
      />
    </>
  );
}

export default UserProfileImage;
