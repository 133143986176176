import React, { useState, useEffect } from 'react'
import { addDriverAction } from '../../../redux/action/cmd'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleProcessorAction } from '../../../redux/action/processor'
import { useParams } from 'react-router'

export default function ReassignDriver() {
  const [selectedDriver, setSelectedDriver] = useState(null)
  const dispatch = useDispatch()
  const { processor, loading } = useSelector((state) => state.processor)

  const { allNetwork, singleProcessorVehicles } = useSelector((state) => ({
    allNetwork: state.allNetworkStore.allNetwork,
    loading: state.allNetworkStore.loading,
    singleProcessorVehicles: state.processor.singleProcessorVehicles,
  }))

  const { id } = useParams()
  const handleDriverClick = (index) => {
    if (selectedDriver === index) {
      setSelectedDriver(null)
    } else {
      setSelectedDriver(index)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(addDriverAction({ role: 'driver' }))
  }

  useEffect(() => {
    if (allNetwork && allNetwork.length > 0) {
      const traderAggregatorNetwork = allNetwork.find(
        (network) => network.type === 'trader/aggregator',
      )

      if (traderAggregatorNetwork && traderAggregatorNetwork._id) {
        dispatch(getSingleProcessorAction(traderAggregatorNetwork._id))
      }
    }
  }, [allNetwork, dispatch])

  return (
    <div className="p-1">
      <>
        <form onSubmit={handleSubmit}>
          <div
            className="flex flex-wrap gap-1 mt-1 driver-container"
            style={{ height: '25vh', overflowY: 'auto' }}
          >
            {processor && processor.members && processor.members.length > 0 && (
              <div className="driver-name">
                {processor.members.map((member, index) => {
                  if (member.role === 'driver') {
                    return (
                      <div
                        className={`flex align-center gap-2 p-1 col s4 driver-box`}
                        key={index}
                        style={{
                          boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                          height: '30%',
                          border:
                            selectedDriver === index
                              ? '1px solid purple'
                              : '1px solid transparent',
                          borderRadius:
                            selectedDriver === index ? '8px' : '8px',
                          background:
                            selectedDriver === index
                              ? 'rgba(251, 245, 255, 1)'
                              : 'transparent',
                        }}
                        onClick={() => handleDriverClick(index)}
                      >
                        <img
                          src="../images/user.png"
                          alt="user"
                          style={{ width: '50px' }}
                        />
                        <div>
                          <p>{member.account.name}</p>
                          <p>
                            <span className="grey-text">
                              {member.account.phoneNumber}
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            )}
          </div>
          <div className="flex justify-end mt-1">
            <button className="btn" disabled={!selectedDriver}>
              Submit
            </button>
          </div>
        </form>
      </>
    </div>
  )
}
