import React from 'react'
import CardAnalytics from './CardAnalytics'
import PickupRequestChart from './PickupRequestCharts'
import ScrapGeneratorChart from './ScrapGeneratorChart'
import RevenueGeneratorChart from './RevenueGeneratorChart'
import { useMediaQuery } from 'react-responsive'
import AnalyticsCard from '../../../../modules/AnalyticsCard'

const cardData = [
  {
    icon: 'local_shipping',
    bgColor: 'rgba(52, 152, 219, 0.20)',
    iconColor: 'rgba(52, 122, 226, 1)',
  },
  { icon: 'group', bgColor: '#FAF4FF', iconColor: 'rgba(111, 45, 168, 1)' },
  {
    icon: 'navigation',
    bgColor: '#FFF9DB',
    iconColor: 'rgba(255, 212, 0, 1)',
  },
  {
    icon: 'local_shipping',
    bgColor: 'rgba(255, 176, 58, 0.20)',
    iconColor: 'rgba(255, 136, 27, 1)',
  },
  {
    icon: 'local_shipping',
    bgColor: '#F0FFF6',
    iconColor: 'rgba(39, 174, 96, 1)',
  },
  {
    icon: 'forklift',
    bgColor: 'rgba(178, 164, 255, 0.20)',
    iconColor: 'rgba(178, 164, 255, 1)',
  },
]

export default function SingleOrgAnalitics({ summary }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
  return (
    <>
      <div className={isLaptop ? 'flex column gap-1' : 'full-width flex gap-2'}>
       
        <div
          className={isMobile ? 'flex column gap-1' : 'flex full-width gap-2'}
        >
          <div className="full-width flex column gap-1">
            <AnalyticsCard
              count={`${summary.totalPickups ? summary.totalPickups : 0}`}
              title="Total Pickups"
              trend="20%"
              icon={cardData[0].icon}
              bgColor={cardData[0].bgColor}
              iconColor={cardData[0].iconColor}
            />
            <AnalyticsCard
              count={`${
                summary.totalNumberOfNetworkMembers
                  ? summary.totalNumberOfNetworkMembers
                  : 0
              }`}
              title="My Network"
              trend="20%"
              icon={cardData[1].icon}
              bgColor={cardData[1].bgColor}
              iconColor={cardData[1].iconColor}
            />
          </div>

          <div className="full-width flex column gap-1">
            <AnalyticsCard
              count={`${
                summary.totalPendingPickups ? summary.totalPendingPickups : 0
              }`}
              title="Pending Pickups "
              trend="10%"
              icon={cardData[3].icon}
              bgColor={cardData[3].bgColor}
              iconColor={cardData[3].iconColor}
            />
            <AnalyticsCard
              count={`${
                summary.totalQuantityChannelized
                  ? summary.totalQuantityChannelized
                  : 0
              }`}
              title="Total channelized "
              trend="20%"
              icon={cardData[4].icon}
              bgColor={cardData[4].bgColor}
              iconColor={cardData[4].iconColor}
            />
          </div>
          <div className="full-width flex column gap-1">
            <AnalyticsCard
              count={`${
                summary.totalNumberOfLocations
                  ? summary.totalNumberOfLocations
                  : 0
              }`}
              title="Locations"
              trend="12%"
              icon={cardData[2].icon}
              bgColor={cardData[2].bgColor}
              iconColor={cardData[2].iconColor}
            />
            <AnalyticsCard
              count={`${
                summary.totalNumberOfScraps ? summary.totalNumberOfScraps : 0
              }`}
              title="Added Scraps"
              trend="1.20%"
              icon={cardData[5].icon}
              bgColor={cardData[5].bgColor}
              iconColor={cardData[5].iconColor}
            />
          </div>
        </div>
      </div>
      <div className={isMobile ? 'flex column gap-1' : 'flex gap-1'}>
      <div className="flex flex-wrap">
          <CardAnalytics type={1} pickupCount={0} pickupType={1} />
          <div className="flex full-width gap-1">
            <CardAnalytics type={2} pickupCount={0} pickupType={0} />
            <CardAnalytics type={2} pickupCount={0} pickupType={0} />
          </div>
        </div>
        <ScrapGeneratorChart />
        <RevenueGeneratorChart />
      </div>
    </>
  )
}
