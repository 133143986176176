import React, { useState } from 'react';

export default function RejectPickup({ onCloseModal }) {
  const [rejectReason, setRejectReason] = useState('');

  const handleInputChange = (event) => {
    setRejectReason(event.target.value);
  };

  return (
    <div>
      <b>Reject Reason</b>
      <input
        className="input-style textarea"
        placeholder="Write a reason here..."
        style={{ paddingLeft: '10px' }}
        value={rejectReason}
        onChange={handleInputChange}
      />
      <div className="flex gap-1 justify-end">
        <button
          className="btn-small green"
          onClick={onCloseModal}
          disabled={!rejectReason}
        >
          Send
        </button>
        <button
          flat
          className="btn-small red white-text"
          node="button"
          onClick={onCloseModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
