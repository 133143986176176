import React from "react";
import { useMediaQuery } from "react-responsive";
  

function DashboardCard({title,amount,desc,img}) {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div style={{height:'14vh',borderRadius:'5px'}} className={ isTablet ? "flex start box p-1 white" : " full-width flex align-center  white start box p-1 " }>
      <img src={img} style={{width:"70px",height:'70px'}} className="dashboard-card-img" alt="dashboard-box-image" />
      <div className=" flex column ml-1">
        <b className="purple-text" >{title}</b>
        <b className="normal-size " style={{ paddingTop:"5px" }} >{amount}</b>
        <p className="semi-bold gray "> {desc}</p>
      </div>
    </div>
   
  );
}

export default DashboardCard;
