import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export default function RenewalReminder() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [selectedBrands, setSelectedBrands] = useState([]); 

  const removeBrand = (brandToRemove) => {
    const updatedBrands = selectedBrands.filter((brand) => brand !== brandToRemove);
    setSelectedBrands(updatedBrands);
  };

  return (
    <div className="p-1">
      <b>Brand Renewal Reminders</b>
      <div style={{ width:isMobile ? '100%': '50%' }}>
        <span
          className="material-symbols-outlined purple-text"
          style={{
            background: '#ECE4F4',
            padding: '10px',
            borderRadius: '5px',
            position: 'absolute',
            top: '112px',
            right: '400px',
          }}
        >
          expand_more
        </span>
        <select
          className="input-field-style brand-renewal"
          // multiple
          // onChange={handleBrandRenewalChange}
          // value={selectedBrands} 
        >
          <option>-- Select --</option>
          <option value="Whatsapp">Whatsapp</option>
          <option value="Email">Email</option>
          <option value="Text message">Text message</option>
        </select>
      </div>
      <div className="selected-capsules mt-1" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedBrands.length > 0 ? (
          selectedBrands.map((brand, index) => (
            <div
              key={index}
              className="selected-capsule"
              style={{
                background: '#6F2DA8',
                color: 'white',
                borderRadius: '15px',
                padding: '5px 10px',
                margin: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span>{brand}</span>
              <span style={{ cursor: 'pointer' }} onClick={() => removeBrand(brand)}>
                &#x2715;
              </span>
            </div>
          ))
        ) : (
          <p>No brands selected</p>
        )}
      </div>
      <div className="mt-1" style={{ width:isMobile ? '100%': '50%' }}>
        <b>Select Platform</b>
        <span
          className="material-symbols-outlined purple-text"
          style={{
            background: '#ECE4F4',
            padding: '10px',
            borderRadius: '5px',
            position: 'absolute',
            top: '232px',
            right: '400px',
          }}
        >
          expand_more
        </span>
        <select className="input-field-style">
          <option>-- Select --</option>
          <option>Whatsapp</option>
          <option>Email</option>
          <option>Text message</option>
        </select>
      </div>
    </div>
  );
}